import { i18n } from '@/plugins/vue-i18n.plugin';

export const localeDateKeys = {
  YM: 'YM',
  YMD: 'YMD',
  YMDW: 'YMDW'
};

export const dateByLocaleKey = (date, key = localeDateKeys.YM, separator = '-') => {
  if (!date || date === '0000-00-00') {
    return null;
  }

  let localDate = date;
  const estimateSymbol = '≈';
  const isEstimateDate = date.includes(estimateSymbol);

  if (isEstimateDate) {
    localDate = date.replace('≈', '');
  }

  const [y, m, d] = localDate.split(separator);
  let localeParams = {
    1: y
  };

  if (key === localeDateKeys.YMD) {
    localeParams = {
      1: d,
      2: y
    };
  }

  const locale = i18n.tc(`Common.Date${key}.${+m}`, null, localeParams);

  if (m > 0) {
    return isEstimateDate ? `${estimateSymbol}${locale}` : locale;
  }

  return '';
};