import { treeItemStates as itemStates } from '@/config/demand/tree/tree.config';
import { routeNames } from '@/config/router/router.config';

// To get itemState param for tree/toggleAttentionItemState action in case of 'Needs Attention' button click
export const getAttentionToggleStatus = (itemState) => {
  return [
    itemStates.WARN_ABANDONED,
    itemStates.WARNING,
    itemStates.WARN_MIXED
  ].includes(itemState)
    ? itemStates.NORMAL
    : itemStates.WARNING;
};

// To get itemState param for tree/toggleCheckedItemState action in case of 'Checked' button click
export const getCheckedToggleStatus = (itemState) => {
  return itemState === itemStates.CHECKED
    ? itemStates.NORMAL
    : itemStates.CHECKED;
};

export const getOpenNodes = (nodes) => {
  return nodes.reduce((acc, node, nodeIndex) => {
    if (node.hasChilds && (node.depth < nodes[nodeIndex + 1]?.depth)) {
      acc.push(node.nodeId);
    }

    return acc;
  }, []);
};

export const getNodeUrl = (nodeId) => {
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split('/');

  pathSegments.pop();
  
  const routeIndex = pathSegments.indexOf(routeNames.DEMAND);

  if (nodeId === 0) {
    if (routeIndex !== -1 && pathSegments[routeIndex + 1]) {
      pathSegments.splice(routeIndex + 1, 1);
    }
  } else {
    if (routeIndex !== -1) {
      pathSegments[routeIndex + 1] = nodeId;
    } else {
      pathSegments.push(routeNames.DEMAND, nodeId);
    }
  }

  url.pathname = pathSegments.join('/').replace(/\/{2,}/g, '/');
  url.search = '';

  return url.toString();
};
