export const SelectScroll = {
  inserted: (el, binding, vnode) => {
    const handler = binding?.value;

    if (!handler) {
      return;
    }

    const instance = vnode.componentInstance;
    const scrollNode = el.querySelector('.multiselect__content-wrapper');

    if (!scrollNode) {
      return;
    }

    instance.$watch('isOpen', (isOpen) => {
      if (isOpen) {
        scrollNode.addEventListener('scroll', handler, true);
      } else {
        scrollNode.removeEventListener('scroll', handler, true);
      }
    });
  }
};
