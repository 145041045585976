import monitoring from './monitoring';
import analytics from './analytics';
import { KPIsKey } from '@/config/dashboard/dashboard.config';

const types = {
  SET_TAB: 'SET_TAB'
};

const initialState = () => ({
  tab: KPIsKey
});

const state = initialState();

const mutations = {
  [types.SET_TAB](state, value) {
    state.tab = value;
  }
};

const actions = {
  setTab({ commit }, value) {
    commit(types.SET_TAB, value);
  },
  resetState() {
    this.dispatch('dashboard/analytics/resetState');
    this.dispatch('dashboard/monitoring/resetState');
  }
};

export default {
  namespaced: true,
  modules: {
    monitoring,
    analytics
  },
  state,
  actions,
  mutations
};
