<template>
  <div
    id="app"
    data-app
    :class="[
      'v-application--is-ltr',
      theme
    ]"
  >
    <PendingTasks />
    <SlOverlay :show="initializing">
      <SlLoader :text="false" />
    </SlOverlay>
    <!--  should be hidden until the initialization is complete  -->
    <router-view v-if="!initializing" />
    <notifications
      position="bottom right"
      classes="sl-notification"
    >
      <template #body="{ item, close }">
        <SlNotification
          :item="item"
          :close="close"
        />
      </template>
    </notifications>
    <SlConfirmModal />
    <SlRenameModal />
    <SlLoadingModal />
    <SlErrorModal />
    <SlTableInfoModal />
    <SlUploadFileModal />
    <CreateProjectModal />
    <MetaInfoIssuesModal />
    <UpdateWithLogsModal />
    <FreezeSimulationModal />
    <UpgradeToEnterpriseModal />
    <ErrorsNotifyModal />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { $cwd_embedScript, $cwd_isInTranslationMode } from '@/plugins/localization/crowdin';
import { ampli } from '@/plugins/product/ampli';
import SlNotification from '@/components/UIKit/SlNotification';
import CreateProjectModal from '@/components/Modals/CreateProjectModal.vue';
import MetaInfoIssuesModal from '@/components/Modals/Warnings/MetaInfoIssuesModal.vue';
import UpdateWithLogsModal from '@/components/Modals/UpdateWithLogsModal.vue';
import FreezeSimulationModal from '@/components/Modals/FreezeSimulationModal.vue';
import UpgradeToEnterpriseModal from '@/components/Modals/UpgradeToEnterprise.vue';
import ErrorsNotifyModal from '@/components/Modals/Shared/ErrorsNotifyModal/Index';
import PendingTasks from '@/components/PendingTasks/Index.vue';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { shortcut } from '@/mixins/shortcut';
import { metaInfo } from '@/mixins/metaInfo';
import { initialization } from '@/mixins/initialization';
import { fileSaver } from '@/mixins/webAPI';
import { APP_NAME } from '@/config/app.config.js';
import { logLevel, updateData } from '@/config/project/updateData.config';
import { operationInfoTypes, updateFgsFlags } from '@/config/api/operations.config';
import modalsId from '@/config/shared/modalsId.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { filterTypesByRouteName } from '@/config/filters';
import { registerReportModules } from '@/helpers/report';
import { openLink } from '@/helpers/shared/webAPI';
import { preventDefault } from '@/helpers/shared/listeners';
import { loadLanguageAsync } from '@/plugins/vue-i18n.plugin';
import { loadValidationLanguageAsync } from '@/plugins/vee-validate.plugin';

export default {
  name: 'App',
  components: {
    PendingTasks,
    MetaInfoIssuesModal,
    SlNotification,
    CreateProjectModal,
    UpdateWithLogsModal,
    FreezeSimulationModal,
    UpgradeToEnterpriseModal,
    ErrorsNotifyModal
  },
  mixins: [modal, access, shortcut, metaInfo, initialization, fileSaver],
  data() {
    return {
      modalsId
    };
  },
  provide() {
    return {
      handleReImport: this.reimportCallback
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme,
      initializing: (state) => state.initialization.initializing
    }),
    ...mapGetters({
      projectLabel: 'project/projectLabel',
      isMultipleConnections: 'project/isMultipleConnections',
      isSpreadsheetConnection: 'project/isSpreadsheetConnection',
      isSourceFile: 'project/isSourceFile'
    })
  },
  watch: {
    projectLabel(label) {
      if (!label) {
        return document.title = APP_NAME;
      }

      document.title = `${label} - ${APP_NAME}`;
    },
    'authState.user.accessToken'(newToken, oldToken) {
      if (newToken && newToken !== oldToken) {
        this.setToken(newToken);

        if (!this.initializing) {
          this.appInit();
        }
      }
    },
    'authState.isLoading'(isLoading, wasLoading) {
      if (!isLoading && wasLoading) {
        this.loginIfNeeded();
      }
    },
    $sl_teamMembersLength(newLength, oldLength) {
      if (newLength < oldLength) {
        this.$sl_loadTeamMembers();
      }
    },
    $sl_routeName(routeName) {
      this.setFilterType(filterTypesByRouteName[routeName]);
    }
  },
  async beforeCreate() {
    registerReportModules();

    const language = this.$store.state.settings.language;

    await loadLanguageAsync(language);
    await loadValidationLanguageAsync(language);

  },
  mounted() {
    // prevent default browser validation messages
    document.addEventListener('invalid', preventDefault, true);

    this.setupCrowdin();
  },
  beforeDestroy() {
    document.removeEventListener('invalid', preventDefault);
  },
  methods: {
    ...mapActions({
      reImport: 'project/reImport',
      setIsProjectUpdating: 'project/setIsProjectUpdating',
      fetchSpreadsheetProjectInfo: 'project/fetchSpreadsheetProjectInfo',
      getConnectionInfo: 'connection/getConnectionInfo',
      reImportMultiple: 'integrations/reimportProject',
      clearTreeState: 'demand/tree/resetState',
      setFilterType: 'filters/setFilterType'
    }),
    async handleReImport({ isMultiple, ...params }) {
      try {
        this.setIsProjectUpdating(true);

        const subscriber = isMultiple ? this.reImportMultiple : this.reImport;

        await this.$store.dispatch('operations/subscribe', {
          subscriber: () => subscriber(params),
          paused: ({ operationData, type }) => {
            if (operationData.url && type === operationInfoTypes.NEED_OAUTH) {
              openLink(operationData.url, true);
            }
          },
          ui_flags: async({ operationId, flags }) => {
            if (flags.includes(updateFgsFlags.IMPORT_META_INFO)) {
              const metaInfo = await this.fetchMetaInfo(operationId);

              this.handleImportMetaInfo(metaInfo, operationId, params.type === updateData.FULL ? 'fullReimport' : 'updateData');
            }
          },
          finished: this.clearTreeState
        });

        this.$notify({
          type: 'success',
          title: this.$t('Web.UpdateData.Success')
        });

        if (this.$sl_hasAccess(this.$sl_features.editConnection) && this.isSpreadsheetConnection && !isMultiple) {
          await this.getConnectionInfo();
          await this.fetchSpreadsheetProjectInfo();
        }
      } catch (e) {
        const error = e.error || e;

        if (error.code === slErrorCodes.PROJECT_IMPORTING) {
          return;
        }

        const isWarning = error.code === slErrorCodes.IMPORT_NO_DATA;

        this.$notify({
          type: isWarning ? 'warn' : 'error',
          text: this.isMultipleConnections
            ? `${error?.message}\n${this.$t('Web.Integrations.Errors.FixError')}`
            : isWarning
              ? error?.message
              : this.$t('ProjectChanges.Other.UpdateFailed', { 1: error?.message }),
          duration: -1
        });
      } finally {
        this.setIsProjectUpdating(false);

        ampli.projectReimport();
      }
    },
    async reimportCallback(type = updateData.FULL, logLevelParam = logLevel.NORMAL, storeResult = false) {
      if (!this.$sl_hasAccess(this.$sl_features.updatePrj)) {
        return;
      }

      const params = { type, logLevel: logLevelParam, storeResult };

      if (this.isMultipleConnections) {
        if (this.isSpreadsheetConnection) {
          await this.fetchSpreadsheetProjectInfo();

          if (this.isSourceFile) {
            return this.showModal(modalsId.TRANSACTIONAL_UPDATE_DATA, {
              reimportCallback: (reimportData) => this.handleReImport({
                ...params,
                reimportData,
                isMultiple: true
              }),
              reimportType: type
            });
          }
        }

        return this.handleReImport({
          ...params,
          isMultiple: true
        });
      }

      if (this.isSpreadsheetConnection) {
        await this.fetchSpreadsheetProjectInfo();

        if (this.isSourceFile) {
          return this.showModal(modalsId.SPREADSHEET_UPDATE_DATA, {
            reimportCallback: (reimportData) => this.handleReImport({ ...params, reimportData }),
            reimportType: type
          });
        }
      }

      this.handleReImport(params);
    },
    setupCrowdin() {
      if ($cwd_isInTranslationMode()) {
        $cwd_embedScript();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  max-height: 100vh;
  height: 100vh;
}
</style>
