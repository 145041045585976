import { sourceTypes } from '@/config/integrations/spreadsheet.config';

const microsoftDrivesAdapter = ({ type, sourceType, isOwner, file }) => {
  return {
    type,
    sourceType,
    isOwner,
    files: file.fileId ? [file] : []
  };
};

const linkAdapter = ({ type, sourceType, file }) => {
  return {
    type,
    sourceType,
    files: file.url ? [file] : []
  };
};

const fileAdapter = ({ type, sourceType, file }) => {
  const { fileName: name, sz: size, id } = file;

  return {
    type,
    sourceType,
    files: name ? [{ name, size, id }] : []
  };
};

export default function(data) {
  const adapterByType = {
    [sourceTypes.URL]: linkAdapter,
    [sourceTypes.MICROSOFT_DRIVES]: microsoftDrivesAdapter,
    [sourceTypes.FILE]: fileAdapter
  };

  return adapterByType[data.sourceType]?.(data) || {};
}
