import { connectorRouteNames } from '@/config/router/router.config';

export const newConnectorRoutes = [
  {
    path: 'create-project',
    name: connectorRouteNames.CREATE_PROJECT,
    component: () => import(/* webpackMode: "lazy" */ '@/views/Connectors/Index.vue')
  },
  {
    path: 'create-project/:connector',
    name: connectorRouteNames.CREATE_PROJECT_CONNECTOR,
    component: () => import(/* webpackMode: "lazy" */ '@/views/Connectors/Connector.vue'),
    props: {
      isNew: true
    }
  }
];

export const editConnectorRoutes = [
  {
    path: ':projectId/edit-connection',
    name: connectorRouteNames.EDIT_CONNECTION,
    component: () => import(/* webpackMode: "lazy" */ '@/views/Connectors/Index.vue')
  },
  {
    path: ':projectId/edit-connection/:connector',
    name: connectorRouteNames.EDIT_CONNECTION_CONNECTOR,
    component: () => import(/* webpackMode: "lazy" */ '@/views/Connectors/Connector.vue')
  }
];
