import { routeNames } from '@/config/router/router.config';

export const exportTypes = {
  XLSX: 'xlsx',
  CSV: 'csv'
};

export const sortingOrders = {
  ASCENDING: 'Ascending',
  DESCENDING: 'Descending'
};

export const ordersTypesKeyMap = {
  [routeNames.INVENTORY]: 'Inventory',
  [routeNames.INVENTORY_PLANNED_ORDERS]: 'Inventory',
  [routeNames.INTERSTORE]: 'Optimization',
  [routeNames.INTERSTORE_PLANNED_ORDERS]: 'Optimization'
};

export const tableTypeKeyMap = {
  [routeNames.INVENTORY]: 'inventory',
  [routeNames.INTERSTORE]: 'inventory',
  [routeNames.REPORTS]: 'report'
};

export const columnKeys = {
  ITEMCODE: 'ITEMCODE',
  ITEMDESC: 'ITEMDESC',
  LOCATION: 'LOCATION',
  SUPPLIER: 'SUPPLIER',
  CHANNEL: 'CHANNEL',
  BOMTYPE: 'BOMTYPE',
  AUXFIELD_I: 'AUXFIELD_I',
  ORDER_CYCLE: 'ORDER_CYCLE',
  BOXROUND: 'BOXROUND',
  MINBUNDLE: 'MINBUNDLE',
  MAXBUNDLE: 'MAXBUNDLE',
  SHELF_LIFE: 'SHELF_LIFE',
  SAFETY_SHELF: 'SAFETY_SHELF',
  SAFESTOCK: 'SAFESTOCK',
  SERVICE_LEVEL: 'SERVICE_LEVEL',
  SHELF_DISCARD: 'SHELF_DISCARD',
  SOURCE_FROM: 'SOURCE_FROM',
  NPERIODS: 'NPERIODS',
  ONORDER: 'ONORDER',
  ONORDER_DATE: 'ONORDER_DATE',
  ORDER_NOW_OVERRIDE: 'ORDER_NOW_OVERRIDE',
  CONTAINER: 'CONTAINER',
  ONHAND: 'ONHAND',
  DISTRIB_CENTER: 'DISTRIB_CENTER',
  BACKORDER: 'BACKORDER',
  ITEM_SALES_PRICE: 'ITEM_SALES_PRICE',
  PURCHASE_PRICE: 'PURCHASE_PRICE',
  PURCHASE_VALUE: 'PURCHASE_VALUE',
  GROUP: 'GROUP',
  BOM_GROUP: 'BOM_GROUP',
  ITEM_VOLUME: 'ITEM_VOLUME',
  ITEM_WEIGHT: 'ITEM_WEIGHT',
  ITEM_PALLET: 'ITEM_PALLET',
  NOTE: 'NOTE',
  PURCHASE_PLAN_I_CURR: 'PURCHASE_PLAN_I_CURR',
  LEADTIME: 'LEADTIME',
  LEADTIME_VARIANCE: 'LEADTIME_VARIANCE',
  ITEM_ORDERING_DAYS: 'ITEM_ORDERING_DAYS',
  TRANSFER_REGION: 'TRANSFER_REGION',
  SS_DC_PERIODS: 'SS_DC_PERIODS',
  SS_BOM_PERIODS: 'SS_BOM_PERIODS'
};

export const dropdownKeys = [
  columnKeys.SUPPLIER,
  columnKeys.DISTRIB_CENTER,
  columnKeys.CONTAINER,
  columnKeys.GROUP,
  columnKeys.BOM_GROUP,
  columnKeys.ITEM_ORDERING_DAYS,
  columnKeys.TRANSFER_REGION
];
export const linkKeys = [
  columnKeys.ITEMCODE,
  columnKeys.BOMTYPE,
  columnKeys.SOURCE_FROM
];

export const columnTypes = {
  INTEGER: 'int',
  FLOAT: 'float'
};

export const numberColumnTypes = [
  columnTypes.INTEGER,
  columnTypes.FLOAT
];

export const getInventoryColorInfo = (ctx) => ([
  {
    type: 'inventory',
    text: ctx.$t('Web.Report.ColorHints.InventoryLevel')
  },
  {
    type: 'overstock',
    text: ctx.$t('Web.Report.ColorHints.Overstock')
  },
  {
    type: 'stockout',
    text: ctx.$t('Web.Report.ColorHints.Stockout')
  },
  {
    type: 'editable',
    text: ctx.$t('Web.Report.ColorHints.Editable')
  },
  {
    type: 'manufacturing',
    text: ctx.$t('Web.Report.ColorHints.Manufacturing')
  }
]);
