import Vue from 'vue';
import { i18n } from '@/plugins/vue-i18n.plugin';
import connectorApi from '@/api/integrations/connector';
import databaseRelatedApi from '@/api/integrations/databaseRelated';
import netsuiteApi from '@/api/integrations/netsuite';
import {
  importTabKeys,
  tabKeys
} from '@/config/integrations/transform.config';
import { integrationTypes } from '@/config/integrations';
import { setColSlot } from '@/helpers/connection';
import { checkEqualObject } from '@/helpers/utils/checkEqualObject';
import { slotsReducer } from '@/helpers/integration/slotsReducer';

const importTabsState = () => {
  return importTabKeys.reduce((acc, tab) => {
    acc[tab] = {
      query: '',
      importPreview: {},
      availableSlots: [],
      matchedSlots: {},
      slotsIndexes: {},
      stubs: tab === tabKeys.TRANSACTIONS
        ? [{
          label: i18n.tc('Web.Netsuite.StubStaringDate'),
          value: ':startdate'
        }]
        : []
    };

    return acc;
  }, {});
};

const types = {
  SET_SETTINGS: 'SET_SETTINGS',
  SET_SLOTS: 'SET_SLOTS',
  SET_IMPORT_PREVIEW: 'SET_IMPORT_PREVIEW',
  UPDATE_TAB_SETTINGS: 'UPDATE_TAB_SETTINGS',
  SET_MATCHED_SLOT: 'SET_MATCHED_SLOT',
  SET_SLOTS_INDEXES: 'SET_SLOTS_INDEXES',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  [tabKeys.SETTINGS]: {
    startDate: null
  },
  ...importTabsState()
});

const state = initialState();

const getters = {
  isTabHasSlots: (state) => (tab) => Object.values(state[tab].matchedSlots).some(Boolean)
};

const mutations = {
  [types.SET_SETTINGS](state, value) {
    Object.keys(value).forEach(key => {
      if (typeof state[key] !== 'object') {
        state[key] = value[key];

        return;
      }

      if (state[key] !== undefined) {
        Object.keys(value[key]).forEach(subKey => {
          Vue.set(state[key], subKey, value[key][subKey]);
        });
      }
    });
  },
  [types.SET_SLOTS](state, slots) {
    Object.keys(slots).forEach(key => {
      if (state[key] !== undefined) {
        Vue.set(state[key], 'availableSlots', slots[key]);
      }
    });
  },
  [types.SET_IMPORT_PREVIEW](state, { tab, value }) {
    Vue.set(state[tab], 'importPreview', {
      ...state[tab].importPreview,
      ...value
    });
  },
  [types.UPDATE_TAB_SETTINGS](state, { tab, key, value }) {
    Vue.set(state[tab], key, value);
  },
  [types.SET_MATCHED_SLOT](state, { tab, index, value }) {
    Vue.set(state[tab].matchedSlots, index, value);
  },
  [types.SET_SLOTS_INDEXES](state, { tab, slotKey, indexesList }) {
    Vue.set(state[tab].slotsIndexes, slotKey, indexesList);
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setSettings({ commit }, value) {
    commit(types.SET_SETTINGS, value);
  },
  updateTabSettings({ commit }, payload) {
    commit(types.UPDATE_TAB_SETTINGS, payload);

    this.dispatch('integrations/setIsEdited', true);
  },
  async fetchSlots({ commit }) {
    try {
      const response = await connectorApi.getSlots();

      const slots = response?.data;

      if (!slots) {
        return;
      }

      commit(types.SET_SLOTS, slots);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchSlots' });
    }
  },
  resetUnusedSlots({ state, dispatch }, { tab, headers }) {
    if (!headers?.length) {
      return;
    }

    const { matchedSlots, slotsIndexes } = slotsReducer({
      tabData: state[tab],
      headers
    });

    const isMatchedSlotsEqual = checkEqualObject(state[tab].matchedSlots, matchedSlots);

    if (!isMatchedSlotsEqual ) {
      dispatch('updateTabSettings', {
        tab,
        key: 'matchedSlots',
        value: matchedSlots
      });
    }

    const isSlotsIndexesEqual = checkEqualObject(state[tab].slotsIndexes, slotsIndexes);

    if (!isSlotsIndexesEqual) {
      dispatch('updateTabSettings', {
        tab,
        key: 'slotsIndexes',
        value: slotsIndexes
      });
    }
  },
  setImportPreview({ commit, getters, dispatch }, { tab, value, matchedSlots, slotsIndexes }) {
    commit(types.SET_IMPORT_PREVIEW, {
      tab,
      value
    });

    if (!getters.isTabHasSlots(tab)) {
      dispatch('updateTabSettings', {
        tab,
        key: 'matchedSlots',
        value: matchedSlots || {}
      });

      dispatch('updateTabSettings', {
        tab,
        key: 'slotsIndexes',
        value: slotsIndexes || {}
      });

      return;
    }

    dispatch('resetUnusedSlots', {
      tab,
      headers: value.headers
    });
  },
  setColSlot(context, payload) {
    this.dispatch('integrations/setIsEdited', true);

    return setColSlot(types, context, payload);
  },
  exportToCSV({ dispatch, rootState }, payload) {
    const { type } = rootState.integrations.active_integration;

    if (type === integrationTypes.NETSUITE) {
      return dispatch('netsuiteExportToCSV', payload);
    }

    return dispatch('baseExportToCSV', payload);
  },
  baseExportToCSV({ rootState }, { tab }) {
    const { type, id } = rootState.integrations.active_integration;

    return databaseRelatedApi.exportToCsv(
      { type, id },
      {
        query: state[tab].query,
        startDate: state[tabKeys.SETTINGS].startDate
      }
    );
  },
  netsuiteExportToCSV({ rootState }, { tab }) {
    const { type, id } = rootState.integrations.active_integration;

    return netsuiteApi.exportToCsv(
      { type, id },
      {
        query: state[tab].query,
        startDate: state[tabKeys.SETTINGS].startDate
      }
    );
  },
  downloadCsv({ rootState }, payload) {
    const { type } = rootState.integrations.active_integration;

    return databaseRelatedApi.downloadCsv({
      type,
      ...payload
    });
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
