export default {
  // eslint-disable-next-line
  password: /^(?=.*[a-z])(?=.*(\d|[!@#$%^&*<>~_+\-=?.,"':;\[\]}{()|/\\]))[\w\d!@#$%^&*<>~_+\-=?.,"':;\[\]}{()|/\\]{8,64}$/,
  spaces: /\s+/g,
  spacesOrNewlines: /\s|\n/g,
  simpleInput: /^(?!.*(?:[,. ]){2,})-?[\d,. ]*$/,
  intInput: /^[0-9]*$/,
  negativeIntInput: /^$|^-[0-9]*$|^[0-9]*$/,
  intSubmit: /^\s*$|^-*[0-9]+$/,
  floatInput: /^$|^\d*(\.?\d*)?$/,
  floatSubmit: /^\s*$|^-*(\d+(\.?\d+)*)?$/,
  negativeFloatInput: /^$|^-?\d*(\.?\d*)?$/,
  negativeFloatSubmit: /^\s*$|^-?(\d+(\.?\d+)*)?$/,
  forecastOverridesInput: /^\s?$|^(\d+)$|^(\d*>?)$|^([-+*]?)$|^([-+*]?\d*(\.?\d*)+>?)$|^(\*+\d*(\.?\d*)?[-+]?\d*(\.?\d*)?)$|^(\*?\d*(\.?\d*)?[-+]?\d+>?)$|^\d*(\.?\d*)?$/,
  forecastOverridesSubmit: /^(\s*(\*\s*((\d['\s]?)*([.,]?\d+|(\d['\s]?)+([.,]?\d*))([eE][+-]?\d*)?)?\d\s*)?\s*([+-]\s*(\d['\s]?)*\d\s*)?>?\s*|\s*([+-]?\s*(\d['\s]?)*\d*(\.?\d*)?\s*)?>?)$/,
  encodeSpace: /%20|%2520/g,
  fileName: /[/,?,:,&,\\,*,",<,>,|,#,%,.]/g,
  fileUri: /^file:\/*((?!.*[*?"<>|])[^\s/\\]*[\p{L}\p{N}\p{P}\p{S}\s]*)?\.[a-zA-Z]+$/ui,
  nonDigit: /[^0-9]/g,
  nonLetter: /[^\p{L}]/u,
  parseCsv: /(?:^|\s*,\s*)(?:"([^"]*)"|([^",]*))(?:\s*|,|$)/g,
  shopifyShopName: /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/
};
