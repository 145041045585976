import { mapActions } from 'vuex';
import { fileSaver } from '@/mixins/webAPI';
import { reimportWarningTypes } from '@/config/error/reimportErrors.config';
import modalsId from '@/config/shared/modalsId.config';
import { SALES_DEMO_URL } from '@/config/shared/resources.config';
import { notifyLinkTypes } from '@/config/plugins/notify.config';
import { openLink } from '@/helpers/shared/webAPI';
import { ampli } from '@/plugins/product/ampli';

export const metaInfo = {
  mixins: [fileSaver],
  methods: {
    ...mapActions({
      fetchMetaInfo: 'project/fetchMetaInfo',
      exportBomIssues: 'project/exportBomIssues'
    }),
    handleImportMetaInfo(importIssues, operationId, $amp_reason) {
      if (!importIssues?.length) {
        return;
      }

      importIssues.forEach(issue => {
        if (issue?.type === reimportWarningTypes.CYCLIC_SUBSTITUTIONS) {
          return this.$notify({
            type: 'warn',
            title: this.$t('Main.Ui.acReload'),
            text: issue.data.message,
            duration: -1
          });
        }

        if (issue?.type === reimportWarningTypes.QB_ISSUE) {
          return this.$notify({
            type: 'warn',
            title: this.$t('Web.MetaInfoIssues.ImportWarning'),
            text: this.$t('Web.MetaInfoIssues.QB'),
            duration: -1,
            data: {
              callback: () => this.showModal(modalsId.META_INFO_ISSUES, {
                title: this.$t('Web.MetaInfoIssues.ImportWarning'),
                subtitle: this.$t('Web.MetaInfoIssues.QB'),
                issues: issue.data
              })
            }
          });
        }

        if (issue?.type === reimportWarningTypes.BOM_ISSUE) {
          return this.$notify({
            type: 'warn',
            title: this.$t('Web.MetaInfoIssues.ImportWarning'),
            text: this.$t('Web.MetaInfoIssues.Bom'),
            duration: -1,
            data: {
              callback: () => this.showModal(modalsId.META_INFO_ISSUES, {
                title: this.$t('Web.MetaInfoIssues.ImportWarning'),
                subtitle: this.$t('Web.MetaInfoIssues.Bom'),
                issues: issue.data,
                exportCallback: () => this.saveFile(this.exportBomIssues.bind(this, operationId))
              })
            }
          });
        }

        if (issue?.type === reimportWarningTypes.RESTRICTED_PROJECT) {
          ampli.projectWasRestricted({ projectRestrictionReason: $amp_reason });

          return this.$notify({
            type: 'warn',
            duration: -1,
            title: this.$t('Web.FreeVersion.ImportWarningTitle', { 1: issue.data.nItems }),
            text: this.$t('Web.FreeVersion.ImportWarningText'),
            data: {
              type: notifyLinkTypes.BUILTIN,
              text: this.$t('Web.FreeVersion.ImportWarningLink'),
              callback: () => {
                openLink(SALES_DEMO_URL, true);

                ampli.talkToExpertButtonClicked({ talkToExpertButtonSource: 'projectRestrictedWarning' });
              }
            }
          });
        }

        return this.$notify({
          type: 'warn',
          title: this.$t('Web.MetaInfoIssues.ImportWarning'),
          text: this.$t('Web.MetaInfoIssues.Default'),
          duration: -1
        });
      });
    }
  }
};
