import { FilterObject } from '@/helpers/api/adapters';
import { creationTypes } from '@/config/shared/creationTypes.config';

export default function(data) {
  if (!data) {
    return null;
  }

  return {
    substitutionRuleType: creationTypes.CUSTOM,
    ...FilterObject(data)
  };
}