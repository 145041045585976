import axios from 'axios';
import { bypassPendingRequests } from '@/config/api/helperRequests.config';
import { stringify } from '@/helpers/utils/JSON';

const pendingRequests = new Map();

const getRequestKey = (config) => {
  if (!config) {
    return null;
  }

  const { url, method, params, data } = config;

  return [url, method, params, data]
    .map(i => typeof i !== 'string' ? stringify(i) : i)
    .join('-');
};

export const setPendingRequest = (config) => {
  if (!config || bypassPendingRequests.includes(config.url)) {
    return;
  }

  const requestKey = getRequestKey(config);

  if (pendingRequests.has(requestKey)) {
    throw new axios.Cancel('Request already in progress');
  } else {
    pendingRequests.set(requestKey, true);
  }
};

export const removePendingRequest = (config) => {
  if (!config || bypassPendingRequests.includes(config.url)) {
    return;
  }

  const requestKey = getRequestKey(config);

  if (pendingRequests.has(requestKey)) {
    pendingRequests.delete(requestKey);
  }
};