import settingsApi from '@/api/settings';
import clientStorage from '@/api/clientStorage';
import { themesKeys } from '@/config/shared/themes.config';
import { treeFilterKeys } from '@/config/demand/tree/tree.config';
import { validateLocales } from '@/config/plugins/vee-validate.config';
import clientStorageKeysConfig from '@/config/api/clientStorageKeys.config';
import { abcAnalysisSumKeys } from '@/config/shared/settings.config';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { loadValidationLanguageAsync } from '@/plugins/vee-validate.plugin';
import SettingsDataResponseAdapter from '@/adapters/response/SettingsDataResponse.adapter';
import SettingsDataRequestAdapter from '@/adapters/request/SettingsDataRequest.adapter';
import { setLanguageHeader } from '@/helpers/api/axios';
import { setNestedValue } from '@/helpers/utils/setNestedValue';
import { availableLocales } from '@/config/plugins/vue-i18n.config';

const types = {
  SET_SETTINGS: 'SET_SETTINGS',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_IS_CHANGED: 'SET_IS_CHANGED',
  SET_SETTINGS_LANGUAGE: 'SET_SETTINGS_LANGUAGE',
  SET_COLORBLIND_MODE: 'SET_COLORBLIND_MODE',
  SET_AVAILABLE_OPTIONS: 'SET_AVAILABLE_OPTIONS'
};

const state = () => ({
  settings: null,
  is_fetching: false,
  is_changed: false,
  language: clientStorage.getDataByKey(clientStorageKeysConfig.CURRENT_LOCALE_KEY) || availableLocales[0],
  colorblind_mode: !!clientStorage.getDataByKey(clientStorageKeysConfig.COLORBLIND_MODE),
  available_options: null
});

const getters = {
  supplyUnit: state => state.settings?.supplyUnit,
  currency: state => state.settings?.currency,
  inventoryOrderStrategy: state => state.settings?.inventory?.orderStrategy,
  containerWeight: state => state.settings?.containerSettings?.containerWantWeight
    ? state.settings.containerSettings.containerWeight
    : '',
  containerVolume: state => state.settings?.containerSettings?.containerWantVolume
    ? state.settings.containerSettings.containerVolume
    : '',
  containerQty: state => state.settings?.containerSettings?.containerWantQty
    ? state.settings.containerSettings.containerQty
    : '',
  containerCost: state => state.settings?.containerSettings?.containerWantCost
    ? state.settings.containerSettings.containerCost
    : ''
};

const mutations = {
  [types.SET_SETTINGS](state, value) {
    state.settings = value;
  },
  [types.SET_IS_FETCHING](state, value) {
    state.is_fetching = value;
  },
  [types.SET_IS_CHANGED](state, value) {
    state.is_changed = value;
  },
  [types.SET_SETTINGS_LANGUAGE](state, value) {
    state.language = value;
  },
  [types.SET_COLORBLIND_MODE](state, value) {
    state.colorblind_mode = value;
  },
  [types.SET_AVAILABLE_OPTIONS](state, value) {
    state.available_options = value;
  }
};

const actions = {
  async fetchSettings({ commit }, setIsFetching = false) {
    try {
      if (setIsFetching) {
        commit(types.SET_IS_FETCHING, true);
      }

      const response = await settingsApi.getSettings();

      if (response?.data) {
        commit(types.SET_SETTINGS, SettingsDataResponseAdapter(response.data));
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchSettings' });
    } finally {
      commit(types.SET_IS_FETCHING, false);
    }
  },
  async fetchAvailableOptions({ commit }) {
    try {
      const response = await settingsApi.getAvailableOptions();

      if (response?.data) {
        commit(types.SET_AVAILABLE_OPTIONS, response.data);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchAvailableOptions' });
    }
  },
  async changeSettings({ state, commit }, { key, subKey, value }) {
    try {
      commit(types.SET_IS_CHANGED, true);

      const settingsToUpdate = {
        [key]: subKey ? { [subKey]: value } : value
      };

      if (['autoUpdate', 'autoExport', 'autoUpdateConstrainedOrderingPlan'].includes(key)) {
        settingsToUpdate[key] = {
          ...state.settings[key],
          [subKey]: value
        };
      }

      return settingsApi.changeSettings(SettingsDataRequestAdapter(settingsToUpdate));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'changeSettings' });
    }
  },
  async saveSettings({ state, commit, dispatch }, { i18n }) {
    try {
      commit(types.SET_IS_FETCHING, true);

      dispatch('saveColorblindMode');

      if (state.settings?.abc.func === abcAnalysisSumKeys.NONE) {
        this.dispatch('demand/tree/setFilterByKey', {
          [treeFilterKeys.ABC_ANALYSIS]: false,
          filterId: DEFAULT_FILTER_ID
        });
      }

      const response =  await settingsApi.saveSettings();

      return response;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'saveSettings' });

      throw e;
    } finally {
      commit(types.SET_IS_FETCHING, false);
      commit(types.SET_IS_CHANGED, false);
      dispatch('saveLanguage', i18n);
    }
  },
  async discardSettings() {
    try {
      await settingsApi.discardSettings();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'discardSettings' });
    }
  },
  changeSafetyStockSettings({ dispatch }, { keys, cell }) {
    const { key, path, value } = cell;
    let settingsToUpdate = {};

    if (path) {
      const nestedSettings = setNestedValue({}, path, value);

      settingsToUpdate = { [key]: nestedSettings };
    } else {
      settingsToUpdate = { [key]: value };
    }

    dispatch('changeSettings', {
      key: 'safetyStock',
      value: { keys, cell: settingsToUpdate }
    });
  },
  async cancelSettingsEdit({ commit, dispatch }) {
    commit(types.SET_IS_CHANGED, false);

    await dispatch('discardSettings');
  },
  setLanguageByCode({ commit }, code) {
    commit(types.SET_SETTINGS_LANGUAGE, code);
    setLanguageHeader(code);
  },
  saveLanguage({ state }, i18n) {
    const currentLanguage = i18n.locale;

    clientStorage.setDataByKey(clientStorageKeysConfig.CURRENT_LOCALE_KEY, state.language);

    if (state.language !== currentLanguage) {
      // load & set validation locale
      loadValidationLanguageAsync(validateLocales[state.language], state.language);

      window.location.reload();
    }
  },
  setColorblindMode({ commit }, value) {
    commit(types.SET_COLORBLIND_MODE, value);
  },
  saveColorblindMode({ state }) {
    this.dispatch('setTheme', state.colorblind_mode ? themesKeys.COLORBLIND : themesKeys.LIGHT);
    clientStorage.setDataByKey(clientStorageKeysConfig.COLORBLIND_MODE, state.colorblind_mode);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
