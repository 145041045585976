<template>
  <div
    class="sl-breadcrumb"
    :class="{
      'sl-breadcrumb--active': active
    }"
  >
    <div
      v-if="index !== 0"
      class="sl-breadcrumb__divider grey-70"
    >
      <slot
        v-if="$scopedSlots.divider"
        name="divider"
      />
      <template v-else>
        <icon
          data="@icons/chevron_right.svg"
          class="size-16 fill-off"
        />
      </template>
    </div>
    <SlLink
      :size="size"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot />
    </SlLink>
  </div>
</template>

<script>
export default {
  name: 'SlBreadcrumb',
  props: {
    index: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'md'
    },
    active: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils";

.sl-breadcrumb {
  display: flex;
  align-items: center;
  min-width: 30px;

  overflow: hidden;

  &__divider {
    height: 16px;
    margin: 0 8px;
  }

  .sl-link {
    display: block;

    color: $grey-70;
    text-decoration: underline;
    @include text-ellipsis;

    &:hover, &:active {
      color: $grey-90;
    }
  }

  &.sl-breadcrumb--active {
    .sl-link {
      display: block;

      color: $grey-90;
      text-decoration: none;
    }
  }
}
</style>