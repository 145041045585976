import Vue from 'vue';
import suppliersApi from '@/api/suppliers';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { getTableConfigStructure, getTableConfigParams, sortingParamsAdapter } from '@/helpers/shared/tableConfig';
import { ampli } from '@/plugins/product/ampli';
import { exportTypes } from '@/config/report/inventoryReport';
import { suppliersKeys } from '@/config/suppliers';

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_TAB: 'SET_TAB',
  SUBMIT_CELL_VALUE: 'SUBMIT_CELL_VALUE',
  SELECT_ROW: 'SELECT_ROW',
  RESET_STATE: 'RESET_STATE',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG'
};

const initialState = () => ({
  [suppliersKeys.SUPPLIERS]: {
    table: null,
    tableConfig: getTableConfigStructure()
  },
  [suppliersKeys.HOLIDAYS]: {
    table: null,
    tableConfig: getTableConfigStructure()
  },
  tab: suppliersKeys.SUPPLIERS
});

const state = initialState();

const getters = {
  isEdited: state => Object.values(suppliersKeys).some(tab => {
    return state[tab]?.table?.metadata?.edited;
  })
};

const mutations = {
  [types.SET_TABLE](state, { tab, table }) {
    Vue.set(state[tab], 'table', table);
  },
  [types.SET_TAB](state, tab) {
    state.tab = tab;
  },
  [types.SUBMIT_CELL_VALUE](_, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, value);
  },
  [types.SELECT_ROW](_, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, { val: value });
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state[state.tab].tableConfig, key, value);
  },
  [types.SET_TABLE_CONFIG](state, { tab, payload }) {
    Object.assign(state[tab].tableConfig, payload);
  }
};

const actions = {
  setTab({ commit }, tab) {
    commit(types.SET_TAB, tab);
  },
  async fetchEntities({ state, commit }, tab = null) {
    try {
      const activeTab = tab || state.tab;

      const response = await suppliersApi.getEntitiesTable({
        tab: activeTab,
        ...getTableConfigParams(state[activeTab].tableConfig)
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_TABLE, {
        tab: activeTab,
        table: GenericTableAdapter(response.data)
      });

      commit(types.SET_TABLE_CONFIG, {
        tab: activeTab,
        payload: sortingParamsAdapter(response.data.table)
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/fetchEntities' });
    }
  },
  fetchSuppliers({ dispatch }) {
    return dispatch('fetchEntities', suppliersKeys.SUPPLIERS);
  },
  fetchSuppliersHolidays({ dispatch }) {
    return dispatch('fetchEntities', suppliersKeys.HOLIDAYS);
  },
  async createEntity({ state }, payload) {
    try {
      await suppliersApi.createEntity({ tab: state.tab }, payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/createEntity' });
    }
  },
  async deleteRows({ state }) {
    try {
      await suppliersApi.deleteEntities({ tab: state.tab });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/deleteRows' });
    }
  },
  async fetchSupplierNames(_, payload) {
    try {
      const response = await suppliersApi.getSupplierNames(payload);

      return response.data.suppliers;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/getSupplierNames' });
    }
  },
  async searchSuppliers(_, payload) {
    try {
      const response = await suppliersApi.getSuppliersList({
        ...payload,
        top: 20,
        skip: 0
      });

      return response.data?.suppliers || [];
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/searchSuppliers' });
    }
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  exportToXlsx({ state }) {
    ampli.suppliersExported({
      exportFormat: exportTypes.XLSX
    });

    return suppliersApi.exportToXlsx({ tab: state.tab });
  },
  importFromXlsx({ state }, file) {
    return suppliersApi.importFromXlsx({ tab: state.tab }, file);
  },
  async updateOverrides({ state }, { id, ...value }) {
    try {
      await suppliersApi.overrideEntity({
        tab: state.tab,
        global: !id,
        id,
        ...(!id && {
          query: getTableConfigParams(state[state.tab].tableConfig).query
        })
      }, value);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/updateOverrides' });
    }
  },
  async saveOverrides() {
    try {
      await suppliersApi.applyOverrides();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/saveOverrides' });
    }
  },
  async discardOverrides() {
    try {
      await suppliersApi.deleteOverrides();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'suppliers/discardOverrides' });
    }
  },
  selectRow({ state, commit }, { rowId, ...payload }) {
    const rows = state[state.tab].table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    commit(types.SELECT_ROW, {
      cell: rows[rowIndex],
      ...payload
    });
  },
  submitCell({ state, commit }, { rowId, cellKey, value }) {
    const rows = state[state.tab].table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    if (rowIndex === -1) {
      return;
    }

    const oldCell = rows[rowIndex][cellKey];

    const newValue = {
      ...oldCell,
      val: value
    };

    commit(types.SUBMIT_CELL_VALUE, {
      cell: rows[rowIndex],
      cellKey,
      value: newValue
    });
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
