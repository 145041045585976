import { parse, stringify } from '@/helpers/utils/JSON';

export default {
  setDataByKey(key, data) {
    window.localStorage.setItem(key, stringify(data));
  },
  getDataByKey(key) {
    return parse(window.localStorage.getItem(key));
  }
};
