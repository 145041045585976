import { sortingOrders } from '@/config/report/inventoryReport';
import { contextMenuFgs, demandTableContextMenuFgs } from '@/config/shared/fgs.config';
import { shortcutsConfig } from '@/config/shortcuts/shortcuts.config';

export const contextMenuAdjustment = {
  pageX: 70,
  pageY: 30
};

export const fgsActionTypes = {
  ENABLED: 'enabledAcs',
  VISIBLE: 'visibleAcs'
};

export const contextMenuOptionTypes = {
  DIVIDER: 'divider'
};

export const contextMenuKeys = {
  APPROVE: 'approve',
  NEEDS_ATTENTION: 'attention',
  CHECKED: 'checked',
  EXPORT_NODE_TO_XLSX: 'export-node-to-xlsx',
  CLEAR_OVERRIDES: 'clear-overrides',
  CLEAR_SETTINGS: 'clear-settings',
  CUT: 'cut',
  COPY: 'copy',
  PASTE: 'paste',
  DELETE: 'delete',
  APPLY_CELL_TO_FINAL_FORECAST: 'apply-cell-to-final-forecast',
  APPLY_ROW_TO_FINAL_FORECAST: 'apply-row-to-final-forecast',
  PROPAGATE_VALUE: 'propagate-value',
  COPY_ITEM_TO_CLIPBOARD: 'copy-item-to-clipboard',
  SUBSTITUTE: 'substitute',
  HIDE_COLUMN: 'hide-column',
  PIN_COLUMN: 'pin-column',
  SORT_COLUMN: 'sort-column',
  SORT_COLUMN_ASC: 'sort-column:asc',
  SORT_COLUMN_DESC: 'sort-column:desc',
  INVENTORY_COL_VISIBILITY: 'inventory-col-visibility',
  REMOVE_FROM_IMPORT: 'remove-from-import',
  FILTER_BY_BOM: 'filter-by-bom',
  FILTER_BY: 'filter-by',
  FILTER_ADD: 'filter-add',
  SELECT_ALL: 'select-all',
  EDIT: 'edit',
  ADD_NOTE: 'add-note',
  EDIT_NOTE: 'edit-note',
  FIND_IN_DEMAND: 'find-in-demand',
  FIND_IN_INVENTORY: 'find-in-inventory',
  FIND_IN_REPORTS: 'find-in-reports',
  ITEM_BOM: 'material-product-tree',
  EXPLAIN_CALCULATION: 'explain-calculation',
  COPY_NODE_LINK: 'copy-node-link',
  OPEN_NODE_IN_NEW_TAB: 'open-node-in-new-tab',
  RESET_NEXT_ORDER_DATE: 'reset-next-order-date',
  COPY_CELL_FROM_STATISTICAL_FORECAST: 'copy-cell-from-statistical-forecast',
  COPY_ROW_FROM_STATISTICAL_FORECAST: 'copy-row-from-statistical-forecast',
  CONVERT_CELL_FORMULAS_TO_VALUES: 'convert_cell_formulas_to_values',
  CONVERT_ROW_FORMULAS_TO_VALUES: 'convert_row_formulas_to_values',
  COPY_QTY_TO_OVERRIDE: 'copy-qty-to-override',
  ZOOM_OUT: 'zoom-out',
  IGNORE_ACTUAL_SALES_BEFORE: 'ignore-actual-sales-before',
  IGNORE_TREND_BEFORE: 'ignore-trend-before'
};

//======================== REDESIGNED ====================================\\
export const demandChartOptions = (ctx, period) => ([
  {
    name: ctx.$t('Main.Ui.acChartZoomOut'),
    key: contextMenuKeys.ZOOM_OUT,
    group: 'canvas'
  },
  {
    name: ctx.$t('Main.IgnoreActualSales', { 1: period }),
    key: contextMenuKeys.IGNORE_ACTUAL_SALES_BEFORE
  },
  {
    name: ctx.$t('Main.IgnoreBefore', { 1: period }),
    key: contextMenuKeys.IGNORE_TREND_BEFORE
  }
]);

export const demandChartActionsMap = {
  [contextMenuKeys.ZOOM_OUT]: 'zoomOut',
  [contextMenuKeys.IGNORE_ACTUAL_SALES_BEFORE]: 'ignoreActualSalesBefore',
  [contextMenuKeys.IGNORE_TREND_BEFORE]: 'ignoreTrendBefore'
};

export const reportHeaderContextMenuOptions = (ctx) => ([
  {
    name: (isPinned) => isPinned ? ctx.$t('Web.Table.Unpin') : ctx.$t('Web.Table.Pin'),
    key: contextMenuKeys.PIN_COLUMN,
    icon: (isPinned) => isPinned ? 'unpin' : 'pin'
  },
  {
    name: ctx.$t('Main.CustomizeColumnsSubmenus.Hide'),
    key: contextMenuKeys.HIDE_COLUMN,
    icon: 'eye_slash'
  },
  {
    name: ctx.$t('Web.Table.Sort'),
    key: contextMenuKeys.SORT_COLUMN,
    icon: 'sort',
    options: sortContextMenuOptions(ctx)
  }
]);

export const reportHeaderContextActionsMap = {
  [contextMenuKeys.PIN_COLUMN]: 'pinColumn',
  [contextMenuKeys.HIDE_COLUMN]: 'hideColumn'
};

export const sortContextMenuOptions = (ctx) => ([
  {
    name: ctx.$t('Web.Table.SortAsc'),
    order: sortingOrders.ASCENDING,
    icon: 'arrow-up'
  },
  {
    name: ctx.$t('Web.Table.SortDesc'),
    order: sortingOrders.DESCENDING,
    icon: 'arrow-down'
  }
]);

export const reportTableContextMenuOptions = (ctx) => {
  const shortcuts = shortcutsConfig(ctx).INVENTORY_REPORTS_TABLE.values;

  return [
    {
      name: ctx.$t('Main.Ui.acFilterBy'),
      key: contextMenuKeys.FILTER_BY,
      fgs: contextMenuFgs.FILTER_BY
    },
    {
      name: ctx.$t('Main.Ui.acAddToFilter'),
      key: contextMenuKeys.FILTER_ADD,
      fgs: contextMenuFgs.ADD_TO_FILTER
    },
    {
      name: ctx.$t('Main.Ui.acFilterBom'),
      key: contextMenuKeys.FILTER_BY_BOM,
      fgs: contextMenuFgs.FILTER_BY_BOM
    },
    {
      type: 'divider'
    },
    {
      name: ctx.$t('Web.ContextMenu.FindInDemand'),
      key: contextMenuKeys.FIND_IN_DEMAND,
      fgs: contextMenuFgs.FIND_IN_DEMAND
    },
    {
      name: ctx.$t('Main.Ui.acFindInInventoryReport'),
      key: contextMenuKeys.FIND_IN_INVENTORY,
      fgs: contextMenuFgs.FIND_IN_IR
    },
    {
      name: ctx.$t('Main.Ui.acFindInReports'),
      key: contextMenuKeys.FIND_IN_REPORTS,
      fgs: contextMenuFgs.FIND_IN_FR
    },
    {
      type: 'divider'
    },
    {
      name: ctx.$t('Main.Ui.acItemBom'),
      key: contextMenuKeys.ITEM_BOM,
      shortcut: shortcuts.OPEN_MATERIAL_PRODUCT_TREE.shortcut,
      fgs: contextMenuFgs.ITEM_BOM
    },
    {
      name: ctx.$t('Main.Ui.acBanFromImport'),
      key: contextMenuKeys.REMOVE_FROM_IMPORT,
      shortcut: shortcuts.REMOVE_FROM_IMPORT.shortcut,
      fgs: contextMenuFgs.REMOVE_FROM_IMPORT
    },
    {
      name: ctx.$t('Main.Ui.acReplaceWith'),
      key: contextMenuKeys.SUBSTITUTE,
      fgs: contextMenuFgs.SUBSTITUTE
    },
    {
      type: 'divider'
    },
    {
      name: ctx.$t('Main.Ui.acInventorySelectAll'),
      key: contextMenuKeys.SELECT_ALL,
      shortcut: shortcuts.SELECT_ALL_ROWS.shortcut
    },
    {
      name: ctx.$t('Web.Edit.Copy'),
      key: contextMenuKeys.COPY
    },
    {
      name: ctx.$t('Web.Context.CopyFromQty'),
      key: contextMenuKeys.COPY_QTY_TO_OVERRIDE,
      fgs: contextMenuFgs.COPY_QTY_TO_OVERRIDE
    },
    {
      name: ctx.$t('Web.Tree.ContextCopyItemCode'),
      key: contextMenuKeys.COPY_ITEM_TO_CLIPBOARD,
      shortcut: shortcuts.COPY_ITEM.shortcut,
      fgs: contextMenuFgs.COPY_ITEM_CODE
    },
    {
      name: ctx.$t('Main.AddNote'),
      key: contextMenuKeys.ADD_NOTE,
      fgs: contextMenuFgs.ADD_NOTE
    },
    {
      name: ctx.$t('Main.EditNote'),
      key: contextMenuKeys.EDIT_NOTE,
      fgs: contextMenuFgs.EDIT_NOTE
    },
    {
      name: ctx.$t('Main.Ui.acClearOverrides'),
      key: contextMenuKeys.CLEAR_OVERRIDES,
      fgs: contextMenuFgs.CLEAR_OVERRIDES
    },
    {
      name: ctx.$t('Main.Ui.acExplain'),
      key: contextMenuKeys.EXPLAIN_CALCULATION,
      fgs: contextMenuFgs.EXPLAIN_CALCULATION
    },
    {
      name: ctx.$t('Web.ContextMenu.ResetNextOrderDate'),
      key: contextMenuKeys.RESET_NEXT_ORDER_DATE,
      fgs: contextMenuFgs.RESET_NEXT_ORDER_DATE
    }
  ];
};

export const reportTableContextMenuActionsMap = {
  [contextMenuKeys.FILTER_BY]: 'filterBy',
  [contextMenuKeys.FILTER_ADD]: 'addToFilter',
  [contextMenuKeys.FILTER_BY_BOM]: 'filterByRelatedBOMs',
  [contextMenuKeys.COPY]: 'copyCell',
  [contextMenuKeys.COPY_ITEM_TO_CLIPBOARD]: 'copyItemCode',
  [contextMenuKeys.REMOVE_FROM_IMPORT]: 'removeFromImport',
  [contextMenuKeys.CLEAR_OVERRIDES]: 'clearRowOverrides',
  [contextMenuKeys.SELECT_ALL]: 'selectAll',
  [contextMenuKeys.SUBSTITUTE]: 'substituteItem',
  [contextMenuKeys.ADD_NOTE]: 'editNote',
  [contextMenuKeys.EDIT_NOTE]: 'editNote',
  [contextMenuKeys.FIND_IN_DEMAND]: 'findInDemand',
  [contextMenuKeys.FIND_IN_INVENTORY]: 'findInInventory',
  [contextMenuKeys.FIND_IN_REPORTS]: 'findInReports',
  [contextMenuKeys.ITEM_BOM]: 'openBoms',
  [contextMenuKeys.EXPLAIN_CALCULATION]: 'openExplainCalculation',
  [contextMenuKeys.RESET_NEXT_ORDER_DATE]: 'handleResetNextOrderDate',
  [contextMenuKeys.COPY_QTY_TO_OVERRIDE]: 'handleCopyQtyToOverride'
};

export const reportTableContextMenuMiddleActionsMap = {
  [contextMenuKeys.FIND_IN_DEMAND]: 'findInDemandInNewTab',
  [contextMenuKeys.FIND_IN_INVENTORY]: 'findInInventoryInNewTab',
  [contextMenuKeys.FIND_IN_REPORTS]: 'findInReportsInNewTab'
};

export const treeContextMenuOptions = (ctx) => {
  const shortcuts = shortcutsConfig(ctx).DEMAND_TREE.values;

  return [
    {
      name: ctx.$t('Web.Tree.ContextOpenInNewTab'),
      key: contextMenuKeys.OPEN_NODE_IN_NEW_TAB
    },
    {
      name: ctx.$t('Web.Tree.ContextLinkAddress'),
      key: contextMenuKeys.COPY_NODE_LINK
    },
    {
      name: ctx.$t('Web.Tree.ContextCopyItemCode'),
      key: contextMenuKeys.COPY_ITEM_TO_CLIPBOARD,
      shortcut: shortcuts.COPY_CODE.shortcut,
      fgs: contextMenuFgs.COPY_ITEM_CODE
    },
    {
      type: 'divider'
    },
    {
      name: ctx.$t('Main.Ui.acApprove'),
      key: contextMenuKeys.APPROVE,
      shortcut: shortcuts.SET_APPROVE_UNAPPROVE.shortcut,
      fgs: contextMenuFgs.APPROVE
    },
    {
      name: ctx.$t('Main.Ui.acNeedsAttention'),
      key: contextMenuKeys.NEEDS_ATTENTION,
      shortcut: shortcuts.SET_ATTENTION.shortcut,
      fgs: contextMenuFgs.NEEDS_ATTENTION
    },
    {
      name: ctx.$t('Main.Ui.acMarkChecked'),
      key: contextMenuKeys.CHECKED,
      shortcut: shortcuts.SET_CHECKED.shortcut,
      fgs: contextMenuFgs.CHECK
    },
    {
      type: 'divider'
    },
    {
      name: ctx.$t('Main.Ui.acFindInInventoryReport'),
      key: contextMenuKeys.FIND_IN_INVENTORY,
      fgs: contextMenuFgs.FIND_IN_IR
    },
    {
      name: ctx.$t('Main.Ui.acFindInReports'),
      key: contextMenuKeys.FIND_IN_REPORTS,
      fgs: contextMenuFgs.FIND_IN_FR
    },
    {
      name: ctx.$t('Main.Ui.acItemBom'),
      key: contextMenuKeys.ITEM_BOM,
      shortcut: shortcuts.OPEN_MATERIAL_PRODUCT_TREE.shortcut,
      fgs: contextMenuFgs.ITEM_BOM
    },
    {
      type: 'divider'
    },
    {
      name: ctx.$t('Main.Ui.acBanFromImport'),
      key: contextMenuKeys.REMOVE_FROM_IMPORT,
      shortcut: shortcuts.REMOVE_FROM_IMPORT.shortcut,
      fgs: contextMenuFgs.REMOVE_FROM_IMPORT
    },
    {
      name: ctx.$t('Main.Ui.acReplaceWith'),
      key: contextMenuKeys.SUBSTITUTE,
      fgs: contextMenuFgs.SUBSTITUTE
    },
    {
      name: ctx.$t('Main.AddNote'),
      key: contextMenuKeys.ADD_NOTE,
      fgs: contextMenuFgs.ADD_NOTE
    },
    {
      name: ctx.$t('Main.EditNote'),
      key: contextMenuKeys.EDIT_NOTE,
      fgs: contextMenuFgs.EDIT_NOTE
    },
    {
      name: ctx.$t('Main.Ui.acClearOverrides'),
      key: contextMenuKeys.CLEAR_OVERRIDES,
      fgs: contextMenuFgs.CLEAR_OVERRIDES
    },
    {
      name: ctx.$t('Main.ClearMultiple'),
      key: contextMenuKeys.CLEAR_SETTINGS,
      options: clearSettingsConfig(ctx)
    },
    {
      name: ctx.$t('Main.LocalExportTable'),
      key: contextMenuKeys.EXPORT_NODE_TO_XLSX,
      options: exportNodeToXlsxConfig(ctx)
    }
  ];
};

export const treeContextMenuActionsMap = {
  [contextMenuKeys.COPY_ITEM_TO_CLIPBOARD]: 'copyItem',
  [contextMenuKeys.COPY_NODE_LINK]: 'copyNodeLink',
  [contextMenuKeys.OPEN_NODE_IN_NEW_TAB]: 'openNodeInNewTab',
  [contextMenuKeys.APPROVE]: 'toggleApproveState',
  [contextMenuKeys.NEEDS_ATTENTION]: 'toggleNeedsAttentionState',
  [contextMenuKeys.CHECKED]: 'toggleCheckedState',
  [contextMenuKeys.FIND_IN_INVENTORY]: 'findInInventory',
  [contextMenuKeys.FIND_IN_REPORTS]: 'findInReports',
  [contextMenuKeys.ITEM_BOM]: 'openBOMs',
  [contextMenuKeys.REMOVE_FROM_IMPORT]: 'removeFromImport',
  [contextMenuKeys.SUBSTITUTE]: 'substituteItem',
  [contextMenuKeys.ADD_NOTE]: 'editNote',
  [contextMenuKeys.EDIT_NOTE]: 'editNote',
  [contextMenuKeys.CLEAR_OVERRIDES]: 'handleClearNodeOverrides'
};

export const treeContextMenuMiddleActionsMap = {
  [contextMenuKeys.FIND_IN_INVENTORY]: 'findInInventoryInNewTab',
  [contextMenuKeys.FIND_IN_REPORTS]: 'findInReportsInNewTab'
};

export const exportNodeToXlsxConfig = (ctx) => ([
  {
    key: 'EXPORT_CURRENT_NODE_TABLE',
    name: ctx.$t('Main.Ui.acExportCurrentToXlsx')
  },
  {
    key: 'EXPORT_CHILD_NODES_TABLE',
    visitLeafs: true,
    name: ctx.$t('Main.Ui.acExportAllToXlsx')
  }
]);

export const clearSettingsConfig = (ctx) => ([
  {
    key: 'IS_MODEL_ACTIVE',
    fgs: contextMenuFgs.IS_MODEL_ACTIVE,
    name: ctx.$t('Main.Ui.lbIsModelActive')
  },
  {
    key: 'FORECAST_APPROACH',
    fgs: contextMenuFgs.CLEAR_FORECAST_APPROACH,
    name: ctx.$t('Main.Ui.acClearMultipleApproach')
  },
  {
    key: 'MODEL',
    fgs: contextMenuFgs.CLEAR_MODEL_TYPE,
    name: ctx.$t('Main.Ui.acClearMultipleModelType')
  },
  {
    key: 'WEEK_52_SEASONS',
    fgs: contextMenuFgs.CLEAR_WEEK_52_SEASONS,
    name: ctx.$t('Web.Demand.Use52Seasons')
  },
  {
    key: 'IGNORE_DATA_BEFORE',
    fgs: contextMenuFgs.CLEAR_IGNORE_TREND,
    name: ctx.$t('Main.Ui.acClearMultipleIgnoreDataBefore')
  },
  {
    key: 'ZEROS_AND_LOST_SALES',
    fgs: contextMenuFgs.CLEAR_IGNORE_ZERO_SALES,
    name: ctx.$t('Main.Ui.acClearMultipleZerosLostSales')
  },
  {
    key: 'IGNORE_LOST_DAYS',
    fgs: contextMenuFgs.CLEAR_IGNORE_STOCKOUT_DAYS,
    name: ctx.$t('Main.Ui.acClearMultipleIgnoreLostDays')
  },
  {
    key: 'AUTODETECT_OUTLIERS',
    fgs: contextMenuFgs.CLEAR_AUTODETECT_OUTLIERS,
    name: ctx.$t('Main.Ui.acClearMultipleDetectOutliers')
  },
  {
    key: 'PRICE_ELASTICITY',
    fgs: contextMenuFgs.CLEAR_USE_PRICE_ELASTICITY,
    name: ctx.$t('Main.Ui.acClearMultiplePriceElasticity')
  },
  {
    key: 'HOLIDAYS',
    fgs: contextMenuFgs.CLEAR_HOLIDAYS,
    name: ctx.$t('Main.Ui.acClearMultipleHolidays')
  },
  {
    key: 'SEASONALITY_PATTERN',
    fgs: contextMenuFgs.CLEAR_SEASONAL_PATTERNS,
    name: ctx.$t('Main.Ui.acClearMultipleSeasonalityPatterns')
  },
  {
    key: 'PROMOTIONS',
    fgs: contextMenuFgs.CLEAR_USE_PROMOTIONS,
    name: ctx.$t('Main.Ui.acClearMultiplePromotions')
  },
  {
    key: 'MODEL_ADOPTING',
    fgs: contextMenuFgs.CLEAR_USE_MODEL_FROM,
    name: ctx.$t('Main.Ui.acClearMultipleModelAdopting')
  },
  {
    key: 'DEMAND_SENSING',
    fgs: contextMenuFgs.CLEAR_DEMAND_SENSING,
    name: ctx.$t('Main.Ui.acClearMultipleDemandSensing')
  },
  {
    key: 'BILL_OF_MATERIAL_USE',
    fgs: contextMenuFgs.CLEAR_MATERIAL_PROCUREMENT,
    name: ctx.$t('Main.Ui.acClearMultipleManufacture')
  },
  {
    key: 'APPROVAL_HORIZON',
    fgs: contextMenuFgs.CLEAR_APPROVAL_HORIZON,
    name: ctx.$t('Main.Ui.acClearMultipleApprovalHorizon')
  },
  {
    key: 'ORDERING_AVAILABILITY',
    fgs: contextMenuFgs.CLEAR_ORDERING_AVAILABILITY,
    name: ctx.$t('Main.Ui.acClearMultipleAllowance')
  },
  {
    key: 'MODEL_COEFFS',
    fgs: contextMenuFgs.CLEAR_MODEL_COEFFS,
    name: ctx.$t('Main.Ui.acClearMultipleModelCoeffs')
  }
]);

export const demandTableContextMenuOptions = (ctx) => ([
  {
    name: ctx.$t('Web.Edit.Cut'),
    key: contextMenuKeys.CUT,
    fgs: demandTableContextMenuFgs[contextMenuKeys.CUT]
  },
  {
    name: ctx.$t('Web.Edit.Copy'),
    key: contextMenuKeys.COPY,
    fgs: demandTableContextMenuFgs[contextMenuKeys.COPY]
  },
  {
    name: ctx.$t('Web.Edit.Paste'),
    key: contextMenuKeys.PASTE,
    fgs: demandTableContextMenuFgs[contextMenuKeys.PASTE]
  },
  {
    name: ctx.$t('Web.Edit.Delete'),
    key: contextMenuKeys.DELETE,
    fgs: demandTableContextMenuFgs[contextMenuKeys.DELETE]
  },
  {
    type: 'divider'
  },
  {
    name: ctx.$t('Main.AddNote'),
    key: contextMenuKeys.ADD_NOTE,
    fgs: demandTableContextMenuFgs[contextMenuKeys.ADD_NOTE]
  },
  {
    name: ctx.$t('Web.ContextMenu.CopyCellFromStatisticalForecast'),
    key: contextMenuKeys.COPY_CELL_FROM_STATISTICAL_FORECAST,
    fgs: demandTableContextMenuFgs[contextMenuKeys.COPY_CELL_FROM_STATISTICAL_FORECAST]
  },
  {
    name: ctx.$t('Web.ContextMenu.CopyRowFromStatisticalForecast'),
    key: contextMenuKeys.COPY_ROW_FROM_STATISTICAL_FORECAST,
    fgs: demandTableContextMenuFgs[contextMenuKeys.COPY_ROW_FROM_STATISTICAL_FORECAST]
  },
  {
    name: ctx.$t('Web.ContextMenu.ConvertCellFormulasToValues'),
    key: contextMenuKeys.CONVERT_CELL_FORMULAS_TO_VALUES,
    fgs: demandTableContextMenuFgs[contextMenuKeys.CONVERT_CELL_FORMULAS_TO_VALUES]
  },
  {
    name: ctx.$t('Web.ContextMenu.ConvertRowFormulasToValues'),
    key: contextMenuKeys.CONVERT_ROW_FORMULAS_TO_VALUES,
    fgs: demandTableContextMenuFgs[contextMenuKeys.CONVERT_ROW_FORMULAS_TO_VALUES]
  },
  {
    name: ctx.$t('Main.ApplyOvPeriod'),
    key: contextMenuKeys.APPLY_CELL_TO_FINAL_FORECAST,
    fgs: demandTableContextMenuFgs[contextMenuKeys.APPLY_CELL_TO_FINAL_FORECAST]
  },
  {
    name: ctx.$t('Main.ApplyOv'),
    key: contextMenuKeys.APPLY_ROW_TO_FINAL_FORECAST,
    fgs: demandTableContextMenuFgs[contextMenuKeys.APPLY_ROW_TO_FINAL_FORECAST]
  },
  {
    name: ctx.$t('Main.Propagate'),
    key: contextMenuKeys.PROPAGATE_VALUE,
    fgs: demandTableContextMenuFgs[contextMenuKeys.PROPAGATE_VALUE]
  },
  {
    name: ctx.$t('Main.Ui.acChooseExportToXlsx'),
    key: contextMenuKeys.EXPORT_NODE_TO_XLSX
  }
]);

export const demandTableContextMenuActionsMap = {
  [contextMenuKeys.COPY]: 'copyCell',
  [contextMenuKeys.CUT]: 'cutCell',
  [contextMenuKeys.DELETE]: 'deleteCell',
  [contextMenuKeys.PASTE]: 'pasteCell',
  [contextMenuKeys.ADD_NOTE]: 'editNote',
  [contextMenuKeys.COPY_CELL_FROM_STATISTICAL_FORECAST]: 'copyCellFromStatisticalForecast',
  [contextMenuKeys.COPY_ROW_FROM_STATISTICAL_FORECAST]: 'copyRowFromStatisticalForecast',
  [contextMenuKeys.CONVERT_CELL_FORMULAS_TO_VALUES]: 'convertCellFormulasToValues',
  [contextMenuKeys.CONVERT_ROW_FORMULAS_TO_VALUES]: 'convertRowFormulasToValues',
  [contextMenuKeys.APPLY_CELL_TO_FINAL_FORECAST]: 'applyCellToFinalForecast',
  [contextMenuKeys.APPLY_ROW_TO_FINAL_FORECAST]: 'applyRowToFinalForecast',
  [contextMenuKeys.PROPAGATE_VALUE]: 'propagateCell',
  [contextMenuKeys.EXPORT_NODE_TO_XLSX]: 'handleExportNodeToXlsx'
};