import axios from '@/plugins/axios';

export default {
  getFilters(params) {
    return axios.get('filters', { params });
  },
  removeFilter(params) {
    return axios.delete('filters', { params });
  },
  duplicateFilter(params) {
    return axios.post('filters/duplicate', null, { params });
  },
  getAvailableFilters(params) {
    return axios.get('filters/available', { params });
  },
  postFiltersOrder(params, body) {
    return axios.post('/filters/order', body, { params });
  },
  applyFilterToPage(params) {
    return axios.post('/filters/apply', null, { params });
  }
};
