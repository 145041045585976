<template>
  <div
    ref="container"
    class="sl-line-skeleton-loader"
  >
    <svg
      class="sl-line-skeleton-loader__svg"
      preserveAspectRatio="xMidYMid meet"
      :viewBox="viewBox"
    >
      <path
        ref="line"
        :d="generatePath()"
        class="sl-line-skeleton-loader__line"
        :style="pathStyles"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SlLineSkeletonLoader',
  props: {
    yPoints: {
      type: Array,
      required: true,
      validator: (value) => value.every((y) => y >= 0 && y <= 10)
    },
    duration: {
      type: Number,
      default: 1.5
    },
    lineWidth: {
      type: Number,
      default: 1.5
    },
    infinite: Boolean,
    autoDraw: Boolean
  },
  data() {
    return {
      containerWidth: 100,
      containerHeight: 100,
      totalLength: 0,
      heightOffset: 20
    };
  },
  computed: {
    viewBox() {
      return `0 0 ${this.containerWidth} ${this.containerHeight}`;
    },
    path() {
      return this.generatePath();
    },
    pathStyles() {
      return {
        animationDuration: this.duration + 's',
        animationIterationCount: this.infinite ? 'infinite' : '1',
        strokeDasharray: this.autoDraw ? this.totalLength : 'none',
        strokeDashoffset: this.autoDraw ? this.totalLength : '0',
        strokeWidth: this.lineWidth
      };
    }
  },
  mounted() {
    this.updateDimensions();
  },
  methods: {
    generatePath() {
      if (this.yPoints.length === 0) {
        return '';
      }

      const minY = Math.min(...this.yPoints);
      const maxY = Math.max(...this.yPoints);

      let d = `M 0 ${this.containerHeight - ((this.yPoints[0] - minY) / (maxY - minY)) * this.containerHeight}`;

      for (let i = 1; i < this.yPoints.length; i++) {
        const x = (i / (this.yPoints.length - 1)) * this.containerWidth;
        const y = this.containerHeight - ((this.yPoints[i] - minY) / (maxY - minY)) * this.containerHeight;

        d += ` L ${x} ${y}`;
      }

      return d;
    },
    updateTotalLength() {
      if (!this.$refs.line) {
        return;
      }

      requestAnimationFrame(() => {
        this.totalLength = this.$refs.line?.getTotalLength();
        this.$refs.line.style.strokeDasharray = this.totalLength;
        this.$refs.line.style.strokeDashoffset = this.autoDraw ? this.totalLength : '0';
      });
    },
    updateDimensions() {
      const container = this.$refs.container;

      if (!container) {
        return;
      }

      this.containerWidth = Math.floor(container.clientWidth);
      this.containerHeight = Math.floor(container.clientHeight - this.heightOffset);

      this.updateTotalLength();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-line-skeleton-loader";
</style>
