import defaultAxios from 'axios';
import globalConfig from '@/config/global.config';
import { getFronteggEnv } from '@/helpers/shared/fronteggEnv';

export const AIAssistantAxios = defaultAxios.create();

export const setupAIAssistantAxios = () => {
  const env = getFronteggEnv();

  AIAssistantAxios.defaults.baseURL = globalConfig.AI_CHAT_URL_BY_ENV[env];
};
