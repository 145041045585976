import AIAssistantApi from '@/api/AIAssistant';
import { CHAT_ROLES } from '@/config/AIAssistant/AIAssistant.config';
import { toArray } from '@/helpers/utils/toArray';

const types = {
  SET_MESSAGES: 'SET_MESSAGES',
  PUSH_MESSAGE: 'PUSH_MESSAGE',
  REPLACE_LAST_MESSAGE: 'REPLACE_LAST_MESSAGE',
  DELETE_LAST_MESSAGE: 'DELETE_LAST_MESSAGE',
  SET_CHAT_ID: 'SET_CHAT_ID'
};

const initialState = () => ({
  chat_id: null,
  messages: []
});

const state = initialState();

const getters = {
  hasPending: (state) => state.messages.some(m => m.pending)
};

const mutations = {
  [types.SET_CHAT_ID](state, value) {
    state.chat_id = value;
  },
  [types.SET_MESSAGES](state, value) {
    state.messages = value;
  },
  [types.PUSH_MESSAGE](state, message) {
    state.messages = [...state.messages, message];
  },
  [types.REPLACE_LAST_MESSAGE](state, message) {
    state.messages.splice(state.messages.length - 1, 1, message);
  },
  [types.DELETE_LAST_MESSAGE](state) {
    state.messages.splice(state.messages.length - 1, 1);
  }
};

const actions = {
  async fetchUserChats({ commit }, payload) {
    try {
      const response = await AIAssistantApi.getUserChat(payload);

      const chatId = response?.data?.chat_id;

      if (!chatId) {
        throw {
          code: 'web',
          message: 'No chat_id from server'
        };
      }

      commit(types.SET_CHAT_ID, chatId);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'AIAssistant/fetchUserChats' });

      throw e;
    }
  },
  fetchChatMessages({ commit, dispatch }, id) {
    commit(types.SET_CHAT_ID, id);

    dispatch('fetchCurrentChatMessages');
  },
  async fetchCurrentChatMessages({ state, commit }) {
    try {
      const response = await AIAssistantApi.getChatMessages(state.chat_id);

      const messages = toArray(response?.data?.messages);

      commit(types.SET_MESSAGES, messages);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'AIAssistant/fetchCurrentChatMessages' });
    }
  },
  async sendUserMessage({ state, commit }, payload) {
    try {
      commit(types.PUSH_MESSAGE, {
        role: CHAT_ROLES.USER,
        message: payload
      });

      commit(types.PUSH_MESSAGE, {
        role: CHAT_ROLES.ASSISTANT,
        pending: true,
        message: ''
      });

      const response = await AIAssistantApi.putChatMessage(state.chat_id, payload);

      const AIMessage = response?.data?.message;

      if (!AIMessage) {
        throw {
          code: 'web',
          message: 'Chat answered with an empty message. Try again later.'
        };
      }

      commit(types.REPLACE_LAST_MESSAGE, {
        role: CHAT_ROLES.ASSISTANT,
        message: AIMessage
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'AIAssistant/fetchCurrentChatMessages' });

      commit(types.DELETE_LAST_MESSAGE);

      throw e;
    }
  },
  async resetUserChat({ dispatch }, userId) {
    await dispatch('fetchUserChats', userId);

    dispatch('resetCurrentChat');
  },
  async resetCurrentChat({ state, commit }) {
    try {
      await AIAssistantApi.deleteChat(state.chat_id);

      commit(types.SET_MESSAGES, []);
      commit(types.SET_CHAT_ID, null);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'AIAssistant/resetCurrentChat' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
