import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import { HttpError } from './HttpError';
import { errorRegistry } from './errorRegistry';
import statusCodes from '@/config/utils/statusCodes.config';
import { updateResponse, updateLastChangeStamps } from '@/api/helpers/updateResponse';
import { removePendingRequest, setPendingRequest } from '@/api/helpers/pendingRequests';

export const requestSuccess = async(config) => {
  setPendingRequest(config);

  config.headers['x-email'] = Vue.mapAuthState()?.authState?.user?.email;
  config.headers['X-pid'] = config.headers['X-pid'] || store.state.manageProjects.projectId || '';

  return config;
};

export const requestError = (error) => {
  return Promise.reject(error);
};

export const responseSuccess = async(response) => {
  removePendingRequest(response?.config);

  if (response?.config?.raw) {
    return response;
  }

  if ([statusCodes.NOT_MODIFIED, statusCodes.OK].includes(response?.status)) {
    await updateLastChangeStamps(response);
    await updateResponse(response, response?.config?.method);

    // todo: refactor response structure with API change
    return response;
  }

  if (!response?.data) {
    throw new HttpError('API error. No data');
  }

  throw new HttpError('API error. Not OK');
};

export const responseError = (error) => {
  if (axios.isCancel(error)) {
    return error?.response;
  }

  removePendingRequest(error?.config);

  return errorRegistry.responseErrorHandler(error);
};
