import { render, staticRenderFns } from "./SlInfoItem.vue?vue&type=template&id=0bd3ccd9&scoped=true"
import script from "./SlInfoItem.vue?vue&type=script&lang=js"
export * from "./SlInfoItem.vue?vue&type=script&lang=js"
import style0 from "./SlInfoItem.vue?vue&type=style&index=0&id=0bd3ccd9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bd3ccd9",
  null
  
)

export default component.exports