import { formatFullTime, utcTimezoneOffsetTime } from '@/helpers/date/date';
import { i18n } from '@/plugins/vue-i18n.plugin';

export const getNoteTooltip = (note) => {
  if (!note) {
    return null;
  }

  return {
    theme: 'dark',
    content: getNoteTooltipTemplate(note),
    html: true
  };
};

export const getNoteTooltipTemplate = (data) => {
  if (!data) {
    return '';
  }

  const { body, author, editTime } = data;

  return `
  <div class="split-tooltip">
    <div class="split-tooltip__title">${author || i18n.tc('Import.Names.Dummy')}</div>
    <div class="split-tooltip__title">${formatFullTime(editTime)} UTC ${utcTimezoneOffsetTime}</div>
    <div class="split-tooltip--divider"></div>
    <div class="split-tooltip--message multiline-ellipsis">${body.replace(/\n/g, '<br/>')}</div>
  </div>`;
};

export const getNoteKey = (data) => {
  if (!data) {
    return null;
  }

  const { item, loc, chan } = data;

  return [item, loc, chan].join('_');
};
