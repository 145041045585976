export const MAX_FILES = 1;

export const matchKeys = {
  FILE: 'file',
  SHEET: 'sheet'
};

export const tabKeys = {
  SETTINGS: 'settings',
  TRANSACTIONS: 'transactions',
  ITEM_INFO: 'inventory',
  ORDERS_RECEIVE: 'arrivals',
  ORDERS_SHIP: 'backorders',
  SUBSTITUTIONS: 'substitutions',
  BOM: 'bom',
  PROMOTIONS: 'promotions',
  BATCH_EXPIRATIONS: 'expirations',
  INVENTORY_PARAMETERS: 'inventoryParameters',
  SUPPLIERS: 'suppliers',
  TRANSFER_REGIONS: 'transferRegions'
};

export const importTabKeys = [
  tabKeys.TRANSACTIONS,
  tabKeys.ITEM_INFO,
  tabKeys.ORDERS_RECEIVE,
  tabKeys.BOM,
  tabKeys.ORDERS_SHIP,
  tabKeys.SUBSTITUTIONS,
  tabKeys.PROMOTIONS,
  tabKeys.BATCH_EXPIRATIONS,
  tabKeys.INVENTORY_PARAMETERS,
  tabKeys.SUPPLIERS,
  tabKeys.TRANSFER_REGIONS
];

export const settingTabs = (ctx) => ([
  {
    label: ctx.$t('Web.Transactional.TabSettings'),
    value: tabKeys.SETTINGS,
    component: 'Settings'
  }
]);

export const importTabs = (ctx) => {
  return [
    {
      label: ctx.$t('OrderListDialog.Ui.tabMain:at'),
      value: tabKeys.TRANSACTIONS,
      component: 'Import'
    },
    {
      label: ctx.$t('OrderListDialog.Ui.tabInventory:at'),
      value: tabKeys.ITEM_INFO,
      component: 'Import'
    },
    {
      label: ctx.$t('OrderListDialog.Ui.tabInTransition:at'),
      value: tabKeys.ORDERS_RECEIVE,
      component: 'Import'
    },
    {
      label: ctx.$t('OrderListDialog.Ui.tabBackorders:at'),
      value: tabKeys.ORDERS_SHIP,
      component: 'Import'
    },
    {
      label: ctx.$t('OrderListDialog.Ui.tabSubstitutions:at'),
      value: tabKeys.SUBSTITUTIONS,
      component: 'Import'
    },
    {
      label: ctx.$t('OrderListDialog.Ui.tabBom:at'),
      value: tabKeys.BOM,
      component: 'Import'
    },
    {
      label: ctx.$t('OrderListDialog.Ui.tabPromotions:at'),
      value: tabKeys.PROMOTIONS,
      component: 'Import'
    },
    {
      label: ctx.$t('OrderListDialog.Ui.tabExpirations:at'),
      value: tabKeys.BATCH_EXPIRATIONS,
      component: 'Import'
    },
    {
      label: ctx.$t('OrderListDialog.Ui.tabInvParams:at'),
      value: tabKeys.INVENTORY_PARAMETERS,
      component: 'Import'
    },
    {
      label: ctx.$t('Web.Suppliers.PageTitle'),
      value: tabKeys.SUPPLIERS,
      component: 'Import'
    },
    {
      label: ctx.$t('Web.TransferRegions.PageTitle'),
      value: tabKeys.TRANSFER_REGIONS,
      component: 'Import'
    }
  ];
};

export const composeDateKeys = {
  NONE: 'None',
  YEAR_MONTH: 'YearMonth',
  YEAR_WEEK: 'YearWeek'
};

export const composeDateOptions = (ctx) => ([
  {
    label: ctx.$t('Import.ComposeDate.0'),
    value: composeDateKeys.NONE
  },
  {
    label: ctx.$t('Import.ComposeDate.YM'),
    value: composeDateKeys.YEAR_MONTH
  },
  {
    label: ctx.$t('Import.ComposeDate.YW'),
    value: composeDateKeys.YEAR_WEEK
  }
]);

export const csvDelimiterOptions = [
  {
    label: ',',
    value: ','
  },
  {
    label: ';',
    value: ';'
  },
  {
    label: 'space',
    value: ' '
  },
  {
    label: 'tab',
    value: '\t'
  }
];

export const sourceTypes = {
  URL: 'url',
  FILE: 'file',
  MICROSOFT_DRIVES: 'microsoftDrives'
};

export const sourceTypeOptions = (ctx) => ([
  {
    label: ctx.$t('Web.Transactional.Link'),
    value: sourceTypes.URL
  },
  {
    label: ctx.$t('Web.Transactional.MicrosoftDrives'),
    value: sourceTypes.MICROSOFT_DRIVES
  },
  {
    label: ctx.$t('Web.Spreadsheet.UploadLabel'),
    value: sourceTypes.FILE
  }
]);
