export const themeOptions = {
  adminPortal: {
    pages: {
      privacy: {
        content: {
          '.MuiChip-filledPrimary': {
            backgroundColor: '#EDFCF2'
          }
        }
      },
      users: {
        content: {
          '.MuiChip-filledPrimary': {
            backgroundColor: '#E9F0FF'
          }
        }
      },
      security: {
        content: {
          '.Mui-selected.adminportal-fe-root-11y29gy': {
            backgroundColor: '#EDFCF2'
          },
          '.Mui-selected.adminportal-fe-root-ggnxnf': {
            backgroundColor: '#FEF3F2'
          },
          '.MuiAlert-standardInfo': {
            backgroundColor: '#E9F0FF'
          },
          '.MuiChip-filledPrimary': {
            backgroundColor: '#EDFCF2'
          }
        }
      },
      audits: {
        content: {
          '.MuiChip-filledPrimary': {
            backgroundColor: '#E9F0FF'
          }
        }
      },
      apiTokens: {
        content: {
          '.MuiChip-filledPrimary': {
            backgroundColor: '#E9F0FF'
          }
        }
      }
    },
    selectTheme: {
      selectInput: {
        '.MuiChip-filledPrimary': {
          backgroundColor: '#E9F0FF'
        }
      }
    }
  }
};

export const metadata =  {
  navigation: {
    security: {
      visibility: 'byPermissions',
      permissions: ['viewAccountSecurity']
    }
  }
};