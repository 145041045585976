import Vue from 'vue';
import spreadsheetApi from '@/api/integrations/spreadsheet';
import connectorApi from '@/api/integrations/connector';
import TablePreviewAdapter from '@/adapters/response/connection/shared/TablePreview.adapter';
import {
  importTabKeys,
  tabKeys
} from '@/config/integrations/transform.config';
import {
  composeDateKeys,
  matchKeys
} from '@/config/integrations/spreadsheet.config';
import { fileFormats } from '@/config/utils/fileUpload.config';
import { integrationStatuses, integrationSteps, stepStatuses } from '@/config/integrations';
import { setColSlot } from '@/helpers/connection';
import cloneObject from '@/helpers/utils/cloneObject';
import { getParsedSteps } from '@/helpers/integration/stepsParser';
import { dealWith } from '@/api/helpers/errorRegistry';
import { fileToFormData } from '@/helpers/utils/fileUpload';
import { gzipFile } from '@/helpers/utils/gzip';

const types = {
  SET_FILES_SHEETS: 'SET_FILES_SHEETS',
  SET_WARNING: 'SET_WARNING',
  UPDATE_TAB_SETTINGS: 'UPDATE_TAB_SETTINGS',
  SET_MATCHED_SHEETS: 'SET_MATCHED_SHEETS',
  UPDATE_MATCHED_SHEETS: 'UPDATE_MATCHED_SHEETS',
  SET_TAB_SETTINGS: 'SET_TAB_SETTINGS',
  SET_MATCHED_SLOT: 'SET_MATCHED_SLOT',
  SET_SLOTS_INDEXES: 'SET_SLOTS_INDEXES',
  RESET_IS_TABS_LOADED: 'RESET_IS_TABS_LOADED',
  SET_INTEGRATION_SETTINGS: 'SET_INTEGRATION_SETTINGS',
  SET_AVAILABLE_SLOTS: 'SET_AVAILABLE_SLOTS',
  RESET_MATCHED_SHEETS: 'RESET_MATCHED_SHEETS',
  RESET_IMPORT_TAB: 'RESET_IMPORT_TAB',
  SET_TAB: 'SET_TAB',
  RESET_STATE: 'RESET_STATE'
};

const tabInitialState = () => ({
  importPreview: {},
  availableSlots: [],
  matchedSlots: {},
  slotsIndexes: {},
  isLoaded: false,
  isLoading: false
});

const tabsInitialState = () => {
  return importTabKeys.reduce((acc, tab) => {
    acc[tab] = tabInitialState();

    return acc;
  }, {});
};

const matchSheetsInitialState = () => {
  return importTabKeys.reduce((acc, key) => {
    acc[key] = {
      [matchKeys.FILE]: null,
      [matchKeys.SHEET]: null
    };

    return acc;
  }, {});
};

const initialState = () => ({
  files_sheets: {},
  warning: '',
  tab: tabKeys.SETTINGS,
  [tabKeys.SETTINGS]: {
    csvDelimiter: '',
    startFrom: 1,
    composeDate: composeDateKeys.NONE,
    headerRowsCount: 1,
    uniteLocations: false,
    zeroPriceTransactions: true,
    zeroQtyTransactions: false
  },
  match_sheets: matchSheetsInitialState(),
  ...tabsInitialState()
});

const state = initialState();

const getters = {
  files: (_, __, rootState) => rootState.integrations.settings.connection.files || [],
  isCsv: (state) => !!state[tabKeys.SETTINGS].csvDelimiter,
  isFirstFileCsv: (_, getters) => {
    const firstFileId = getters.files[0]?.id ?? getters.files[0]?.fileId ?? null;

    return getters.fileByFileIdMap[firstFileId]?.name.endsWith(fileFormats.csv);
  },
  dataByTab: (state) => (tab) => state[tab],
  requestParams: (state, getters) => (fileId) => {
    const file = getters.fileByFileIdMap[fileId];
    const csvDelimiter = state[tabKeys.SETTINGS].csvDelimiter;
    const params = {
      HeaderRowsCount: state[tabKeys.SETTINGS].headerRowsCount,
      composeDate: state[tabKeys.SETTINGS].composeDate
    };

    if (file && file.name.endsWith(fileFormats.csv)) {
      params.csvDelimiter = csvDelimiter;
    }

    return params;
  },
  isMultipleFiles: (_, getters) => getters.files.length > 1,
  fileByFileIdMap: (_, getters) => {
    return getters.files.reduce((acc, file) => {
      acc[file.id] = file;

      return acc;
    }, {});
  },
  isTabHasSlots: (state) => (tab) => Object.values(state[tab].matchedSlots).some(Boolean),
  hasMatchedSheets: (state) => {
    return importTabKeys.some(tab => state.match_sheets[tab][matchKeys.SHEET] !== null);
  }
};

const mutations = {
  [types.SET_FILES_SHEETS](state, { fileId, sheets }) {
    Vue.set(state.files_sheets, fileId, sheets);
  },
  [types.SET_WARNING](state, value) {
    state.warning = value;
  },
  [types.SET_TAB](state, value) {
    state.tab = value;
  },
  [types.SET_TAB_SETTINGS](state, { tab, value }) {
    Object.assign(state[tab], value);
  },
  [types.UPDATE_TAB_SETTINGS](state, { tab, key, value }) {
    Vue.set(state[tab], key, value);
  },
  [types.SET_MATCHED_SHEETS](state, value) {
    Object.assign(state.match_sheets, value);
  },
  [types.UPDATE_MATCHED_SHEETS](state, { key, field, value }) {
    Vue.set(state.match_sheets[key], field, value);
  },
  [types.SET_MATCHED_SLOT](state, { tab, index, value }) {
    Vue.set(state[tab].matchedSlots, index, value);
  },
  [types.SET_SLOTS_INDEXES](state, { tab, slotKey, indexesList }) {
    Vue.set(state[tab].slotsIndexes, slotKey, indexesList);
  },
  [types.RESET_IS_TABS_LOADED](state) {
    importTabKeys.forEach(tab => {
      Vue.set(state[tab], 'isLoaded', false);
    });
  },
  [types.SET_INTEGRATION_SETTINGS](state, value) {
    Object.keys(value).forEach(key => {
      // skip already matched files/sheets
      if (key === 'match_sheets') {
        const isMatched = importTabKeys.some(tab => {
          return state[key][tab][matchKeys.FILE] !== null;
        });

        return !isMatched && Object.assign(state[key], value[key]);
      }

      if (state[key]) {
        Object.assign(state[key], value[key]);
      }
    });
  },
  [types.SET_AVAILABLE_SLOTS](state, value) {
    importTabKeys.forEach(tab => {
      Vue.set(state[tab], 'availableSlots', value[tab]);
    });
  },
  [types.RESET_MATCHED_SHEETS](state, value) {
    state.match_sheets = value;
  },
  [types.RESET_IMPORT_TAB](state, tab) {
    // ignore loaded slots
    const availableSlots = cloneObject(state[tab].availableSlots);

    state[tab] = {
      ...tabInitialState(),
      availableSlots
    };
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  resetIsTabsLoaded({ commit }) {
    commit(types.RESET_IS_TABS_LOADED);
  },
  setTab({ commit }, value) {
    commit(types.SET_TAB, value);
  },
  setSettings({ commit }, payload) {
    commit(types.SET_INTEGRATION_SETTINGS, payload);
  },
  updateTabSettings({ commit }, { isEdited, ...payload }) {
    commit(types.UPDATE_TAB_SETTINGS, payload);

    if (isEdited) {
      this.dispatch('integrations/setIsEdited', true);
    }
  },
  updateMatchedSheet({ getters, commit, dispatch }, payload) {
    commit(types.SET_TAB, tabKeys.SETTINGS);

    const { key, field, value } = payload;

    if (field === matchKeys.FILE) {
      commit(types.UPDATE_MATCHED_SHEETS, {
        key,
        field: matchKeys.SHEET,
        value: null
      });
    }

    if (!getters.isMultipleFiles) {
      commit(types.UPDATE_MATCHED_SHEETS, {
        key,
        field: matchKeys.FILE,
        value: getters.files[0]?.id
      });

      dispatch('resetSelectedField', { field, value });
    }

    const file = getters.fileByFileIdMap[value];
    const isCsv = file && file.type === fileFormats.csv;

    if (isCsv) {
      dispatch('resetMatchedSheets', file.id);

      commit(types.UPDATE_MATCHED_SHEETS, {
        key,
        field: matchKeys.SHEET,
        value: 0
      });
    }

    commit(types.UPDATE_MATCHED_SHEETS, payload);
    commit(types.RESET_IMPORT_TAB, key);
  },
  matchDefaultSheet({ getters, commit }) {
    if (!getters.isMultipleFiles && getters.isCsv) {
      commit(types.UPDATE_MATCHED_SHEETS, {
        key: tabKeys.TRANSACTIONS,
        field: matchKeys.FILE,
        value: getters.files[0]?.id
      });

      commit(types.UPDATE_MATCHED_SHEETS, {
        key: tabKeys.TRANSACTIONS,
        field: matchKeys.SHEET,
        value: 0
      });
    }
  },
  resetSelectedField({ state, commit }, { field, value }) {
    importTabKeys.forEach(tabKey => {
      if (state.match_sheets[tabKey][field] === value) {
        commit(types.UPDATE_MATCHED_SHEETS, {
          key: tabKey,
          field,
          value: null
        });
        commit(types.RESET_IMPORT_TAB, tabKey);
      }
    });
  },
  resetMatchedSheets({ state, commit }, fileId) {
    const file = getters.files.find(file => fileId === file.id);

    if (file) {
      const newMatched = importTabKeys.reduce((acc, key) => {
        const tab = state.match_sheets[key];
        const isFileMatched = tab[matchKeys.FILE] === file.id;

        if (isFileMatched) {
          commit(types.RESET_IMPORT_TAB, key);
        }

        acc[key] = {
          [matchKeys.FILE]: isFileMatched ? null : tab[matchKeys.FILE],
          [matchKeys.SHEET]: isFileMatched ? null : tab[matchKeys.SHEET]
        };

        return acc;
      }, {});

      commit(types.RESET_MATCHED_SHEETS, newMatched);
    }
  },
  setColSlot(context, payload) {
    this.dispatch('integrations/setIsEdited', true);

    return setColSlot(types, context, payload);
  },
  setMatchedSheets({ commit, getters }, sheets) {
    if (!sheets?.length || !getters.files.length) {
      return;
    }

    const matchedSheets = sheets.reduce((acc, { tab, index }) => {
      if (tab) {
        acc[tab] = {
          [matchKeys.FILE]: getters.files[0]?.id,
          [matchKeys.SHEET]: index
        };
      }

      return acc;
    }, {});

    commit(types.SET_MATCHED_SHEETS, matchedSheets);
  },
  async fetchSheetList({ state, rootState, commit, getters, dispatch }, fileId) {
    try {
      if (fileId === null || state.files_sheets[fileId]?.length) {
        return;
      }

      const { id } = rootState.integrations.active_integration;

      const response = await spreadsheetApi.getSheetList({ id });
      let sheets = response?.data;

      if (!sheets) {
        return;
      }

      // kostyl until multiple sheets epic
      if (getters.isCsv) {
        sheets = sheets.map(sheet => ({...sheet, name: getters.files[0]?.name}));
      }

      commit(types.SET_FILES_SHEETS, {
        fileId,
        sheets
      });

      if (!getters.isMultipleFiles && !getters.hasMatchedSheets) {
        await dispatch('setMatchedSheets', sheets);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchSheetList' });
    }
  },
  async fetchAvailableSlots({ state, commit }) {
    try {
      const slotsExist = importTabKeys.some(tab => {
        return state[tab].availableSlots.length;
      });

      if (slotsExist) {
        return;
      }

      const response = await connectorApi.getSlots();
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(types.SET_AVAILABLE_SLOTS, data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchAvailableSlots' });
    }
  },
  async fetchTablePreview({ state, getters, rootState }, tab) {
    try {
      const { id } = rootState.integrations.active_integration;
      const match = state.match_sheets[tab];

      if (match[matchKeys.SHEET] === null) {
        return;
      }

      return spreadsheetApi.getSheetPreview({
        sheetIndex: match[matchKeys.SHEET],
        id,
        tab,
        autodetection: !getters.isTabHasSlots(tab),
        ...getters.requestParams(match[matchKeys.FILE])
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTablePreview' });
    }
  },
  setTablePreviewResult({ commit, getters }, { data, tab }) {
    const { matchedSlots, slotsIndexes, ...table } = TablePreviewAdapter(data);

    commit(types.SET_TAB_SETTINGS, {
      tab,
      value: {
        importPreview: table,
        ...(!getters.isTabHasSlots(tab) && { matchedSlots, slotsIndexes })
      }
    });
  },
  async fetchWarnings({ state, commit, getters, rootState }) {
    try {
      const { id } = rootState.integrations.active_integration;
      const match = state.match_sheets[tabKeys.TRANSACTIONS];

      if (!match) {
        return;
      }

      const response = await spreadsheetApi.getWarnings({
        ...getters.requestParams(match[matchKeys.FILE]),
        id,
        sheetIndex: match[matchKeys.SHEET]
      });
      const warning = response?.data?.warning ?? '';

      commit(types.SET_WARNING, warning);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchWarnings' });
    }
  },
  async fetchBasicSettings({ rootState, commit }, { isCache = false } = {}) {
    try {
      const { id } = rootState.integrations.active_integration;

      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => spreadsheetApi.getBasicSettings({ id })
      });

      if (!operationData || isCache) {
        return;
      }

      commit(types.SET_INTEGRATION_SETTINGS, {
        [tabKeys.SETTINGS]: operationData
      });
    } catch (e) {
      dealWith({
        '*': {
          text: (e) => e.message
        }
      })(e);

      this.dispatch('user/logout', { e, from: 'fetchBasicSettings' });
    }
  },
  async cacheMicrosoftDrivesFiles({ rootState }) {
    try {
      const { id } = rootState.integrations.active_integration;

      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => spreadsheetApi.cacheMicrosoftDrivesFiles({ id })
      });

      return operationData?.successful;
    } catch (e) {
      dealWith({
        '*': {
          text: (e) => e.message
        }
      })(e);

      this.dispatch('user/logout', { e, from: 'cacheMicrosoftDrivesFiles' });
    }
  },
  async searchMicrosoftDrivesFiles({ rootState }, query = '') {
    try {
      const { id } = rootState.integrations.active_integration;

      const response = await spreadsheetApi.searchMicrosoftDrivesFiles({
        id,
        query,
        top: 20,
        skip: 0
      });

      return response?.data?.data || [];
    } catch (e) {
      dealWith({
        '*': {
          text: (e) => e.message
        }
      })(e);

      this.dispatch('user/logout', { e, from: 'searchMicrosoftDrivesFiles' });
    }
  },
  async uploadMicrosoftDrivesFile({ rootState }) {
    try {
      const { id } = rootState.integrations.active_integration;

      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => spreadsheetApi.uploadMicrosoftDrivesFile({ id })
      });

      return operationData?.successful;
    } catch (e) {
      dealWith({
        '*': {
          text: (e) => e.message
        }
      })(e);

      this.dispatch('user/logout', { e, from: 'uploadMicrosoftDrivesFile' });
    }
  },
  async uploadSpreadsheetFile({ rootState }, { integrationId, value, reqConfig }) {
    const { id } = rootState.integrations.active_integration;

    return spreadsheetApi.uploadSpreadsheetFile(
      fileToFormData(await gzipFile(value), 'spreadsheet'),
      {
        ...reqConfig,
        params: {
          id: integrationId || id
        }
      }
    );
  },
  downloadSpreadsheetFile({ rootState }, integrationId) {
    const { id } = rootState.integrations.active_integration;

    return spreadsheetApi.downloadSpreadsheetFile({
      id: integrationId || id
    });
  },
  resetWarnings({ commit }) {
    commit(types.SET_WARNING, '');
  },
  saveSettings({ state, getters }) {
    return this.dispatch('integrations/updateIntegrationSettings', {
      ...state,
      files: getters.files
    });
  },
  stepsParser({ rootState }, { steps, status }) {
    const isFilesExist = rootState.integrations.settings.connection.files?.length;
    const isUninitialized = status === integrationStatuses.UNINITIALIZED;
    const isFailed = status === integrationStatuses.FAILED_TO_IMPORT;
    const activeIndex = Object.keys(steps).findIndex(key => {
      return key === (isUninitialized ? integrationSteps.UPLOAD_FILE : integrationSteps.PULL_DATA);
    });

    const getStatus = (index) => {
      const condition = isFailed
        ? index < activeIndex
        : index <= activeIndex;

      return condition
        ? stepStatuses.COMPLETED
        : stepStatuses.INCOMPLETE;
    };

    if (!isFilesExist) {
      return getParsedSteps(steps, 0);
    }

    return getParsedSteps(steps, activeIndex, getStatus);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
