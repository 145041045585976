import axios from '@/plugins/axios';

export default {
  getTransferRegions(params) {
    return axios.get('/report/transfer_regions', { params });
  },
  exportTransferRegionsToXlsx() {
    return axios.get('/report/transfer_regions/xlsx', {
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  importTransferRegionsFromXlsx(body) {
    return axios.post('/report/transfer_regions/xlsx', body, {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
  },
  createTransferRegion(body) {
    return axios.post('/report/transfer_regions/create', body);
  },
  deleteTransferRegions() {
    return axios.delete('/report/transfer_regions/delete');
  },
  updateTransferRegion(params, body) {
    return axios.put('/report/transfer_regions/overrides', body, { params });
  },
  saveTransferRegions() {
    return axios.post('/report/transfer_regions/overrides');
  },
  discardTransferRegions() {
    return axios.delete('/report/transfer_regions/overrides');
  }
};