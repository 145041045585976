import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '../../public/locale/en.json';
import { availableLocales, slavicLocales } from '@/config/plugins/vue-i18n.config';
import logger from '@/api/logger';

const defaultLocaleRule = (choice, choicesLength) => {
  // "1st | 2nd | 3rd | 4th | ''(empty line added for separation from slavic locale)" start from 0 for week num
  if (choicesLength === 5) {
    return choice - 1;
  }

  if (choice === 1) {
    return 0;
  }

  // "Monday | Tuesday | Wednesday | Thursday | Friday | Saturday | Sunday" also starts from 0
  if (choicesLength > 5) {
    return choice;
  }

  return 1;
};
const slavicLocaleRule = (choice, choicesLength) => {
  // "1st | 2nd | 3rd | 4th | ''(empty line added for separation from slavic locale)" start from 0 for week num
  if (choicesLength === 5) {
    return choice - 1;
  }

  // "Monday | Tuesday | Wednesday | Thursday | Friday | Saturday | Sunday" also starts from 0
  if (choicesLength > 5) {
    return choice;
  }

  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (choicesLength < 4) {
    return (!teen && endsWithOne) ? 1 : 2;
  }

  if (!teen && endsWithOne) {
    return 1;
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return (choicesLength < 4) ? 2 : 3;
};
const getPluralizationRules = (allLocales, slavicLocales) => {
  return allLocales.reduce((rules, locale) => {
    if (slavicLocales.includes(locale)) {
      rules[locale] = slavicLocaleRule;

      return rules;
    }

    rules[locale] = defaultLocaleRule;

    return rules;
  }, {});
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: availableLocales[0],
  messages: {
    en
  },
  pluralizationRules: getPluralizationRules(availableLocales, slavicLocales),
  silentTranslationWarn: process.env.VUE_APP_MODE === 'production',
  missing: () => ('')
});

export const loadLanguageAsync = async(lang = availableLocales[0]) => {
  try {
    i18n.locale = lang;

    // used fetch instead of dynamic import to avoid import all locales
    const response = await fetch(`/locale/${lang}.json`);
    const messages = await response.json();

    i18n.setLocaleMessage(lang, messages);
  } catch (e) {
    logger.formatAndWriteError({
      e,
      from: 'loadLanguageAsync'
    });
    console.error('Load language error:', e);
  }
};
