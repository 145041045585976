export const operationStatuses = {
  NONE: 'none',
  QUEUED: 'queued',
  PAUSED: 'paused',
  IN_PROGRESS: 'in_progress',
  FAILED: 'failed',
  FINISHED: 'finished'
};

export const activeOperationStatuses = [
  operationStatuses.QUEUED,
  operationStatuses.PAUSED,
  operationStatuses.IN_PROGRESS
];

export const operationInfoTypes = {
  COMMAND_RESULT: 'commandResult',
  NEED_OAUTH: 'needOAuth',
  READ_RESULT: 'readResult',
  AWAIT_FOR_ACTION: 'awaitForAction'
};

export const updateFgsFlags = {
  IMPORT_META_INFO: 'import_meta_info',
  OPEN_PROJECT_META_INFO: 'open_project_meta_info'
};

export const operationTypes = {
  GLOBAL: 'global',
  PROJECT: 'project'
};