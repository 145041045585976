import { toArray } from '@/helpers/utils/toArray';
import { multipleSlots, mutuallyExclusiveSlots } from '@/config/connection';

export const setColSlot = (types, { state, commit }, { tab, value, index }) => {
  const removeSlot = (slotKey, colIndex) => {
    commit(types.SET_SLOTS_INDEXES, {
      tab,
      slotKey,
      indexesList: state[tab].slotsIndexes[slotKey]?.filter(i => +i !== +colIndex) || []
    });

    commit(types.SET_MATCHED_SLOT, {
      tab,
      index: colIndex,
      value: ''
    });
  };
  const addSlot = () => {
    commit(types.SET_SLOTS_INDEXES, {
      tab,
      slotKey: value.colMeaning,
      indexesList: [...toArray(state[tab].slotsIndexes[value.colMeaning]), index]
    });

    return commit(types.SET_MATCHED_SLOT, {
      tab,
      index,
      value: value.colMeaning
    });
  };

  // if the value is empty, delete the current slot
  const currentSlot = state[tab].matchedSlots[index];

  if (!value) {
    return removeSlot(currentSlot, index);
  }

  // if the current slot is already taken, delete the current slot
  if (currentSlot) {
    removeSlot(currentSlot, index);
  }

  // add multiple slot
  //second condition used in connector 2.0
  if (value._isMultiple === 'true' || value.isMultiple) {
    return addSlot();
  }

  // if the slot is already mapped, delete the mapped slot
  const colSlotValues = Object.entries(state[tab].matchedSlots);
  const mapped = colSlotValues.find(pair => pair[1] === value.colMeaning);

  if (mapped) {
    removeSlot(mapped[1], mapped[0]);
  }

  // if the slot is mutually exclusive, delete the exclusive slot
  const exlusiveSlot = mutuallyExclusiveSlots[value.colMeaning];

  if (exlusiveSlot) {
    const exlusive = colSlotValues.find(pair => pair[1] === exlusiveSlot);

    if (exlusive) {
      removeSlot(exlusive[1], exlusive[0]);
    }
  }

  addSlot();
};

export const getColumnSlotOptions = (tabData, colIndex) => {
  let hasMultiple = false;
  // set multiple slot count per column
  const multipleSlotCount = multipleSlots.reduce((acc, slotKey) => {
    const indexes = tabData.slotsIndexes[slotKey];

    // add count if multiple slot already was selected
    if (indexes?.length > 0) {
      const hasIndex = indexes.includes(colIndex);
      let slotCount = '';

      // for other columns set future selecting count
      if (!hasIndex) {
        slotCount = indexes.length + 1;
      }

      // for already selected multiple slot set its own order count
      if (indexes.length > 1 && hasIndex) {
        slotCount = indexes.findIndex(i => i === colIndex) + 1;
      }

      acc[slotKey] = slotCount;

      hasMultiple = true;
    }

    return acc;
  }, {});

  if (!hasMultiple) {
    return tabData.availableSlots;
  }

  // if multiple slot/slots already selected add its counts
  return tabData.availableSlots.map(option => {
    const slotCount = multipleSlotCount[option.colMeaning];

    if (slotCount > 1) {
      return {
        ...option,
        name: `${option.name} ${slotCount}`
      };
    }

    return option;
  });
};
