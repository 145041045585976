import { i18n } from '@/plugins/vue-i18n.plugin';

export const creationTypes = {
  IMPORT: 'Import',
  CUSTOM: 'Custom'
};

export const creationTypeLabels = {
  [creationTypes.IMPORT]: i18n.t('Main.CreationType.Import'),
  [creationTypes.CUSTOM]: i18n.t('Main.CreationType.Custom')
};
