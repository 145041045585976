import SpreadsheetAdapter from '@/adapters/response/integrations/connection/Spreadsheet.adapter';
import DatabaseRelatedAdapter from '@/adapters/response/integrations/connection/DatabaseRelated.adapter';
import SAPAdapter from '@/adapters/response/integrations/connection/SAP.adapter';
import DynamicsNavAdapter from '@/adapters/response/integrations/connection/DynamicsNav.adapter';
import DynamicsGpAdapter from '@/adapters/response/integrations/connection/DynamicsGp.adapter';
import FishbowlAdapter from '@/adapters/response/integrations/connection/Fishbowl.adapter';
import { integrationTypes } from '@/config/integrations';

export default function(settings, integration) {
  const adapterByType = {
    [integrationTypes.SPREADSHEET]: SpreadsheetAdapter,
    [integrationTypes.DATABASE]: DatabaseRelatedAdapter,
    [integrationTypes.SAP]: SAPAdapter,
    [integrationTypes.SAP_API]: SAPAdapter,
    [integrationTypes.DYNAMICS_NAV]: DynamicsNavAdapter,
    [integrationTypes.DYNAMICS_GP]: DynamicsGpAdapter,
    [integrationTypes.FISHBOWL]: FishbowlAdapter
  };

  const adapter = adapterByType[integration.type];

  return adapter ? adapter(settings, integration) : settings;
}
