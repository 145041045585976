import Vue from 'vue';
import store from '../store';
import VueRouter from 'vue-router';
import { routeNames } from '@/config/router/router.config';
import { toArray } from '@/helpers/utils/toArray';
import { checkEqualObject } from '@/helpers/utils/checkEqualObject';
import { editConnectorRoutes, newConnectorRoutes } from '@/router/connectorRoutes';
import { integrationsRoutes } from '@/router/integrationsRoutes';
import reportRoutes from '@/router/reportRoutes';
import projectRoutes from '@/router/projectRoutes';
import access from '@/router/middleware/access.middleware';
import auth from '@/router/middleware/auth.middleware';
import initialization from '@/router/middleware/initialization.middleware';
import middlewarePipeline from '@/router/middleware/middlewarePipeline';
import system from '@/router/middleware/system.middleware';
import admin from '@/router/middleware/admin.middleware';

Vue.use(VueRouter);

const defaultMiddleware = [auth, initialization];
const requestsMiddleware = [access];

const baseRoutes = [
  {
    path: '/p/',
    name: null,
    component: () => import('../views/Main.vue'),
    children: [
      {
        path: ':projectId/demand/:id?',
        name: routeNames.DEMAND,
        component: () => import(/* webpackPrefetch: true */ '../views/Demand.vue'),
        meta: {
          middleware: requestsMiddleware
        }
      },
      {
        path: ':projectId/dashboards/:dashboardId?',
        name: routeNames.DASHBOARD,
        component: () => import(/* webpackPrefetch: true */ '../views/Dashboard.vue'),
        meta: {
          middleware: requestsMiddleware
        }
      },
      {
        path: ':projectId/changes',
        name: routeNames.CHANGES,
        component: () => import(/* webpackPrefetch: true */ '../views/Changes.vue'),
        meta: {
          middleware: requestsMiddleware
        }
      },
      {
        path: ':projectId/users/',
        component: () => import(/* webpackMode: "lazy" */ '../views/Users.vue'),
        meta: {
          middleware: requestsMiddleware
        },
        children: [
          {
            path: '',
            name: routeNames.USERS,
            component: () => import(/* webpackMode: "lazy" */ '../components/User/Tabs/UsersTab.vue'),
            meta: {
              tab: routeNames.USERS
            }
          },
          {
            path: 'roles',
            name: routeNames.ROLES,
            component: () => import(/* webpackMode: "lazy" */ '../components/User/Tabs/RolesTab/RolesList.vue'),
            meta: {
              tab: routeNames.ROLES
            }
          },
          {
            path: 'roles/edit/:roleId',
            name: routeNames.EDIT_ROLE,
            component: () => import(/* webpackMode: "lazy" */ '../components/User/Tabs/RolesTab/EditRole.vue'),
            meta: {
              tab: routeNames.ROLES
            }
          },
          {
            path: 'roles/create',
            name: routeNames.CREATE_ROLE,
            component: () => import(/* webpackMode: "lazy" */ '../components/User/Tabs/RolesTab/EditRole.vue'),
            meta: {
              tab: routeNames.ROLES
            }
          },
          {
            path: 'catalogs',
            name: routeNames.CATALOGS,
            component: () => import(/* webpackMode: "lazy" */ '../components/User/Tabs/CatalogsTab/CatalogsList.vue'),
            meta: {
              tab: routeNames.CATALOGS
            }
          },
          {
            path: 'catalogs/edit/:catalogId',
            name: routeNames.EDIT_CATALOG,
            component: () => import(/* webpackMode: "lazy" */ '../components/User/Tabs/CatalogsTab/EditCatalogs.vue'),
            meta: {
              tab: routeNames.CATALOGS
            }
          },
          {
            path: 'catalogs/create',
            name: routeNames.CREATE_CATALOG,
            component: () => import(/* webpackMode: "lazy" */ '../components/User/Tabs/CatalogsTab/EditCatalogs.vue'),
            meta: {
              tab: routeNames.CATALOGS
            }
          }
        ]
      },
      {
        path: 'projects/',
        name: null,
        component: () => import('../views/ProjectsPage.vue'),
        children: [
          {
            path: 'list',
            name: routeNames.PROJECT_LIST,
            component: () => import(/* webpackPrefetch: true */ '../components/Project/ProjectList.vue')
          },
          {
            path: 'archive',
            name: routeNames.ARCHIVE,
            component: () => import(/* webpackMode: "lazy" */ '../components/Project/ArchiveList.vue')
          },
          {
            path: 'examples',
            name: routeNames.EXAMPLES,
            component: () => import(/* webpackMode: "lazy" */ '../components/Project/ExampleList.vue')
          }
        ]
      },
      {
        path: 'subscription',
        name: routeNames.SUBSCRIPTION,
        component: () => import(/* webpackMode: "lazy" */ '../views/SubscriptionPage.vue'),
        meta: {
          middleware: admin
        }
      },
      ...reportRoutes.map(route => ({
        ...route,
        meta: {
          middleware: [
            ...requestsMiddleware,
            ...toArray(route?.meta?.middleware)
          ]
        }
      })),
      ...projectRoutes.map(route => ({
        ...route,
        meta: {
          middleware: [
            ...requestsMiddleware,
            ...toArray(route?.meta?.middleware)
          ]
        }
      })),
      ...editConnectorRoutes.map(route => ({
        ...route,
        meta: {
          middleware: requestsMiddleware
        }
      })),
      ...newConnectorRoutes,
      ...integrationsRoutes
    ]
  },
  // {
  //   path: '/tutorials',
  //   name: routeNames.TUTORIALS,
  //   component: () => import('../components/Tutorials/Index.vue')
  // },
  {
    path: '/activate-streamline',
    name: routeNames.ACTIVATION,
    meta: {
      middleware: [system]
    },
    component: () => import(/* webpackMode: "lazy" */ '../views/SystemPages/ActivateStreamline.vue')
  },
  {
    path: '/get-started',
    name: routeNames.GET_STARTED,
    meta: {
      middleware: [system]
    },
    component: () => import(/* webpackMode: "lazy" */ '../views/SystemPages/GetStarted.vue')
  },
  {
    path: '/no-access',
    name: routeNames.NO_ACCESS,
    meta: {
      middleware: [system]
    },
    component: () => import(/* webpackMode: "lazy" */ '../views/SystemPages/NoAccess.vue')
  },
  {
    path: '/server-down',
    name: routeNames.SERVER_DOWN,
    meta: {
      middleware: [system]
    },
    component: () => import(/* webpackMode: "lazy" */ '../views/SystemPages/ServerDown.vue')
  },
  {
    path: '/no-connection',
    name: routeNames.NO_CONNECTION,
    meta: {
      middleware: [system]
    },
    component: () => import(/* webpackMode: "lazy" */ '../views/SystemPages/NoConnection.vue')
  }
];

if (process.env.VUE_APP_MODE !== 'production') {
  baseRoutes.unshift({
    path: '/uikit',
    name: 'UI',
    component: () => import(/* webpackMode: "lazy" */'../views/UI/Index.vue')
  });
}

// avoid NavigationDuplicated error globally
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(originalLocation) {
  let location = { ...originalLocation };

  if (store.state.workspace.id) {
    location = {
      ...location,
      params: {
        ...location.params,
        workspaceId: store.state.workspace.id
      }
    };
  }

  const fromLocation = {
    name: router.currentRoute.name,
    params: router.currentRoute.params,
    query: router.currentRoute.query
  };
  const toLocation = {
    name: location.name,
    params: location.params || {},
    query: location.query || {}
  };

  if (
    checkEqualObject(fromLocation, toLocation)
    || (Object.keys(location).length === 1 && checkEqualObject(location, { 0: '/' }))
  ) {
    return;
  }

  return originalPush.call(this, location);
};

/**
 * SINGLE MODE
 * means that web server is launched for a single customer
 * and is not a part of 'free version'-based architecture with /w/<container-id> path prefix
 */
function createRouter(isSingleMode) {
  const routes = isSingleMode
    ? baseRoutes
    : baseRoutes.map(route => ({
      ...route,
      path: `/w/:workspaceId${route.path}`
    })).concat({
      path: '/w/:workspaceId',
      name: null,
      component: () => import('../views/Main.vue')
    });

  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  });
}

const router = createRouter();

router.beforeEach(async(to, from, next) => {
  if (window.location.protocol === 'http:' && window.location.port === '') {
    window.location.replace(`https://${window.location.host + window.location.pathname}`);
  }

  store.dispatch('modals/resetState');

  if (to.params.workspaceId) {
    await store.dispatch('workspace/setWorkspaceId', to.params.workspaceId);
  } else if (store.state.workspace.id) {
    return next({
      name: to.name,
      params: {
        ...to.params,
        workspaceId: store.state.workspace.id
      }
    });
  } else {
    if (!store.state.workspace.isSingleMode) {
      resetRouter(true);
    }

    store.dispatch('workspace/setSingleMode', true);
  }

  const middleware = defaultMiddleware.concat(toArray(to.meta.middleware));

  if (!middleware.length) {
    return next();
  }

  const context = {
    to,
    from,
    next,
    router,
    store
  };

  return middleware[0]({
    ...context,
    nextMiddleware: middlewarePipeline(context, middleware, 1)
  });
});

export function resetRouter(...args) {
  const newRouter = createRouter(...args);

  router.matcher = newRouter.matcher;
}

export default router;
