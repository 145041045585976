import axios from '@/plugins/axios';

export default {
  getChanges(params) {
    return axios.get('/changes', {
      params
    });
  },
  exportChanges() {
    return axios.get('/changes/xlsx', {
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  }
};