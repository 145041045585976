import { mapActions } from 'vuex';

export const modal = {
  methods: {
    ...mapActions('modals', [
      'toggleModal'
    ]),
    onCreated(props) {
      Object.keys(props).forEach(key => {
        if (props[key] !== undefined) {
          this[key] = props[key];
        }
      });
    },
    showModal(id, data) {
      this.$root.$emit('pass-data', { id, ...data });

      this.$root.$emit(`show:${id}`);

      setTimeout(async() => {
        await this.toggleModal({ id, value: true });
        this.$root.$emit(`shown:${id}`);
      }, 100);
    },
    async hideModal(id) {
      this.$root.$emit(`hide:${id}`);
      await this.toggleModal({ id, value: false });
      this.$root.$emit(`hidden:${id}`);
    }
  }
};
