<template>
  <div
    v-close-popper
    tabindex="0"
    class="sl-search-path-option body-1 grey-80"
    @click="$emit('select')"
    @keydown.enter="$emit('select')"
  >
    <div class="sl-search-path__main">
      <slot name="icon" />
      <div class="sl-search-path__content">
        <div class="sl-search-path__label">
          {{ targetNodeLabel }}
        </div>
        <SlNodePath
          v-if="path.length > 1"
          :path="path"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SlSearchPathOption',
  props: {
    path: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    targetNodeLabel() {
      return this.path.at(-1) || '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-dropdown/sl-search-path-option.scss";
</style>