export const suppliersKeys = {
  SUPPLIERS: 'suppliers',
  HOLIDAYS: 'suppliers_holidays'
};

export const suppliersTabLabelByKey = {
  [suppliersKeys.SUPPLIERS]: 'Web.Suppliers.PageTitle',
  [suppliersKeys.HOLIDAYS]: 'Web.Suppliers.Holidays'
};

export const suppliersTabsConfig = (ctx) => ([
  {
    label: ctx.$t(suppliersTabLabelByKey[suppliersKeys.SUPPLIERS]),
    value: suppliersKeys.SUPPLIERS
  },
  {
    label: ctx.$t(suppliersTabLabelByKey[suppliersKeys.HOLIDAYS]),
    value: suppliersKeys.HOLIDAYS
  }
]);
