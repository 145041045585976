<template>
  <SlInput
    ref="searchInput"
    v-model="vModel"
    type="text"
    :placeholder="placeholder || $t('Main.SearchStub')"
    :class="['sl-search-input', {
      'sl-search-input--expandable': expandable,
      'sl-search-input--focused': expandable && isFocused
    }]"
    v-bind="$attrs"
    @keydown="autoSubmitCallback"
    @keydown.enter.stop="submit"
    @focus="handleFocus"
    @blur="handleBlur"
  >
    <template #prepend>
      <SlLoader
        v-if="loading"
        key="loader"
        size="xxs"
        :text="false"
        class="sl-search-input__prepend"
      />
      <icon
        v-else
        key="icon"
        data="@icons/search.svg"
        class="fill-off size-16 sl-search-input__prepend"
      />
    </template>
    <template
      v-if="vModel"
      #append
    >
      <SlButton
        variant="tertiary"
        color="grey"
        size="2xs"
        icon
        class="sl-search-input__append size-16"
        @mousedown.stop="clearSearch"
      >
        <icon
          data="@icons/close.svg"
          class="fill-off size-16"
        />
      </SlButton>
    </template>
  </SlInput>
</template>

<script>
import { keyCodes } from '@/config/utils/statusCodes.config';
import { customDebounce } from '@/helpers/shared/listeners';

export default {
  name: 'SlSearchInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    autoSubmit: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    expandable: Boolean
  },
  data() {
    return {
      isFocused: false
    };
  },
  computed: {
    vModel: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    autoSubmitCallback() {
      if (!this.autoSubmit) {
        return () => {};
      }

      return this.autoSubmitHandler;
    }
  },
  methods: {
    handleFocus(e) {
      this.isFocused = true;

      this.$emit('focus', e);
    },
    handleBlur(e) {
      this.isFocused = false;

      this.$emit('blur', e);
    },
    submit() {
      this.$emit('submit', this.$refs.searchInput?.$refs.input.value);
    },
    autoSubmitHandler: customDebounce(function(e) {
      if (e.keyCode !== keyCodes.enter) {
        this.submit();
      }
    }, 500),
    clearSearch() {
      this.$refs.searchInput.$refs.input.value = '';
      this.vModel = '';
      this.submit();
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-search-input';
</style>
