import { AIAssistantAxios } from '@/plugins/axios/AIAssistant';

export default {
  getUserChat(userId) {
    return AIAssistantAxios.get(`/user/chat/${userId}`);
  },
  getChatMessages(chatId) {
    return AIAssistantAxios.get(`/chat/${chatId}`);
  },
  deleteChat(chatId) {
    return AIAssistantAxios.delete(`/chat/${chatId}`);
  },
  putChatMessage(chatId, message) {
    return AIAssistantAxios.put(`/chat/${chatId}`, {
      message
    });
  },
  createChat(userId) {
    return AIAssistantAxios.post(`/chat/${userId}`);
  }
};
