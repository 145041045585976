export const removalSubstitutionRulesKeys = {
  ITEMS: 'items',
  CHANNELS: 'channels'
};

export const substitutionMagicValues = {
  EMPTY: 'empty',
  NO_DATE: '0000-00-00'
};

export const substitutionLabelCodeByType = {
  [removalSubstitutionRulesKeys.ITEMS]: 'Substitutions.Ui.itemSubs:at',
  [removalSubstitutionRulesKeys.CHANNELS]: 'Substitutions.Ui.channelSubs:at'
};

export const removalSubstitutionRulesConfig = (ctx) => ([
  {
    label: ctx.$t('Substitutions.Ui.itemSubs:at'),
    value: removalSubstitutionRulesKeys.ITEMS
  },
  {
    label: ctx.$t('Substitutions.Ui.channelSubs:at'),
    value: removalSubstitutionRulesKeys.CHANNELS
  }
]);

