var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sl-context-menu",on:{"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.vcoConfig),expression:"vcoConfig"}],class:['sl-context-menu__list', {
      'sl-context-menu--active': _vm.isActive
    }],style:({
      maxWidth: (_vm.maxWidth + "px")
    }),attrs:{"id":_vm.id}},[_vm._l((_vm.options),function(option,index){return [(_vm._isOptionVisible(option, index))?_c('li',{key:index,class:['sl-context-menu__option-wrapper', _vm._optionClasses(option)]},[(_vm.$scopedSlots[option.key])?[_vm._t(option.key,null,null,{
              option: option,
              handler: _vm._optionHandler(option),
              middleHandler: _vm._optionMiddleHandler(option)
            })]:_c('SlContextMenuOption',{attrs:{"option":option,"disabled":option.disabled,"handler":_vm._optionHandler(option),"middle-handler":_vm._optionMiddleHandler(option)}},[_vm._v(" "+_vm._s(option.name)+" ")])],2):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }