import store from '@/store';
import {
  slErrorCodes,
  noAccessErrorCodes,
  serverBusyErrorCodes,
  thirdPartyServiceErrorCodes
} from '@/config/api/slErrorCodes.config';
import { createMultipleHandlers } from '@/api/helpers/errorRegistry';
import { routeNames } from '@/config/router/router.config';
import statusCodes from '@/config/utils/statusCodes.config';
import { projectStatuses } from '@/config/project';
import { noAccessReasonByErrorCode } from '@/config/router/noAccess.config';
import { manageProjectRequest } from '@/config/api/helperRequests.config';
import { systemRedirect } from '@/helpers/router';

export const globalHandlers = {
  [slErrorCodes.NO_WORKING_TENANT]: {
    silent: true,
    before: () => {
      systemRedirect({
        name: routeNames.ACTIVATION
      });

      return true;
    }
  },
  [slErrorCodes.INSUFFICIENT_ACCOUNT_PERMISSIONS]: {
    silent: true,
    before: async() => {
      await store.dispatch('manageProjects/pingProject', {
        pid: store.state.manageProjects.projectId
      });

      if (!store.state.manageProjects.projectId) {
        systemRedirect({
          name: routeNames.PROJECT_LIST
        });

        return true;
      }
    }
  },
  [slErrorCodes.PROJECT_NOT_FOUND]: {
    silent: true,
    before: async() => {
      await store.dispatch('manageProjects/clearCurrentProject');
      await store.dispatch('manageProjects/getProjectList');

      const hasProjects = store.getters['manageProjects/hasProjectsList'];
      const nextName = hasProjects ? routeNames.PROJECT_LIST : routeNames.GET_STARTED;

      systemRedirect({
        name: nextName
      });

      return true;
    }
  },
  [slErrorCodes.RESOURCE_NOT_FOUND]: {
    silent: true,
    before: async(error) => {
      if (error?.config?.url?.includes(manageProjectRequest)) {
        await store.dispatch('manageProjects/getProjectList');

        const projectId = store.state.manageProjects.projectId;
        const activeProjects = store.getters['manageProjects/activeProjects'];
        const isCurrentActive = activeProjects.some(({ id, status }) => {
          return id === projectId && status === projectStatuses.RUNNING;
        });

        if (!isCurrentActive) {
          store.dispatch('manageProjects/clearCurrentProject');

          systemRedirect({
            name: routeNames.PROJECT_LIST
          });

          return true;
        }
      }
    }
  },
  // server is busy
  ...createMultipleHandlers(serverBusyErrorCodes, () => ({
    text: (error) => error.message,
    before: () => false
  })),
  // no access
  ...createMultipleHandlers(noAccessErrorCodes, (errorCode) => ({
    silent: true,
    before: () => {
      systemRedirect({
        name: routeNames.NO_ACCESS,
        query: {
          reason: noAccessReasonByErrorCode[errorCode]
        }
      });

      return true;
    }
  })),
  // third party service errors
  ...createMultipleHandlers(thirdPartyServiceErrorCodes, () => ({
    silent: true,
    before: () => {
      systemRedirect({
        name: routeNames.NO_CONNECTION
      });

      return true;
    }
  })),
  // web server or sl-server down
  'Network Error': {
    text: () => 'Network Error, try again later'
  },
  [statusCodes.SERVICE_UNAVAILABLE]: {
    silent: true,
    before: () => window.location.reload()
  },
  [statusCodes.BAD_GATEWAY]: {
    silent: true,
    before: () => systemRedirect({
      name: routeNames.SERVER_DOWN
    })
  },
  [statusCodes.CF_WEB_SERVER_DOWN]: {
    silent: true,
    before: () => systemRedirect({
      name: routeNames.SERVER_DOWN
    })
  }
};
