import moment from 'moment';
import { i18n } from '@/plugins/vue-i18n.plugin';
import { errorValue } from '@/config/shared/datePicker.config';

export const dateFormats = {
  DEFAULT_TIME: 'HH:mm',
  SHORT_TIME: 'hh:mm A'
};

export const HOURS_IN_DAY = 24;
export const MIN_IN_HOUR = 60;
export const MIN_IN_DAY = MIN_IN_HOUR * HOURS_IN_DAY;

export const millisecondsToTime = (ms, format = dateFormats.DEFAULT_TIME) => {
  return moment.utc(ms).format(format);
};

export const formatHoursMinutes = (value) => {
  const duration = moment.duration(value);

  const hours = duration.hours();
  const minutes = duration.minutes();

  let formattedDuration = '';

  if (hours > 0) {
    formattedDuration += hours + 'h ';
  }

  if (minutes >= 0) {
    formattedDuration += minutes + 'm';
  }

  return formattedDuration;
};

export const utcTimezoneOffsetTime = (() => {
  const offset = moment().utcOffset();
  const hours = Math.floor(offset / 60);
  const minutes = Math.abs(offset % 60);
  let time = '';

  if (hours > 0) {
    time += '+';
  }

  if (minutes) {
    return time + `${hours}:${minutes}`;
  }

  return time + `${hours}`;
})();

export const formatDayMonthYear = (value) => {
  const date = moment(value);

  return i18n.t(`Common.DateYMD.${date.month() + 1}`, { 1: date.date(), 2: date.year() });
};

export const formatMonthYear = (value) => {
  const date = moment(value);

  return i18n.t(`Common.DateYM.${date.month() + 1}`, { 1: date.year() });
};

export const formatYearMonthDay = (value) => {
  return moment(value).format('YYYY-MM-DD');
};

export const formatFullTime = (value) => {
  return moment(value).format('MMM D, YYYY HH:mm:ss');
};

export const formatFullTimeAMPM = (value) => {
  return moment.utc(value).local().format('hh:mm A, D MMMM, YYYY');
};

export const convertHourToReadableFormat = (hours) => {
  const validHours = (HOURS_IN_DAY + hours) % HOURS_IN_DAY;

  return moment({ hour: validHours }).format(dateFormats.SHORT_TIME);
};

export const inventoryTime = () => {
  return moment().format().replace('T', ' ');
};

export const timezoneOffsetMinutes = () => {
  return new Date().getTimezoneOffset() * -1;
};

export const timezoneOffsetMs = (date) => {
  return new Date(date).getTimezoneOffset() * 60 * 1000;
};

export const dateToMs = (date) => {
  if (!date) {
    return null;
  }

  return new Date(date).getTime();
};

export const dateToCurrentLocale = (date) => {
  return new Date(date).toLocaleDateString(navigator.language);
};

export const isValidDate = (date) => {
  return new Date(date).toString() !== errorValue;
};

export const isValidTime = (time, format) => {
  return moment(time, format, true).isValid();
};

export const is24h = () => {
  return Number.isInteger(Number(new Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).format()));
};
