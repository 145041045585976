import Vue from 'vue';
import { i18n } from '@/plugins/vue-i18n.plugin';
import globalConfig from '@/config/global.config';
import { CROWDIN_IN_CONTEXT_LOCALE } from '@/config/plugins/vue-i18n.config';

// Site for partners
const ALLOWED_URL = 'translation.streamlineplan.com';

export function $cwd_canTranslate() {
  if (window.location.origin.includes(ALLOWED_URL)) {
    return true;
  }

  if (globalConfig.MODE === globalConfig.APP_MODES.DEV) {
    return true;
  }

  if (Vue.$cookies.get('env') === globalConfig.APP_MODES.DEV) {
    return true;
  }

  return false;
}

export function $cwd_isInTranslationMode() {
  return i18n.locale === CROWDIN_IN_CONTEXT_LOCALE;
}

export function $cwd_filterLocales() {
  const canTranslate = $cwd_canTranslate();

  return (locale) => {
    if (canTranslate) {
      return true;
    }

    return locale !== CROWDIN_IN_CONTEXT_LOCALE;
  };
}

export function $cwd_embedScript() {
  if (window.jipt) {
    return;
  }

  const scriptContent = document.createElement('script');

  scriptContent.type = 'text/javascript';

  scriptContent.text = `
      var _jipt = [];
      _jipt.push(['project', 'c814f34adb0f557845afb8cb12a272ef']);
      _jipt.push(['domain', 'streamline']);
  `;
  document.head.appendChild(scriptContent);

  const scriptSrc = document.createElement('script');

  scriptSrc.type = 'text/javascript';
  scriptSrc.src = 'https://cdn.crowdin.com/jipt/jipt.js';

  document.head.appendChild(scriptSrc);
}
