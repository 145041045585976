<template>
  <div
    class="sl-context-menu"
    @click.right.prevent.stop
  >
    <ul
      :id="id"
      v-click-outside="vcoConfig"
      :class="['sl-context-menu__list', {
        'sl-context-menu--active': isActive
      }]"
      :style="{
        maxWidth: `${maxWidth}px`
      }"
    >
      <template v-for="(option, index) in options">
        <li
          v-if="_isOptionVisible(option, index)"
          :key="index"
          :class="['sl-context-menu__option-wrapper', _optionClasses(option)]"
        >
          <template v-if="$scopedSlots[option.key]">
            <slot
              :name="option.key"
              v-bind="{
                option,
                handler: _optionHandler(option),
                middleHandler: _optionMiddleHandler(option)
              }"
            />
          </template>
          <SlContextMenuOption
            v-else
            :option="option"
            :disabled="option.disabled"
            :handler="_optionHandler(option)"
            :middle-handler="_optionMiddleHandler(option)"
          >
            {{ option.name }}
          </SlContextMenuOption>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { keyCodes } from '@/config/utils/statusCodes.config';
import { contextMenuOptionTypes } from '@/config/shared/contextMenu.config';
import { hideAllPoppers } from 'floating-vue';

export default {
  name: 'SlContextMenu',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    //hide if clicked outside of menu
    autoHide: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: Number,
      default: 240
    }
  },
  data() {
    return {
      context: null,
      scrollNode: null,
      isActive: false
    };
  },
  computed: {
    vcoConfig() {
      return {
        handler: this.hide,
        middleware: this._clickOutsideMiddleware,
        events: ['mousedown'],
        isActive: this.autoHide
      };
    }
  },
  mounted() {
    document.body.addEventListener('keyup', this._onEscKey);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this._onEscKey);
  },
  methods: {
    show({ event, context = null, scrollSelector = null }) {
      hideAllPoppers();
      event.preventDefault();

      const menuNode = document.getElementById(this.id);

      if (!menuNode) {
        return;
      }

      this.context = context;
      this.scrollNode = document.querySelector(scrollSelector);

      if (this.scrollNode) {
        this.scrollNode.classList.add('sl-context-menu--overlay');
        this.scrollNode.addEventListener('scroll', this.onScroll, { once: true });
      }

      this.isActive = true;

      this.$nextTick(() => {
        const { offsetWidth: menuWidth, offsetHeight: menuHeight } = menuNode;
        let { x: left, y: top} = event;

        if (left + menuWidth > window.innerWidth) {
          left = left - menuWidth;
        }

        if (top + menuHeight > window.innerHeight) {
          top = top - menuHeight > 0 ? top - menuHeight : 10;
        }

        menuNode.style.left = left + 'px';
        menuNode.style.top = top + 'px';
      });
    },
    hide() {
      const menuNode = document.getElementById(this.id);

      if (menuNode) {
        menuNode.removeAttribute('style');
        this.isActive = false;

        if (this.scrollNode) {
          this.scrollNode.classList.remove('sl-context-menu--overlay');
          this.scrollNode.removeEventListener('scroll', this._onScroll);
          this.scrollNode = null;
        }
      }
    },
    _onEscKey(event) {
      if (event.keyCode === keyCodes.esc && this.isActive) {
        this.hide();
      }
    },
    _onScroll() {
      if (this._clickOutsideMiddleware()) {
        this.hide();
      }
    },
    _clickOutsideMiddleware() {
      const menuNode = document.getElementById(this.id);

      return menuNode?.classList.contains('sl-context-menu--active');
    },
    _optionClasses(option) {
      return [
        option.class,
        option.type === contextMenuOptionTypes.DIVIDER
          ? 'sl-context-menu__divider'
          : ''
      ];
    },
    _optionHandler(option) {
      if (option.options) {
        return () => {};
      }

      return () => this._optionClicked(option);
    },
    _optionClicked(option) {
      this.hide();

      this.$emit('option-click', {
        context: this.context,
        ...option
      });
    },
    _optionMiddleHandler(option) {
      if (option.options) {
        return () => {};
      }

      return () => this._optionMiddleClicked(option);
    },
    _optionMiddleClicked(option) {
      this.hide();

      this.$emit('option-middle-click', {
        context: this.context,
        ...option
      });
    },
    _isOptionVisible(option, index) {
      if (option.type === contextMenuOptionTypes.DIVIDER) {
        if (index === 0 || index === this.options.length - 1) {
          return false;
        }

        return this.options[index - 1].type !== contextMenuOptionTypes.DIVIDER;
      }

      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-context-menu/index.scss';
</style>
