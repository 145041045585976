import { routeNames } from '@/config/router/router.config';

export const APP_NAME = 'Streamline';
export const VUEX_LS_KEY = 'vuex';

export const routeByTab = {
  main: routeNames.DEMAND,
  inventory: routeNames.INVENTORY,
  optimization: routeNames.INTERSTORE,
  forecastReport: routeNames.REPORTS,
  dashboard: routeNames.DASHBOARD,
  changes: routeNames.CHANGES
};

export const componentNames = {
  ...routeNames,
  ROOT: 'root'
};
