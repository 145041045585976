import Vue from 'vue';
import filterApi from '@/api/filters/filter';
import { DEFAULT_FILTER_ID, SEARCH_VALUES_PORTION } from '@/config/filter';
import { hasCompletedRule } from '@/helpers/filter';
import { toArray } from '@/helpers/utils/toArray';

const types = {
  SET_FILTER: 'SET_FILTER',
  SET_FILTER_COLUMNS: 'SET_FILTER_COLUMNS',
  SET_FILTER_COLUMN_VALUES: 'SET_FILTER_COLUMN_VALUES',
  SET_IS_UPDATING: 'SET_IS_UPDATING',
  SET_IS_INITIALIZING: 'SET_IS_INITIALIZING',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  filter: null,
  columns: [],
  column_values: {},
  is_updating: false,
  is_initializing: false
});

const state = initialState();

const getters = {
  commonParams: (state, _, rootState) => ({
    type: rootState.filters.filter_type,
    ...(state.filter && { filterId: state.filter.filterId })
  }),
  hasCompletedRule: (state) => hasCompletedRule(state.filter?.predicates?.rules),
  columnByKeyMap: (state) => state.columns.reduce((acc, column) => {
    acc[column.columnKey] = column;

    return acc;
  }, {})
};

const mutations = {
  [types.SET_FILTER](state, value) {
    state.filter = value;
  },
  [types.SET_FILTER_COLUMNS](state, value) {
    state.columns = value;
  },
  [types.SET_FILTER_COLUMN_VALUES](state, { columnKey, value }) {
    Vue.set(state.column_values, columnKey, value);
  },
  [types.SET_IS_UPDATING](state, value) {
    state.is_updating = value;
  },
  [types.SET_IS_INITIALIZING](state, value) {
    state.is_initializing = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  setFilter({ commit }, value) {
    commit(types.SET_FILTER, value);
  },
  async initializeFilter({ state, commit, dispatch }, filterId) {
    try {
      commit(types.SET_IS_INITIALIZING, true);

      await Promise.allSettled([
        !state.filter && dispatch('createFilterCache', filterId),
        dispatch('fetchFilterableColumns')
      ]);
    } finally {
      commit(types.SET_IS_INITIALIZING, false);
    }
  },
  async fetchFilter({ getters }, filterId) {
    try {
      const response = await filterApi.getFilter({
        ...getters.commonParams,
        filterId
      });

      if (!response?.data) {
        return;
      }

      return response.data;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchFilter' });
    }
  },
  // Entire filter cache actions
  async createFilterCache({ commit, getters }, filterId) {
    try {
      const response = await filterApi.createFilterCache({
        ...getters.commonParams,
        filterId
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'createFilterCache' });
    }
  },
  async changeFilterCache({ commit, getters }, payload) {
    try {
      const response = await filterApi.changeFilterCache(
        getters.commonParams,
        payload
      );

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'changeFilterCache' });
    }
  },
  async saveFilterCache({ getters }) {
    try {
      const response = await filterApi.saveFilterCache(getters.commonParams);

      return response?.data?.payload?.id ?? DEFAULT_FILTER_ID;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'saveFilterCache' });
    }
  },
  async discardFilterCache({ getters }) {
    try {
      await filterApi.discardFilterCache(getters.commonParams);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'discardFilterCache' });
    }
  },
  // Filter columns and column values actions
  async fetchFilterableColumns({ commit, getters }) {
    try {
      const response = await filterApi.getFilterableColumns(getters.commonParams);

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER_COLUMNS, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchFilterableColumns' });
    }
  },
  async fetchFilterableColumnValues({ commit, getters }, { lazy, query = '', portion = 0, ...payload }) {
    try {
      const response = await filterApi.getFilterableColumnValues({
        ...getters.commonParams,
        ...payload,
        ...(lazy && {
          top: SEARCH_VALUES_PORTION,
          skip: portion * SEARCH_VALUES_PORTION,
          query
        })
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER_COLUMN_VALUES, {
        columnKey: payload.columnKey,
        value: !portion
          ? toArray(response.data.info)
          : toArray(state.column_values[payload.columnKey]).concat(toArray(response.data.info))
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchFilterableColumnValues' });
    }
  },
  // Filter rules actions
  async createFilterRule({ commit, getters }, groupId) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const response = await filterApi.createFilterRule({
        ...getters.commonParams,
        groupId
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'createFilterRule' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  async duplicateFilterRule({ commit, getters }, ruleId) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const response = await filterApi.duplicateFilterRule({
        ...getters.commonParams,
        ruleId
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'duplicateFilterRule' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  async ungroupFilterRule({ commit, getters }, ruleId) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const response = await filterApi.ungroupFilterRule({
        ...getters.commonParams,
        ruleId
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'ungroupFilterRule' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  async changeFilterRule({ commit, getters }, { ruleId, value }) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const response = await filterApi.changeFilterRule(
        { ...getters.commonParams, ruleId },
        value
      );

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'changeFilterRule' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  async deleteFilterRule({ commit, getters }, ruleId) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const response = await filterApi.deleteFilterRule({
        ...getters.commonParams,
        ruleId
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'deleteFilterRule' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  // Filter groups actions
  async createFilterGroup({ commit, getters }, ruleId) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const response = await filterApi.createFilterGroup({
        ...getters.commonParams,
        ruleId
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'createFilterGroup' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  async changeFilterGroup({ commit, getters }, { groupId, value }) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const response = await filterApi.changeFilterGroup(
        {
          ...getters.commonParams,
          groupId
        },
        value
      );

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'createFilterGroup' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  async duplicateFilterGroup({ commit, getters }, groupId) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const response = await filterApi.duplicateFilterGroup({
        ...getters.commonParams,
        groupId
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'duplicateFilterGroup' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  async ungroupFilterGroup({ commit, getters }, groupId) {
    try {
      commit(types.SET_IS_UPDATING, true);

      const response = await filterApi.ungroupFilterGroup({
        ...getters.commonParams,
        groupId
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'ungroupFilterGroup' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  async deleteFilterGroup({ commit, getters }, groupId) {
    try {
      const response = await filterApi.deleteFilterGroup({
        ...getters.commonParams,
        groupId
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_FILTER, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'deleteFilterGroup' });
    } finally {
      commit(types.SET_IS_UPDATING, false);
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
