export default function(data) {
  const { name, type, rows } = data;
  const coeffs = rows?.map(row => row.value?.val);
  const level = data.level || coeffs?.pop();

  return {
    name,
    type,
    coeffs,
    level
  };
}