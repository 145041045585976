<template>
  <div
    v-if="file"
    class="sl-dropzone-preview-item"
    :class="{
      'sl-dropzone-preview-item--error': isError
    }"
  >
    <div class="sl-dropzone-preview-item__preview">
      <transition name="fade">
        <icon
          v-if="isError"
          data="@icons/error_circle.svg"
          class="fill-off size-24 color-accent-red-90 sl-dropzone-preview-item__icon"
        />
      </transition>
      <transition name="fade">
        <icon
          v-if="isFileTypeIconVisible"
          :data="fileTypeIcon"
          class="size-24 sl-dropzone-preview-item__icon"
        />
      </transition>
      <transition name="fade">
        <icon
          v-if="isSuccessIconVisible"
          data="@icons/success.svg"
          class="fill-off size-24 color-primary-80 sl-dropzone-preview-item__icon"
        />
      </transition>
      <transition name="fade">
        <SlLoader
          v-if="uploading"
          size="xs"
          :text="false"
        />
      </transition>
    </div>
    <div class="sl-dropzone-preview-item__details">
      <div
        v-tooltip="getTooltip(file.name)"
        class="sl-dropzone-preview-item__name body-2 grey-80"
      >
        {{ file.name }}
      </div>
      <div
        v-if="!isError"
        class="sl-dropzone-preview-item__size body-1 grey-70"
      >
        {{ size }}
      </div>
      <div
        v-else
        class="sl-dropzone-preview-item__error"
      >
        <div
          v-tooltip="getErrorTooltip(error)"
          class="sl-dropzone-preview-item__error-text body-1 accent-red-90"
        >
          {{ error }}
        </div>
      </div>
    </div>
    <div class="sl-dropzone-preview-item__actions">
      <SlButton
        v-if="isError && reupload"
        v-tooltip="getTooltip($t('Web.FileUpload.TryAgain'))"
        size="2xs"
        variant="tertiary"
        color="grey"
        icon
        @click="handleTryAgain"
      >
        <icon
          data="@icons/reload.svg"
          class="fill-off size-16"
        />
      </SlButton>
      <SlButton
        v-if="remove"
        v-tooltip="removeButtonTooltip"
        size="2xs"
        variant="tertiary"
        color="grey"
        icon
        @click="handleRemove"
      >
        <icon
          data="@icons/trash.svg"
          class="fill-off size-16"
        />
      </SlButton>
    </div>
  </div>
</template>

<script>
import { bytesToReadableSize } from '@/helpers/file/fileSize';
import { getErrorTooltip } from '@/helpers/shared/tooltip';
import { fileTypeFromName } from '@/helpers/utils/fileUpload';
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'SlDropzonePreviewItem',
  props: {
    file: {
      type: [File, Object],
      default: null
    },
    successful: {
      type: Boolean,
      default: true
    },
    error: {
      type: String,
      default: ''
    },
    remove: {
      type: Boolean,
      default: true
    },
    reupload: {
      type: Boolean,
      default: true
    },
    uploading: Boolean
  },
  data() {
    return {
      getErrorTooltip,
      showSuccessfulIcon: false,
      showRemoveTimeout: 1000,
      iconByType: {
        xlsx: 'excel',
        csv: 'excel',
        xls: 'excel',
        gsl: 'gsl',
        pem: 'pem',
        png: 'png',
        jpg: 'jpg',
        mp4: 'mp4'
      }
    };
  },
  computed: {
    size() {
      return bytesToReadableSize(this.file.size);
    },
    fileType() {
      return fileTypeFromName(this.file.name);
    },
    iconName() {
      return this.iconByType[this.fileType] || 'any_file';
    },
    fileTypeIcon() {
      return require(`@icons/${this.iconName}.svg`);
    },
    isError() {
      return (!this.uploading && !this.successful) || (!this.uploading && !this.successful && this.error);
    },
    isSuccessIconVisible() {
      return this.successful && !this.uploading && this.showSuccessfulIcon;
    },
    isFileTypeIconVisible() {
      return !this.uploading && !this.isSuccessIconVisible && !this.isError;
    },
    removeButtonTooltip() {
      const content = this.uploading
        ? this.$t('Web.FileUpload.TooltipCancelUpload')
        : this.$t('Web.FileUpload.TooltipRemoveFile');

      return this.getTooltip(content);
    }
  },
  watch: {
    successful(val) {
      if (!val) {
        return;
      }

      this.showSuccessfulIcon = true;

      setTimeout(() => {
        this.showSuccessfulIcon = false;
      }, this.showRemoveTimeout);
    }
  },
  methods: {
    getTooltip,
    handleRemove() {
      this.$emit('remove', this.file);
    },
    handleTryAgain() {
      this.$emit('reupload', this.file);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-dropzone/sl-dropzone-preview-item.scss';
</style>