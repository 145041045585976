import Vue from 'vue';
import chartApi from '@/api/demand/chart';
import { chartTabKeys } from '@/config/demand/chart/chart.config';

const types = {
  SET_CHART_DATA: 'SET_CHART_DATA',
  SET_CHART_TYPE: 'SET_CHART_TYPE',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_CURVES_VISIBILITY: 'SET_CURVES_VISIBILITY'
};

const state = () => ({
  chart_type: chartTabKeys.QTY,
  chart_data: {
    [chartTabKeys.QTY]: null,
    [chartTabKeys.REVENUE]: null
  },
  curves_visibility: null,
  is_loading: false
});

const getters = {
  currentChartData: (state) => state.chart_data[state.chart_type],
  modelTypeName: (_, getters) => getters.currentChartData?.modelTypeName
};

const mutations = {
  [types.SET_CHART_DATA](state, { type, data }) {
    Vue.set(state.chart_data, type, data);
  },
  [types.SET_CHART_TYPE](state, value) {
    state.chart_type = value;
  },
  [types.SET_IS_LOADING](state, value) {
    state.is_loading = value;
  },
  [types.SET_CURVES_VISIBILITY](state, value) {
    state.curves_visibility = value;
  }
};

const actions = {
  fetchCharts({ dispatch }) {
    return Promise.allSettled([
      dispatch('fetchChart', chartTabKeys.QTY),
      dispatch('fetchChart', chartTabKeys.REVENUE)
    ]);
  },
  async fetchChart({ state, commit, dispatch, rootGetters }, chartType) {
    try {
      const type = chartType || state.chart_type;

      if (!state.curves_visibility) {
        await dispatch('fetchCurvesVisibility');
      }

      const response = await chartApi.getCharts({
        nodeId: rootGetters['demand/tree/activeNodeId'],
        filterId: rootGetters['filters/activeFilterId'],
        chartType: type
      });
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(types.SET_CHART_DATA, {
        type,
        data
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchChart' });
    } finally {
      commit(types.SET_IS_LOADING, false);
    }
  },
  async fetchCurvesVisibility({ commit }) {
    try {
      const response = await chartApi.getCurvesVisibility();
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(types.SET_CURVES_VISIBILITY, data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchChartCurves' });
    }
  },
  async updateCurveVisibility(_, payload) {
    try {
      await chartApi.updateCurveVisibility(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateCurveVisibility' });
    }
  },
  setChartType({ state, commit, dispatch }, type) {
    commit(types.SET_CHART_TYPE, type);

    if (!state.chart_data[type]) {
      commit(types.SET_IS_LOADING, true);

      dispatch('fetchChart');
    }
  },
  resetHiddenChartData({ state, commit }) {
    const type = state.chart_type === chartTabKeys.QTY
      ? chartTabKeys.REVENUE
      : chartTabKeys.QTY;

    commit(types.SET_CHART_DATA, { type, data: null });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
