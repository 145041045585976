import Vue from 'vue';
import { guardlessRoutes } from '@/config/router/router.config';

export default async function auth({ to, next, nextMiddleware }) {
  let { authState } = Vue.mapAuthState();

  if (!authState.isAuthenticated) {
    // await frontegg auth
    while (!authState.isAuthenticated) {
      await new Promise(res => setTimeout(() => {
        authState = Vue.mapAuthState().authState;

        res();
      }, 100));
    }

    return nextMiddleware();
  }

  // skip redirect if it's empty page redirect
  if (!to.name) {
    return;
  }

  // skip middlewares for guardless routes
  const guardlessRoute = guardlessRoutes.includes(to.name);

  if (guardlessRoute) {
    return next();
  }

  return nextMiddleware();
}