<template>
  <div
    v-if="isAlertsVisible"
    class="global-alerts"
  >
    <SlAlertLine
      v-if="isNeedPayInvoice"
      class="expiration-alert"
      type="error"
      :accent="$t('Web.LicenseDetails.AccountSuspension')"
      column
    >
      <template #text>
        <p>{{ $t('Web.LicenseDetails.OutstandingInvoicesReminder', { 1: invoiceDueLocaleDate }) }}</p>
        <i18n
          path="Web.LicenseDetails.PaymentConfirmationInstruction"
          tag="p"
        >
          <template v-slot:1>
            <SlLink
              :href="`mailto:${SUPPORT_EMAIL}`"
              target="_blank"
              color="red"
            >
              {{ SUPPORT_EMAIL }}
            </SlLink>
          </template>
        </i18n>
        <SlLink
          v-if="$sl_isUserAdmin"
          :href="{
            name: routeNames.SUBSCRIPTION
          }"
          class="mt-8"
          color="red"
        >
          {{ $t('Web.LicenseDetails.ReviewSubscription') }}
        </SlLink>
      </template>
    </SlAlertLine>
    <SlAlertLine
      v-if="isConstrainedPlanOutdated"
      type="warn"
      :accent="$t('Web.Settings.ConstrainedPlanOutdated')"
      :text="$t('Web.Settings.ConstrainedPlanOutdatedWarning')"
    />
    <SlAlertLine
      v-if="isOverdated"
      type="warn"
    >
      <template #accent>
        <i18n path="Web.UpdateData.PleaseUpdate">
          <template v-slot:1>
            <SlLink
              :href="{
                name: routeNames.SETTINGS,
                hash: `#${settingsTabKeys.PROJECT}`
              }"
              color="yellow"
            >
              {{ $t('Main.Ui.acSettings') }}
            </SlLink>
          </template>
        </i18n>
      </template>
    </SlAlertLine>
    <SlAlertLine
      v-if="frozenDate"
      type="warn"
      :accent="$t('Web.FreezeCalc.AlertLabel', { 1: frozenDate })"
    >
      <template #text>
        <SlLink
          color="yellow"
          @click.prevent="handleUnfreezeSimulation"
        >
          {{ $t('Web.FreezeCalc.Unfreeze') }}
        </SlLink>
      </template>
    </SlAlertLine>
    <SlAlertLine
      v-if="isRequiresFullReimport"
      type="warn"
      :accent="$t('Web.UpdateData.UpdateDataFullRequired')"
    >
      <template #text>
        <i18n path="Web.UpdateData.UpdateDataFullRequiredMessage">
          <template v-slot:1>
            <SlLink
              color="yellow"
              @click.prevent="handleReImport(updateData.FULL, logLevel.OFF)"
            >
              {{ $t('Web.UpdateData.UpdateDataFull') }}
            </SlLink>
          </template>
        </i18n>
      </template>
    </SlAlertLine>
    <SlAlertLine
      v-if="showOrderAvailabilityDeprecatedWarn"
      type="warn"
      :accent="$t('Web.Suppliers.OrderingAvailabilityDeprecated')"
      column
    >
      <template #text>
        <i18n path="Web.Suppliers.OrderingAvailabilityMessage">
          <template v-slot:1>
            <SlLink
              :href="{
                name: routeNames.SETTINGS,
                hash: `#${settingsTabKeys.PROJECT}`
              }"
              color="yellow"
            >
              {{ $t('Main.Ui.acSettings') }}
            </SlLink>
          </template>
        </i18n>
      </template>
    </SlAlertLine>
    <SlAlertLine
      v-if="updateFailedReason"
      type="warn"
      :accent="$t('ProjectChanges.Other.UpdateFailed', { 1: updateFailedReason })"
      closable
      @close="handleCloseFailedImportReason"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { routeNames } from '@/config/router/router.config';
import { settingsTabKeys } from '@/config/shared/settings.config';
import { SUPPORT_EMAIL } from '@/config/license';
import { logLevel, updateData } from '@/config/project/updateData.config';
import { uiSettingsKeys, uiTabKeys } from '@/config/users/uiSettings.config';

export default {
  name: 'GlobalAlerts',
  data() {
    return {
      routeNames,
      settingsTabKeys,
      logLevel,
      updateData,
      SUPPORT_EMAIL
    };
  },
  inject: ['handleReImport'],
  computed: {
    ...mapState({
      isOverdated: (state) => state.project.project?.isOverdated,
      frozenDate: (state) => state.project.project?.freeze,
      isConstrainedPlanOutdated: (state) => state.project.project?.isConstrainedPlanOutdated,
      isNeedPayInvoice: (state) => state.account?.license_details?.needPayInvoice,
      isRequiresFullReimport: (state) => state.project.project?.requiresFullReload,
      showOrderAvailabilityDeprecatedWarn: (state) => state.project.project?.showOrderAvailabilityDeprecatedWarn,
      updateFailedReason: (state) => state.project.project?.updateFailedReason
    }),
    ...mapGetters({
      invoiceDueLocaleDate: 'account/invoiceDueLocaleDate'
    }),
    isAlertsVisible() {
      return this.isNeedPayInvoice
        || this.isOverdated
        || Boolean(this.frozenDate)
        || Boolean(this.isConstrainedPlanOutdated)
        || this.isRequiresFullReimport
        || this.showOrderAvailabilityDeprecatedWarn
        || this.updateFailedReason;
    }
  },
  methods: {
    ...mapActions({
      unfreezeProject: 'project/unfreezeProject',
      changeUiSettings: 'user/changeUiSettings'
    }),
    handleUnfreezeSimulation() {
      this.unfreezeProject();
    },
    handleCloseFailedImportReason() {
      this.changeUiSettings({
        uiTab: uiTabKeys.GLOBAL,
        key: uiSettingsKeys.SPECIFIC,
        value: {
          showFailedImport: false
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.expiration-alert {
  padding: 20px;
}

.mt-8 {
  margin-top: 8px;
}
</style>