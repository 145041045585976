export const tileKeys = {
  INV_VALUE: 'InvValue',
  OVERSTOCK: 'ExpectedOverstock',
  STOCKOUT: 'ExpectedStockout',
  DEAD_INVENTORY_VALUE: 'DeadInvValue',
  TURNOVER: 'Turnover',
  MARGIN: 'Margin',
  REVENUE_LAST: 'RevenueLast',
  PURCHASE_VALUE: 'PurchaseValue',
  WRITEOFF_VALUE: 'WriteoffValue',
  ANNUAL_SERVICE_LEVEL: 'AnnualServiceLevel'
};

export const colClassByTileKey = {
  [tileKeys.INV_VALUE]: 'INVENTORY_VALUE',
  [tileKeys.OVERSTOCK]: 'OVERSTOCK',
  [tileKeys.STOCKOUT]: 'STOCKOUT',
  [tileKeys.DEAD_INVENTORY_VALUE]: 'DEAD_INVENTORY_VALUE',
  [tileKeys.TURNOVER]: 'TURNOVER',
  [tileKeys.MARGIN]: 'MARGIN',
  [tileKeys.REVENUE_LAST]: 'REVENUE_LAST',
  [tileKeys.PURCHASE_VALUE]: 'PURCHASE_VALUE_I_0',
  [tileKeys.WRITEOFF_VALUE]: 'EXPECTED_WRITEOFF',
  [tileKeys.ANNUAL_SERVICE_LEVEL]: 'ANNUAL_SERVICE_LEVEL'
};

export const titleByKey = {
  [tileKeys.INV_VALUE]: {
    code: 'Dashboard.Titles.InvValue',
    prop: 'inventory'
  },
  [tileKeys.OVERSTOCK]: {
    code: 'Dashboard.Titles.Overstock',
    prop: 'overstock'
  },
  [tileKeys.STOCKOUT]: {
    code: 'Dashboard.Titles.Stockout',
    prop: 'stockout'
  },
  [tileKeys.DEAD_INVENTORY_VALUE]: {
    code: 'Dashboard.Titles.DeadInvValue',
    prop: 'deadInv'
  },
  [tileKeys.TURNOVER]: {
    code: 'Main.Cols.TurnoverCap',
    prop: 'turnover'
  },
  [tileKeys.MARGIN]: {
    code: 'Dashboard.Titles.Margin',
    prop: 'margin'
  },
  [tileKeys.REVENUE_LAST]: {
    code: 'Dashboard.Titles.RevLast',
    prop: 'revenueLast'
  },
  [tileKeys.PURCHASE_VALUE]: {
    code: 'Main.Cols.PurchaseValue2',
    prop: 'purchaseValue'
  },
  [tileKeys.WRITEOFF_VALUE]: {
    code: 'Dashboard.Titles.WriteOffValue',
    prop: 'expectedWriteOffs'
  },
  [tileKeys.ANNUAL_SERVICE_LEVEL]: {
    code: 'Main.Cols.AnnualServiceLevel',
    prop: 'annualServiceLevel'
  }
};

export const periodKeys = {
  START: 'Start',
  PREVIOUS: 'Previous',
  LAST_YEAR: 'LastYear'
};

export const tileFormats = {
  GRID_01: 'grid-1',
  GRID_02: 'grid-2',
  GRID_03: 'grid-3',
  GRID_04: 'grid-4'
};

export const mediaQueries = {
  MAX: 1500,
  MD: 1200,
  MIN: 800
};

export const chartColors = {
  green: 'accent-green',
  red: 'accent-red'
};

export const periodValues = (ctx) => ({
  [periodKeys.START]: ctx.$t('Dashboard.ComparePeriods.strt'),
  [periodKeys.PREVIOUS]: ctx.$t('Dashboard.ComparePeriods.prev'),
  [periodKeys.LAST_YEAR]: ctx.$t('Dashboard.ComparePeriods.last')
});

export const KPIsKey = 'KPIs';

export const monitoringTabs = (ctx) => ([
  {
    id: KPIsKey,
    name: ctx.$t('Web.Dashboard.KPI')
  }
]);