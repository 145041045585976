import { contextMenuKeys } from './contextMenu.config';

export const nodeFlags = {
  ALLOWS_NODE:               1,
  HAS_NOTE:                  1 << 1,
  HAS_APPROVED_PREDICTION:   1 << 2,
  TIMEPOINTS_OVERRIDEN:      1 << 3,
  IS_DISTRIB_ENTRY:          1 << 4,
  IS_ABC_SUBCAT:             1 << 5, // deprecated
  IS_BARE_CHANNEL:           1 << 6, // deprecated
  IS_STRICT_CHANNEL:         1 << 7,
  IS_THING:                  1 << 8,
  IS_NO_CHANNEL:             1 << 9,
  HAS_EDITABLE_PERMISSION:   1 << 10,
  IS_ENTRY:                  1 << 11,
  IS_CHANNEL:                1 << 12,
  IS_INVENTORY_OVERRIDEABLE: 1 << 13,
  ARE_SALES_OVERRIDEABLE:    1 << 14,
  HAS_ONHAND:                1 << 15,
  HAS_CUSTOM_OUTLIER:        1 << 17,
  CAN_SAVE_MODE_AS_PATTERN:  1 << 18,
  HAS_MODEL_COEFFS:          1 << 19,
  HAS_TOP_DOWN_COEFFS:       1 << 20
};

export const demandColsFlags = {
  WANT_SHOW_SAFETY_STOCK:  1,
  IS_CALCULATED_OUTLIER:   1 << 1,
  HAS_PROMOTIONS:          1 << 2,
  ALLOW_ONHAND_NOTE:       1 << 3,
  ALLOW_ACTUAL_DATA_NOTE:  1 << 4,
  ALLOW_OVERRIDE_NOTE:     1 << 5,
  IS_ALL_APPROVED:         1 << 6,  // is approved on ALL nodes in subtree
  // DISPLAY_APPROVED_AS_OVERRIDE: 1 << 7, deprecated
  IS_SMTH_APPROVED:        1 << 8 // is approved on SOME node in subtree
};

export const treeFgs = {
  ORDER_SET:                  1 << 0,
  ORDER_INHERITED:            1 << 1,
  TD_TOPDOWN:                 1 << 2,
  TD_TOPDOWN_CHILDREN:        1 << 3,
  TD_BOTTOMUP:                1 << 4,
  TD_INH_TOPDOWN:             1 << 5,
  TD_INH_BOTTOMUP:            1 << 6,
  IGNOREDATA_SET:             1 << 7,
  IGNOREDATA_INHERITED:       1 << 8,
  MODEL_SET:                  1 << 9,
  MODEL_INHERITED:            1 << 10,
  MODEL_IMPORTED:             1 << 11,
  MULTIPLIER_SET:             1 << 12,
  MULTIPLIER_INHERITED:       1 << 13,
  ZEROLOST_YES_SET:           1 << 16,
  ZEROLOST_YES_INHERITED:     1 << 17,
  CULTURE_SET:                1 << 18,
  CULTURE_INHERITED:          1 << 19,
  USEMODEL_WRONG:             1 << 20,
  USEMODEL_CYCLIC_FAULTY:     1 << 21,
  USEMODEL_CYCLIC_INHERITED:  1 << 22,
  BOM_NO:                     1 << 23,
  BOM_NO_INHERITED:           1 << 24,
  NOTE:                       1 << 25,
  EDITABLE:                   1 << 26,
  INACTIVE_NODE:              1 << 27,
  EDITABLE_FOR_SOME_CHILDREN: 1 << 28,
  ARE_CHILDREN_FILTERED_OUT:  1 << 29
};

export const supportedExportFgs = {
  INV_PLANNED:     1 << 3,
  DC_PLANNED:      1 << 3,
  OPT_PLANNED:     1 << 3,
  INVENTORY:       1,
  DC:              1,
  OPTIMIZED:       1,
  FORECAST_REPORT: 1 << 1
};

export const exportFgs = {
  INVENTORY_PLANNED_ORDERS: 1,
  DC_PLANNED_ORDERS:        1 << 1, // unused
  INVENTORY_REPORT:         1 << 2,
  DC_REPORT:                1 << 3, // unused
  FORECAST_REPORT:          1 << 4
};

export const inventoryCellFgs = {
  SUPPORTS_CONTAINERS: 1 << 3,
  SUPPORTS_GROUPS:     1 << 5,
  EDITABLE:            1 << 6,
  SUPPORTS_BOM_GROUPS: 1 << 7
};

export const demandTableContextMenuFgs = {
  [contextMenuKeys.CUT]:                                 1,
  [contextMenuKeys.COPY]:                                1 << 1,
  [contextMenuKeys.PASTE]:                               1 << 2,
  [contextMenuKeys.DELETE]:                              1 << 3,
  [contextMenuKeys.ADD_NOTE]:                            1 << 4,
  [contextMenuKeys.APPLY_CELL_TO_FINAL_FORECAST]:        1 << 5,
  [contextMenuKeys.APPLY_ROW_TO_FINAL_FORECAST]:         1 << 6,
  [contextMenuKeys.COPY_CELL_FROM_STATISTICAL_FORECAST]: 1 << 7,
  [contextMenuKeys.COPY_ROW_FROM_STATISTICAL_FORECAST]:  1 << 8,
  [contextMenuKeys.PROPAGATE_VALUE]:                     1 << 9,
  [contextMenuKeys.EXPORT_NODE_TO_XLSX]:                 1 << 10,
  [contextMenuKeys.CONVERT_CELL_FORMULAS_TO_VALUES]:     1 << 11,
  [contextMenuKeys.CONVERT_ROW_FORMULAS_TO_VALUES]:      1 << 12
};

export const contextMenuFgs = {
  COPY_ITEM_CODE:              1,
  APPROVE:                     1 << 1,
  NEEDS_ATTENTION:             1 << 2,
  CHECK:                       1 << 3,
  FIND_IN_IR:                  1 << 4,
  FIND_IN_DC:                  1 << 5, // unused
  FIND_IN_FR:                  1 << 6,
  REMOVE_FROM_IMPORT:          1 << 7,
  SUBSTITUTE:                  1 << 8,
  ADD_NOTE:                    1 << 9,
  EDIT_NOTE:                   1 << 10,
  CLEAR_OVERRIDES:             1 << 11,
  CLEAR_SETTINGS:              1 << 12, // unused
  EXPORT_CURRENT_NODE_TABLE:   1 << 13,
  EXPORT_CHILD_NODES_TABLE:    1 << 14,
  ITEM_BOM:                    1 << 15,
  FILTER_BY:                   1 << 16,
  ADD_TO_FILTER:               1 << 17,
  FILTER_BY_BOM:               1 << 18,
  EXPLAIN_CALCULATION:         1 << 19,
  EDIT:                   	   1 << 20,
  FIND_IN_DEMAND:              1 << 21,
  RESET_NEXT_ORDER_DATE:       1 << 22,
  CLEAR_FORECAST_APPROACH:     1 << 23,
  CLEAR_IGNORE_ZERO_SALES:     1 << 24,
  CLEAR_IGNORE_TREND:          1 << 25,
  CLEAR_MODEL_TYPE:            1 << 26,
  CLEAR_USE_MODEL_FROM:        1 << 27,
  CLEAR_MODEL_COEFFS:          1 << 28,
  CLEAR_HOLIDAYS:              1 << 29,
  CLEAR_USE_PROMOTIONS:        1 << 30,
  CLEAR_USE_PRICE_ELASTICITY:  1 << 31,
  CLEAR_MATERIAL_PROCUREMENT:  1n << 32n,
  CLEAR_ORDERING_AVAILABILITY: 1n << 33n,
  CLEAR_IGNORE_STOCKOUT_DAYS:  1n << 34n,
  CLEAR_DEMAND_SENSING:        1n << 35n,
  CLEAR_APPROVAL_HORIZON:      1n << 36n,
  CLEAR_SEASONAL_PATTERNS:     1n << 37n,
  IS_MODEL_ACTIVE:             1n << 38n,
  COPY_QTY_TO_OVERRIDE:        1n << 39n,
  CLEAR_AUTODETECT_OUTLIERS:   1n << 40n,
  CLEAR_WEEK_52_SEASONS:       1n << 41n
};
