import Vue from 'vue';
import spreadsheetApi from '@/api/connections/spreadsheet';
import BasicSettingsAdapter from '@/adapters/response/connection/spreadsheet/BasicSettings.adapter';
import TablePreviewAdapter from '@/adapters/response/connection/shared/TablePreview.adapter';
import {
  composeDateKeys,
  fileSourceTypes,
  importTabKeys,
  mainTabs,
  matchKeys,
  requestTypeByConnector,
  tabKeys
} from '@/config/connection/spreadsheet.config';
import { connectionTypes, DEFAULT_GROUP_BY } from '@/config/connection';
import { fileFormats } from '@/config/utils/fileUpload.config';
import { toArray } from '@/helpers/utils/toArray';
import { setColSlot } from '@/helpers/connection';
import cloneObject from '@/helpers/utils/cloneObject';
import { fileToFormData } from '@/helpers/utils/fileUpload';
import { gzipFile } from '@/helpers/utils/gzip';

const types = {
  SET_FILES_SOURCE: 'SET_FILES_SOURCE',
  SET_FILES: 'SET_FILES',
  SET_FILES_SHEETS: 'SET_FILES_SHEETS',
  SET_WARNING: 'SET_WARNING',
  SET_BASIC_SETTINGS: 'SET_BASIC_SETTINGS',
  UPDATE_TAB_SETTINGS: 'UPDATE_TAB_SETTINGS',
  SET_MATCHED_SHEETS: 'SET_MATCHED_SHEETS',
  UPDATE_MATCHED_SHEETS: 'UPDATE_MATCHED_SHEETS',
  SET_TAB_SETTINGS: 'SET_TAB_SETTINGS',
  SET_MATCHED_SLOT: 'SET_MATCHED_SLOT',
  SET_SLOTS_INDEXES: 'SET_SLOTS_INDEXES',
  RESET_IS_TABS_LOADED: 'RESET_IS_TABS_LOADED',
  SET_CONNECTION_SETTINGS: 'SET_CONNECTION_SETTINGS',
  RESET_MATCHED_SHEETS: 'RESET_MATCHED_SHEETS',
  RESET_IMPORT_TAB: 'RESET_IMPORT_TAB',
  RESET_STATE: 'RESET_STATE'
};

const singleImportTabInitialState = () => ({
  importPreview: {},
  availableSlots: [],
  matchedSlots: {},
  slotsIndexes: {},
  isLoaded: false,
  isLoading: false
});

const importTabsInitialState = () => {
  return importTabKeys.reduce((acc, tab) => {
    acc[tab] = singleImportTabInitialState();

    return acc;
  }, {});
};

const matchSheetsInitialState = () => {
  return importTabKeys.reduce((acc, key) => {
    acc[key] = {
      [matchKeys.FILE]: null,
      [matchKeys.SHEET]: null
    };

    return acc;
  }, {});
};

const initialState = () => ({
  files_source: fileSourceTypes.FILE,
  files: [],
  files_sheets: {},
  warning: '',
  commonData: {
    type: connectionTypes.SPREADSHEET_TRANSACTIONAL
  },
  [tabKeys.GENERAL]: {
    groupBy: DEFAULT_GROUP_BY,
    csvDelimiter: '',
    startFrom: 1,
    composeDate: composeDateKeys.NONE,
    headerRowsCount: 1,
    uniteLocations: false,
    zeroPriceTransactions: true,
    zeroQtyTransactions: false
  },
  [tabKeys.MATCH_SHEETS]: matchSheetsInitialState(),
  ...importTabsInitialState()
});

const state = initialState();

const getters = {
  isCsv: (state) => !!state[tabKeys.GENERAL].csvDelimiter,
  dataByTab: (state) => (tab) => state[tab],
  requestType: (state) => requestTypeByConnector[state.commonData.type],
  requestParams: (state, getters) => (fileId) => {
    const file = getters.fileByFileId(fileId);
    const csvDelimiter = state[tabKeys.GENERAL].csvDelimiter;
    const params = {
      type: getters.requestType,
      HeaderRowsCount: state[tabKeys.GENERAL].headerRowsCount,
      composeDate: state[tabKeys.GENERAL].composeDate
    };

    if (file && file.type === fileFormats.csv) {
      params.csvDelimiter = csvDelimiter;
    }

    return params;
  },
  fileIds: (state) => state.files.map(file => file.fileId).join(','),
  isMultipleFiles: (state) => state.files.length > 1,
  isFileUsed: (state, getters) => (fileId) => {
    const matched = state[tabKeys.MATCH_SHEETS];

    if (getters.isMultipleFiles) {
      return importTabKeys.some(key => matched[key][matchKeys.FILE] === fileId);
    }

    return importTabKeys.some(key => matched[key][matchKeys.SHEET]);
  },
  fileByFileId: (state) => (fileId) => {
    if (!fileId) {
      return null;
    }

    return state.files.find(file => file.fileId === fileId);
  },
  mainKey: (state) => state.commonData.type === connectionTypes.SPREADSHEET_TRANSACTIONAL
    ? tabKeys.TRANSACTIONS
    : tabKeys.SALES,
  isTabHasSlots: (state) => (tab) => Object.values(state[tab].matchedSlots).some(Boolean),
  hasMatchedSheets: (state) => {
    return importTabKeys.some(tab => state[tabKeys.MATCH_SHEETS][tab][matchKeys.SHEET] !== null);
  }
};

const mutations = {
  [types.SET_FILES_SOURCE](state, value) {
    state.files_source = value;
  },
  [types.SET_FILES](state, value) {
    state.files = value;
  },
  [types.SET_FILES_SHEETS](state, { fileId, sheets }) {
    Vue.set(state.files_sheets, fileId, sheets);
  },
  [types.SET_WARNING](state, value) {
    state.warning = value;
  },
  [types.SET_BASIC_SETTINGS](state, value) {
    Object.assign(state[tabKeys.GENERAL], value);
  },
  [types.SET_TAB_SETTINGS](state, { tab, value }) {
    Object.assign(state[tab], value);
  },
  [types.UPDATE_TAB_SETTINGS](state, { tab, key, value }) {
    Vue.set(state[tab], key, value);
  },
  [types.SET_MATCHED_SHEETS](state, value) {
    Object.assign(state[tabKeys.MATCH_SHEETS], value);
  },
  [types.UPDATE_MATCHED_SHEETS](state, { key, field, value }) {
    Vue.set(state[tabKeys.MATCH_SHEETS][key], field, value);
  },
  [types.SET_MATCHED_SLOT](state, { tab, index, value }) {
    Vue.set(state[tab].matchedSlots, index, value);
  },
  [types.SET_SLOTS_INDEXES](state, { tab, slotKey, indexesList }) {
    Vue.set(state[tab].slotsIndexes, slotKey, indexesList);
  },
  [types.RESET_IS_TABS_LOADED](state) {
    importTabKeys.forEach(tab => {
      Vue.set(state[tab], 'isLoaded', false);
    });
  },
  [types.SET_CONNECTION_SETTINGS](state, value) {
    Object.keys(value).forEach(key => {
      if (state[key]) {
        if (Array.isArray(value[key])) {
          return state[key] = value[key];
        }

        Object.assign(state[key], value[key]);
      }
    });
  },
  [types.RESET_MATCHED_SHEETS](state, value) {
    state[tabKeys.MATCH_SHEETS] = value;
  },
  [types.RESET_IMPORT_TAB](state, tab) {
    // ignore loaded slots
    const availableSlots = cloneObject(state[tab].availableSlots);

    state[tab] = {
      ...singleImportTabInitialState(),
      availableSlots
    };
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  resetIsTabsLoaded({ commit }) {
    commit(types.RESET_IS_TABS_LOADED);
  },
  setFilesSource({ commit }, value) {
    commit(types.SET_FILES_SOURCE, value);
  },
  setFiles({ commit }, value) {
    commit(types.SET_FILES, value);
  },
  removeFile({ state, commit, dispatch }, fileId) {
    dispatch('resetMatchedSheets', fileId);

    const filteredFiles = state.files.filter(file => fileId !== file.fileId);

    commit(types.SET_FILES, filteredFiles);
  },
  updateTabSettings({ commit }, payload) {
    commit(types.UPDATE_TAB_SETTINGS, payload);
  },
  updateMatchedSheet({ state, getters, commit, dispatch }, payload) {
    const { key, field, value } = payload;

    if (field === matchKeys.FILE) {
      commit(types.UPDATE_MATCHED_SHEETS, {
        key,
        field: matchKeys.SHEET,
        value: null
      });
    }

    if (!getters.isMultipleFiles) {
      commit(types.UPDATE_MATCHED_SHEETS, {
        key,
        field: matchKeys.FILE,
        value: state.files[0]?.fileId
      });

      dispatch('resetSelectedField', { field, value });
    }

    const file = getters.fileByFileId(value);
    const isCsv = file && file.type === fileFormats.csv;

    if (isCsv) {
      dispatch('resetMatchedSheets', file.fileId);

      commit(types.UPDATE_MATCHED_SHEETS, {
        key,
        field: matchKeys.SHEET,
        value: '0'
      });
    }

    commit(types.UPDATE_MATCHED_SHEETS, payload);
    commit(types.RESET_IMPORT_TAB, key);
  },
  matchDefaultSheet({ state, getters, commit, dispatch }) {
    if (!getters.isMultipleFiles && getters.isCsv) {
      dispatch('fetchSheetList', state.files[0]?.fileId);

      commit(types.UPDATE_MATCHED_SHEETS, {
        key: getters.mainKey,
        field: matchKeys.FILE,
        value: state.files[0]?.fileId
      });

      commit(types.UPDATE_MATCHED_SHEETS, {
        key: getters.mainKey,
        field: matchKeys.SHEET,
        value: '0'
      });
    }
  },
  resetSelectedField({ state, commit }, { field, value }) {
    importTabKeys.forEach(tabKey => {
      if (state[tabKeys.MATCH_SHEETS][tabKey][field] === value) {
        commit(types.UPDATE_MATCHED_SHEETS, {
          key: tabKey,
          field,
          value: null
        });
        commit(types.RESET_IMPORT_TAB, tabKey);
      }
    });
  },
  resetMatchedSheets({ state, commit }, fileId) {
    const file = state.files.find(file => fileId === file.fileId);

    if (file) {
      const newMatched = importTabKeys.reduce((acc, key) => {
        const tab = state[tabKeys.MATCH_SHEETS][key];
        const isFileMatched = tab[matchKeys.FILE] === file.fileId;

        if (isFileMatched) {
          commit(types.RESET_IMPORT_TAB, key);
        }

        acc[key] = {
          [matchKeys.FILE]: isFileMatched ? null : tab[matchKeys.FILE],
          [matchKeys.SHEET]: isFileMatched ? null : tab[matchKeys.SHEET]
        };

        return acc;
      }, {});

      commit(types.RESET_MATCHED_SHEETS, newMatched);
    }
  },
  setColSlot(context, payload) {
    return setColSlot(types, context, payload);
  },
  async uploadFile(_, { value, reqConfig }) {
    return spreadsheetApi.uploadFile(
      fileToFormData(await gzipFile(value), 'spreadsheet'),
      reqConfig
    );
  },
  uploadFileWithURL(_, { value, reqConfig }) {
    return spreadsheetApi.uploadFileWithURL(
      { fileUrl: value },
      reqConfig
    );
  },
  downloadFile() {
    return spreadsheetApi.downloadFile();
  },
  setMatchedSheets({ state, commit, dispatch }, sheets) {
    if (!sheets?.length || !state.files.length) {
      return;
    }

    const matchedSheets = sheets.reduce((acc, { tab, index }) => {
      if (tab) {
        acc[tab] = {
          [matchKeys.FILE]: state.files[0]?.fileId,
          [matchKeys.SHEET]: index
        };

        dispatch('fetchSlots', tab);
      }

      return acc;
    }, {});

    commit(types.SET_MATCHED_SHEETS, matchedSheets);
  },
  async fetchSheetList({ state, commit, getters, dispatch }, fileId) {
    try {
      if (!fileId || state.files_sheets[fileId]?.length) {
        return;
      }

      const response = await spreadsheetApi.getSheetList({ fileId });
      const sheets = toArray(response?.data?.sheetList?.sheet);

      if (!sheets?.length) {
        return;
      }

      commit(types.SET_FILES_SHEETS, {
        fileId,
        sheets
      });

      if (state.commonData.type === connectionTypes.SPREADSHEET_AGGREGATED) {
        return;
      }

      if (!getters.isMultipleFiles && !getters.hasMatchedSheets) {
        await dispatch('setMatchedSheets', sheets);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchSheetList' });
    }
  },
  async fetchBasicSettings({ state, getters, commit }, isUpdate) {
    try {
      if (!state.files.length) {
        return;
      }

      const response = await spreadsheetApi.getBasicSettings({
        fileIds: getters.fileIds,
        type: getters.requestType
      });
      const data = response?.data?.settings?.options;

      if (!data) {
        return;
      }

      const settings = BasicSettingsAdapter(data, isUpdate);

      commit(types.SET_BASIC_SETTINGS, settings);
    } catch (e) {
      const message = e?.message;

      if (message) {
        Vue.notify({
          type: 'error',
          text: message,
          duration: -1
        });
      }

      this.dispatch('user/logout', { e, from: 'fetchBasicSettings' });
    }
  },
  async fetchSlots({ state, commit, getters }, tab) {
    try {
      const availableSlots = state[tab].availableSlots;

      if (availableSlots.length) {
        return;
      }

      const response = await spreadsheetApi.getSlots({
        type: getters.requestType,
        tab
      });
      const data = response?.data?.slots?.slot;

      if (!data) {
        return;
      }

      commit(types.UPDATE_TAB_SETTINGS, {
        tab,
        key: 'availableSlots',
        value: toArray(data)
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchSlots' });
    }
  },
  async fetchTablePreview({ state, getters }, tab) {
    try {
      const match = state[tabKeys.MATCH_SHEETS][tab];

      if (match[matchKeys.SHEET] === null) {
        return;
      }

      return spreadsheetApi.getSheetPreview({
        sheetIndex: match[matchKeys.SHEET],
        fileId: match[matchKeys.FILE],
        tab,
        autodetection: !getters.isTabHasSlots(tab),
        ...getters.requestParams(match[matchKeys.FILE])
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTablePreview' });
    }
  },
  setTablePreviewResult({ commit, getters }, { data, tab }) {
    const { matchedSlots, slotsIndexes, ...table } = TablePreviewAdapter(data);

    commit(types.SET_TAB_SETTINGS, {
      tab,
      value: {
        importPreview: table,
        ...(!getters.isTabHasSlots(tab) && { matchedSlots, slotsIndexes })
      }
    });
  },
  async fetchWarnings({ state, commit, getters }) {
    try {
      const tab = mainTabs.find(tab => state[tabKeys.MATCH_SHEETS][tab][matchKeys.FILE]);
      const match = state[tabKeys.MATCH_SHEETS][tab];

      if (!tab) {
        return;
      }

      const response = await spreadsheetApi.getWarnings({
        ...getters.requestParams(match[matchKeys.FILE]),
        fileId: match[matchKeys.FILE],
        sheetIndex: match[matchKeys.SHEET]
      });
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(types.SET_WARNING, data.warning ?? '');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchWarnings' });
    }
  },
  resetWarnings({ commit }) {
    commit(types.SET_WARNING, '');
  },
  setConnectionSettings({ commit }, payload) {
    commit(types.SET_CONNECTION_SETTINGS, payload);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
