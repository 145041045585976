export default function(data) {
  const {
    _user: user,
    _password: password,
    _host: host,
    _webResource: webResource,
    _category: category,
    _classAsTopCategory: classAsTopCategory,
    _combineLocs: combineLocs
  } = data.settings.info;

  return {
    required: {
      user,
      password,
      host,
      webResource
    },
    optional: {
      category,
      classAsTopCategory,
      combineLocs
    }
  };
}
