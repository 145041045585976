import Vue from 'vue';
import projectApi from '@/api/project';
import { i18n } from '@/plugins/vue-i18n.plugin';
import statusCodes from '@/config/utils/statusCodes.config';
import { releasedConnectors, spreadsheetTypes } from '@/config/connection';
import { periodTypes } from '@/config/project';
import { fileSourceTypes } from '@/config/connection/spreadsheet.config';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';
import { getDifferenceInDays } from '@/helpers/date/getDifferenceDays';
import { MIN_IN_HOUR, MIN_IN_DAY, timezoneOffsetMinutes, convertHourToReadableFormat } from '@/helpers/date/date';
import { toArray } from '@/helpers/utils/toArray';

const SERVER_UPDATE_MINUTES = 240; // 4:00 AM

const types = {
  SET_PROJECT: 'SET_PROJECT',
  SET_PROJECT_STATS: 'SET_PROJECT_STATS',
  SET_PROJECT_TUTORIALS: 'SET_PROJECT_TUTORIALS',
  SET_IS_PROJECT_UPDATING: 'SET_IS_PROJECT_UPDATING',
  SET_IS_PROJECT_SAVING: 'SET_IS_PROJECT_SAVING',
  RESET_STATE: 'RESET_STATE',
  SET_RAM_STATS: 'SET_RAM_STATS',
  SET_FREEZE_SETTINGS: 'SET_FREEZE_SETTINGS',
  SET_SPREADSHEET_PROJECT_INFO: 'SET_SPREADSHEET_PROJECT_INFO'
};

const initialState = () => ({
  project: null,
  tutorials: null,
  item_codes: [],
  locations: [],
  channels: [],
  isUpdating: false,
  is_saving: false,
  ram_stats: {},
  freeze_settings: {},
  spreadsheet_project_info: null
});

const state = initialState();

const getters = {
  connectorType: (state, getters) => {
    if (getters.isMultipleConnections) {
      return state.project?.connectorTypes;
    }

    return state.project?.connectorTypes?.[0];
  },
  actualDates: (state, getters, _, rootGetters) => {
    const realPoints = toArray(state.project?.dates?.points).slice(0, getters.nRealPoints);

    return realPoints.map(point => dateByLocaleKey(point, rootGetters['project/projectDateLocale'])) || [];
  },
  nRealPoints: (state) => state.project?.dates?.nRealPoints,
  hasLocation: (state) => state.project?.tree?.hasLocation,
  hasChannel: (state) => state.project?.tree?.hasChannel,
  hasAbc: (state) => state.project?.tree?.hasAbc,
  hasBom: (state) => state.project?.inventory?.hasBom,
  hasBatchesOnHand: (state) => state.project?.inventory?.hasBatchesOnHand,
  baseCurrency: (state) => state.project?.currency,
  wantInvModel: state => state.project?.inventory?.wantInvModel,
  pKnown: state => state.project?.pKnown?.tInventory + Math.floor(state.project?.pKnown?.part),
  canExport: state => state.project?.canExport,
  canAutoExport: state => state.project?.canAutoExport,
  canAutoUpdate: state => state.project?.canAutoUpdate,
  wasOCImported: state => state?.project?.inventory?.wasOCImported,
  hasInv: state => state?.project?.inventory?.hasInv,
  hasDistrib: state => state?.project?.inventory?.hasDistrib,
  hasCollections: state => state?.project?.inventory?.hasCollections,
  period: state => {
    const predictor = state.project?.predictor;

    if (!predictor) {
      return null;
    }

    const { dateCycle, delta, cycleSize } = predictor;

    if (dateCycle === 'month_year' && delta === 1) {
      return periodTypes.MONTH;
    }

    if ((dateCycle === 'day_week' && cycleSize === 1)
      || (dateCycle === 'uniform' && delta === 7)) {
      return periodTypes.WEEK;
    }

    return periodTypes.UNSUPPORTED;
  },
  isSupportedPeriod: (_, getters) => {
    return getters.period && getters.period !== periodTypes.UNSUPPORTED;
  },
  lastUpdate: (state) => {
    if (!state?.project) {
      return '';
    }

    const { updateFailed, lastUpdate = '' } = state.project;

    if (!lastUpdate) {
      return '';
    }

    const [date = '', time = ''] = lastUpdate.split(' ');
    const delta = getDifferenceInDays(date);
    const localeKey = updateFailed ? 'LastUpdateFailed' : 'LastUpdateDate';
    let localeMessage = '';

    if (delta <= 0) {
      localeMessage = i18n.tc(`Main.${localeKey}.Today`);
    } else if (delta === 1) {
      localeMessage = i18n.tc(`Main.${localeKey}.Yesterday`);
    } else if (delta <= 30) {
      localeMessage = i18n.t(`Main.${localeKey}.NDaysAgo.val`, {
        p1: i18n.tc(`Main.${localeKey}.NDaysAgo.p1`, delta, { n: delta })
      });
    } else {
      const localeDate = dateByLocaleKey(lastUpdate, localeDateKeys.YMD);

      localeMessage = i18n.tc(`Main.${localeKey}.ProperDate`) + ' ' + localeDate;
    }

    if (time) {
      return localeMessage + i18n.t(`Main.${localeKey}.ProperTime`, { 1: time});
    }

    return localeMessage;
  },
  localUpdateTime: (state) => {
    if (!state.project) {
      return '';
    }

    const serverUpdateTime = state.project.recalcTimeUtc;
    const serverTimezoneOffsetMinutes = serverUpdateTime - SERVER_UPDATE_MINUTES;

    const userTimezoneOffsetMinutes = timezoneOffsetMinutes();
    const userUpdateTimeMinutes = (SERVER_UPDATE_MINUTES + (userTimezoneOffsetMinutes - serverTimezoneOffsetMinutes)) % MIN_IN_DAY;
    const userUpdateTimeHours = userUpdateTimeMinutes / MIN_IN_HOUR;

    return convertHourToReadableFormat(userUpdateTimeHours);
  },
  dataAsOf: (state) => {
    return state.project ? dateByLocaleKey(state.project?.actualDate, localeDateKeys.YMD) : '';
  },
  calculationDate: (state) => {
    return state.project ? dateByLocaleKey(state.project?.calculationDate, localeDateKeys.YMD) : '';
  },
  canEditConnection: (state, getters) => {
    return releasedConnectors.includes(getters.connectorType) || state.project?.isConnector2_0;
  },
  isMultipleConnections: (state) => state.project?.isConnector2_0,
  projectLabel: (state) => {
    const projectName = state.project?.fileName ?? '';

    return projectName.replace('.gsl', '');
  },
  projectDateLocale: (state) => state.project?.predictor?.dateProgression?.unit === 'month'
    ? localeDateKeys.YM
    : localeDateKeys.YMD,
  isSpreadsheetConnection: (_, getters) => spreadsheetTypes.some(type => getters.connectorType.includes(type)),
  isSourceFile: (state) => state.spreadsheet_project_info?.type === fileSourceTypes.FILE
};

const mutations = {
  [types.SET_PROJECT](state, value) {
    state.project = value;
  },
  [types.SET_PROJECT_STATS](state, value) {
    Vue.set(state.project, 'stats', value);
  },
  [types.SET_PROJECT_TUTORIALS](state, value) {
    state.tutorials = value;
  },
  [types.SET_IS_PROJECT_UPDATING](state, value) {
    state.isUpdating = value;
  },
  [types.SET_IS_PROJECT_SAVING](state, value) {
    state.is_saving = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  },
  [types.SET_RAM_STATS](state, value) {
    state.ram_stats = value;
  },
  [types.SET_FREEZE_SETTINGS](state, value) {
    state.freeze_settings = value;
  },
  [types.SET_SPREADSHEET_PROJECT_INFO](state, value) {
    state.spreadsheet_project_info = value;
  }
};

const actions = {
  setIsProjectUpdating({ commit }, value) {
    commit(types.SET_IS_PROJECT_UPDATING, value);
  },
  async fetchProject({ commit }) {
    try {
      const response = await projectApi.getProjectData();

      if (!response?.data) {
        return;
      }

      commit(types.SET_PROJECT, response.data);

      await this.dispatch('demand/tree/setTreeOrders');

      const stats = await projectApi.getProjectStatistics();

      if (stats?.data) {
        commit(types.SET_PROJECT_STATS, stats.data);
      }

      return response.data;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchProject' });
    }
  },
  async fetchProjectTabs() {
    try {
      const response = await projectApi.getProjectTabs();

      return response?.data?.tabs;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchProjectTabs' });
      throw e;
    }
  },
  reImport(_, payload) {
    return projectApi.postReimport(payload);
  },
  async fetchMetaInfo(_, id) {
    try {
      const response = await projectApi.getReimportMetaInfo(id);

      return response.data?.importIssues;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchMetaInfo' });
    }
  },
  async saveProject({ commit }) {
    try {
      commit(types.SET_IS_PROJECT_SAVING, true);

      const response = await projectApi.saveProject();

      if (!response) {
        return;
      }

      Vue.notify({
        type: 'success',
        title: i18n.tc('Common.Save'),
        text: i18n.tc('Web.SaveProject.Success')
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'saveProject' });
    } finally {
      commit(types.SET_IS_PROJECT_SAVING, false);
    }
  },
  async getSystemVersion() {
    try {
      const response = await projectApi.getSystemVersion();

      const serverVersion = response?.data?.serverVersion;

      if (process.env.VUE_APP_MODE === 'production' && window.location.hostname !== 'localhost') {
        projectApi.postServerVersion({
          version: serverVersion,
          domain: window.location.origin
        });
      }

      return response;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getSystemVersion' });

      throw e;
    }
  },
  async fetchTutorials({ commit }) {
    try {
      const response = await projectApi.getTutorials();

      const metadata = response?.data?.Metadata;

      if (!metadata) {
        return;
      }

      const tutorials = toArray(metadata.Tutorials.Tutorial).map((tutorial) => ({
        ...tutorial,
        _tags: tutorial._tags?.split(',') || []
      }));

      commit(types.SET_PROJECT_TUTORIALS, tutorials);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTutorials' });
    }
  },
  async fetchItemCodes({ rootGetters }, { query } = {}) {
    try {
      if (!query) {
        return;
      }

      const response = await projectApi.getItemCodes({
        id: rootGetters['filters/activeFilterId'],
        query,
        top: 20,
        skip: 0
      });

      return response.data?.items || [];
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchItemCodes' });
    }
  },
  async fetchLocations({ rootGetters }, { query = '', item } = {}) {
    try {
      const response = await projectApi.getLocations({
        id: rootGetters['filters/activeFilterId'],
        query,
        item,
        top: 20,
        skip: 0
      });

      return response.data?.locations || [];
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchLocations' });
    }
  },
  async fetchChannels({ rootGetters }, { query = '', item, location } = {}) {
    try {
      const response = await projectApi.getChannels({
        id: rootGetters['filters/activeFilterId'],
        query,
        item,
        location,
        top: 20,
        skip: 0
      });

      return response.data?.channels || [];
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchChannels' });
    }
  },
  downloadTruncatedProject(_, params) {
    return projectApi.downloadTruncatedProject(params);
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  async fetchRamStats({ commit, rootGetters }) {
    const isFreePlan = rootGetters['account/isFreePlan'];

    if (isFreePlan) {
      return;
    }

    try {
      const response = await projectApi.getRamStats();
      const stats = response?.data?.stats;

      if (!stats) {
        return;
      }

      commit(types.SET_RAM_STATS, {
        totalRAM: +stats._totalRAM,
        peakProcessRAM: +stats._peakProcessRAM,
        currentProcessRAM: +stats._currentProcessRAM
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchRamStats' });
    }
  },
  async fetchFreezeSettings({ commit }) {
    try {
      const response = await projectApi.getFreezeSettings();
      const freezeSettings = response?.data;

      if (!freezeSettings) {
        return;
      }

      commit(types.SET_FREEZE_SETTINGS, freezeSettings);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchFreezeSettings' });
    }
  },
  unfreezeProject({ dispatch }) {
    return dispatch('submitFreezeSettings', { frozen: false });
  },
  async submitFreezeSettings(_, payload) {
    try {
      await projectApi.putFreezeSettings(payload);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'submitFreezeSettings' });
    }
  },
  exportBomIssues(_, id) {
    return projectApi.exportBomIssues({ id });
  },
  async updateSlVersion(_, version) {
    try {
      const response = await projectApi.putUpdateSlVersion({
        type: version
      });

      if (response?.status === statusCodes.OK) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.UpdateSl.SuccessfulSlUpdate')
        });
      }
    } catch (e) {
      Vue.notify({
        type: 'error',
        text: e.message
      });

      this.dispatch('user/logout', { e, from: 'updateSlVersion' });
    }
  },
  async fetchSpreadsheetProjectInfo({ commit }) {
    try {
      const response = await projectApi.getSpreadsheetProjectInfo();
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(types.SET_SPREADSHEET_PROJECT_INFO, data);

      return this.dispatch('spreadsheet/setFilesSource', data.type);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchSpreadsheetProjectInfo' });
    }
  },
  async fetchOpenProjectMetaInfo(_, params) {
    try {
      const response = await projectApi.getOpenProjectMetaInfo(params);

      response?.data?.issues.forEach(message => {
        Vue.notify({
          type: 'warn',
          text: message,
          duration: -1
        });
      });

    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchOpenProjectMetaInfo' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
