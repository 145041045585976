import { toArray } from '@/helpers/utils/toArray';
import { routeByTab } from '@/config/app.config';
import { accessStateKeys } from '@/config/shared/access.config';
import { routeNames, usersManagementRouteNames } from '@/config/router/router.config';

const getMainRoutes = (tabs, tabAccess) => {
  const mainTabs = toArray(tabs.value?.main);

  return Object.entries(routeByTab).reduce((acc, [tab, route]) => {
    acc[route] = mainTabs.includes(tab) ? tabAccess[tab] : accessStateKeys.NONE;

    return acc;
  }, {});
};

const getSubRouteAccess = (condition) => {
  return condition ? accessStateKeys.EDITABLE : accessStateKeys.NONE;
};

const getUsersManagementRoutes = (features) => {
  return Object.values(usersManagementRouteNames).reduce((acc, route) => {
    acc[route] = getSubRouteAccess(features.manageUsers);

    return acc;
  }, {});
};

export const aggregateRouteAccess = (responses) => {
  const [tabs, user, project] = responses;
  const { tabAccess, features } = user.value.role;
  const { hasBom, hasCollections, hasBatchesOnHand } = project.value?.inventory ?? {};

  const mainRoutes = getMainRoutes(tabs, tabAccess);
  const usersManagementRoutes = getUsersManagementRoutes(features);

  const hasRouteAccess = (tab) => mainRoutes[tab] !== accessStateKeys.NONE;

  const subRoutes = {
    // users management related
    ...usersManagementRoutes,

    // project related
    [routeNames.SETTINGS]: getSubRouteAccess(features.configureProject),
    [routeNames.BILL_OF_MATERIALS]: getSubRouteAccess(features.bomAccess && hasBom),
    [routeNames.REMOVAL_SUBSTITUTION_RULES]: getSubRouteAccess(features.remSubsRules),
    [routeNames.BATCHES_ON_HAND]: getSubRouteAccess(hasBatchesOnHand),
    [routeNames.SUPPLIERS]: getSubRouteAccess(features.accessSuppliers),

    // demand related
    [routeNames.CUSTOM_ROWS]: getSubRouteAccess(features.manageForecastVersions && hasRouteAccess(routeNames.DEMAND)),
    [routeNames.PROMOTIONS]: getSubRouteAccess(hasRouteAccess(routeNames.DEMAND)),
    [routeNames.SEASONALITY_PATTERNS]: getSubRouteAccess(hasRouteAccess(routeNames.DEMAND)),

    // reports related
    [routeNames.COLLECTIONS]: getSubRouteAccess(hasCollections && mainRoutes[routeNames.INVENTORY] === accessStateKeys.EDITABLE),
    [routeNames.CONSTRAINED_PLAN]: getSubRouteAccess(hasRouteAccess(routeNames.INVENTORY)),
    // planned orders & in transition can be read only
    [routeNames.INVENTORY_PLANNED_ORDERS]: mainRoutes[routeNames.INVENTORY] !== accessStateKeys.READ_ONLY
      ? getSubRouteAccess(hasRouteAccess(routeNames.INVENTORY))
      : accessStateKeys.READ_ONLY,
    [routeNames.INTERSTORE_PLANNED_ORDERS]: mainRoutes[routeNames.INTERSTORE] !== accessStateKeys.READ_ONLY
      ? getSubRouteAccess(hasRouteAccess(routeNames.INTERSTORE))
      : accessStateKeys.READ_ONLY,
    [routeNames.INVENTORY_IN_TRANSITION]: mainRoutes[routeNames.INVENTORY] !== accessStateKeys.READ_ONLY
      ? getSubRouteAccess(hasRouteAccess(routeNames.INVENTORY))
      : accessStateKeys.READ_ONLY,
    [routeNames.INTERSTORE_IN_TRANSITION]: mainRoutes[routeNames.INTERSTORE] !== accessStateKeys.READ_ONLY
      ? getSubRouteAccess(hasRouteAccess(routeNames.INTERSTORE))
      : accessStateKeys.READ_ONLY,
    [routeNames.TRANSFER_REGIONS]: mainRoutes[routeNames.INTERSTORE] !== accessStateKeys.READ_ONLY
      ? getSubRouteAccess(hasRouteAccess(routeNames.INTERSTORE))
      : accessStateKeys.READ_ONLY,

    // connections related
    [routeNames.EDIT_CONNECTION]: getSubRouteAccess(features.editConnection),
    [routeNames.EDIT_CONNECTION_CONNECTOR]: getSubRouteAccess(features.editConnection)
  };

  return {
    ...mainRoutes,
    ...subRoutes
  };
};

export const aggregateFeatureAccess = (responses) => {
  // eslint-disable-next-line no-unused-vars
  const [_, user, __] = responses;
  const { tabAccess, features, dataRestrictions } = user.value.role;

  return {
    ...features,
    // invert restrictions
    purchasingData: !dataRestrictions.purchasingData,
    salesData: !dataRestrictions.salesData,
    // route as feature
    intersiteOptimization: tabAccess[routeNames.INTERSTORE] !== accessStateKeys.NONE

  };
};