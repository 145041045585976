<template>
  <SlButton
    size="2xs"
    variant="tertiary"
    color="grey"
    class="sl-button__info"
    icon
    v-bind="$attrs"
    @click.prevent
  >
    <icon
      data="@icons/info.svg"
      class="fill-off size-16"
    />
  </SlButton>
</template>

<script>
import SlButton from '@/components/UIKit/SlButton';

export default {
  name: 'SlInfoButton',
  components: {
    SlButton
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/utils";

.sl-button__info {
  background: transparent;
  border-radius: $border-radius-50 !important;

  color: $grey-50;

  &:hover, &:focus {
    color: $grey-70;
  }

  &:active {
    color: $grey-80;
  }
}
</style>