import tree from './tree';
import table from './table';
import chart from './chart';
import seasonPattern from './submodules/seasonPattern';
import promotion from './submodules/promotion';
import settings from './settings';
import forecastVersions from './submodules/forecastVersions';
import nodeApi from '@/api/demand/node';
import { ampli } from '@/plugins/product/ampli';
import { exportTypes } from '@/config/report/inventoryReport';

const types = {
  SET_IS_NODE_UPDATING: 'SET_IS_NODE_UPDATING'
};

const state = () => ({
  is_node_updating: false
});

const mutations = {
  [types.SET_IS_NODE_UPDATING](state, value) {
    state.is_node_updating = value;
  }
};

const actions = {
  setIsNodeUpdating({ commit }, value) {
    commit(types.SET_IS_NODE_UPDATING, value);
  },
  async setDemandActiveFilterId({ dispatch }, { id }) {
    await this.dispatch('filters/setActiveFilterId', id);
    await this.dispatch('demand/tree/setOpenNodes');
    await this.dispatch('demand/tree/fetchTree', { resetTree: true });

    dispatch('fetchDemandNode');
  },
  async fetchDemandNode() {
    try {
      await Promise.allSettled([
        this.dispatch('demand/table/fetchNodeTable'),
        this.dispatch('demand/settings/fetchForecastSettings'),
        this.dispatch('demand/chart/fetchChart')
      ]);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchDemandNode' });
    }
  },
  exportNodeToXlsx({ rootGetters }, visitLeafs) {
    ampli.demandNodeTableExported({
      exportFormat: exportTypes.XLSX,
      exportedNodesAmount: visitLeafs ? 'subtree' : 'singleNode'
    });

    return nodeApi.exportNodeToXlsx({
      nodeId: rootGetters['demand/tree/activeNodeId'],
      filterId: rootGetters['filters/activeFilterId'],
      visitLeafs
    });
  },
  async clearNodeOverrides({ commit, rootGetters }, nodeId) {
    try {
      commit(types.SET_IS_NODE_UPDATING, true);

      await nodeApi.clearNodeOverrides({
        nodeId,
        filterId: rootGetters['filters/activeFilterId']
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'clearNodeOverrides' });

      commit(types.SET_IS_NODE_UPDATING, false);
    }
  },
  async clearNodeSettings({ commit, rootGetters }, payload) {
    try {
      commit(types.SET_IS_NODE_UPDATING, true);

      await nodeApi.clearNodeSettings({
        filterId: rootGetters['filters/activeFilterId'],
        ...payload
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'clearNodeSettings' });

      commit(types.SET_IS_NODE_UPDATING, false);
    }
  }
};

export default {
  namespaced: true,
  modules: {
    tree,
    table,
    chart,
    seasonPattern,
    promotion,
    settings,
    forecastVersions
  },
  state,
  actions,
  mutations
};
