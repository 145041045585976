import axios from '@/plugins/axios';

export default {
  getSheetList(params) {
    return axios.get('/integrations/spreadsheet/sheetlist', {
      params
    });
  },
  getSheetPreview(params) {
    return axios.get('/integrations/spreadsheet/preview', {
      params
    });
  },
  getWarnings(params) {
    return axios.get('/integrations/spreadsheet/warning', {
      params
    });
  },
  getBasicSettings(params) {
    return axios.get('/integrations/spreadsheet/basic-settings', {
      params
    });
  },
  cacheMicrosoftDrivesFiles(params) {
    return axios.post('/integrations/spreadsheet/microsoft-drives/cache', null, {
      params
    });
  },
  searchMicrosoftDrivesFiles(params) {
    return axios.get('/integrations/spreadsheet/microsoft-drives/search', {
      params
    });
  },
  uploadMicrosoftDrivesFile(params) {
    return axios.post('/integrations/spreadsheet/microsoft-drives/upload', null, {
      params
    });
  },
  uploadSpreadsheetFile(body, config = {}) {
    return axios.post('/integrations/spreadsheet/file/upload', body, {
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  downloadSpreadsheetFile(params) {
    return axios.get('/integrations/spreadsheet/file/download', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  }
};
