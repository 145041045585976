import Vue from 'vue';
import { i18n } from '@/plugins/vue-i18n.plugin';
import backupsApi from '@/api/manageProjects/backups';
import router from '@/router';
import { backupTypes } from '@/config/project/backups.config';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { projectTypes } from '@/config/project';

const types = {
  SET_TABLE: 'SET_TABLE',
  RESET_STATE: 'RESET_STATE'
};

const initialState = () => ({
  [backupTypes.DAILY]: null,
  [backupTypes.MONTHLY]: null,
  [backupTypes.EVENTS]: null
});

const state = initialState();

const getters = {
  getParams: () => (backupId) => ({
    id: router.currentRoute.params.originId,
    backupId
  })
};

const mutations = {
  [types.SET_TABLE](state, { type, value }) {
    state[type] = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  async fetchBackups({ commit }, type = backupTypes.DAILY) {
    try {
      const response = await backupsApi.getBackups({
        type,
        id: router.currentRoute.params.originId
      });
      const data = response?.data;

      if (!data) {
        return;
      }

      commit(types.SET_TABLE, {
        type,
        value: GenericTableAdapter(data)
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchBackups' });
    }
  },
  async runAsTemp({ getters }, { id }) {
    try {
      this.dispatch('initialization/setInitializing', true);

      const response = await backupsApi.previewBackup(
        getters.getParams(id)
      );
      const data = response?.data;

      if (!data) {
        return this.dispatch('initialization/setInitializing', false);
      }

      await this.dispatch('operations/subscribe', {
        subscriber: () => Promise.resolve({
          data: {
            ...data.response,
            data: {
              pid: data.projectId
            }
          }
        }),
        finished: () => this.dispatch('manageProjects/getProjectList')
      });

      this.dispatch('manageProjects/openProject', {
        pid: data.projectId,
        type: projectTypes.BACKUP
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'runAsTemp' });
      this.dispatch('initialization/setInitializing', false);
    }
  },
  async restoreBackup({ getters }, { id, Date }) {
    try {
      this.dispatch('initialization/setInitializing', true);

      const response = await backupsApi.restoreBackup(
        getters.getParams(id)
      );
      const data = response?.data;

      if (!data) {
        return this.dispatch('initialization/setInitializing', false);
      }

      await this.dispatch('operations/subscribe', {
        subscriber: () => Promise.resolve({
          data: {
            ...data.response,
            data: {
              pid: data.projectId
            }
          }
        }),
        finished: () => this.dispatch('manageProjects/getProjectList')
      });

      await this.dispatch('manageProjects/openProject', {
        pid: data.projectId,
        type: projectTypes.BACKUP
      });

      Vue.notify({
        type: 'success',
        title: i18n.t('Web.Backups.RestoreSuccess', { 1: Date.val })
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'restoreBackup' });
      this.dispatch('initialization/setInitializing', false);
    }
  },
  downloadBackup({ getters }, { id }) {
    return backupsApi.downloadBackup(
      getters.getParams(id)
    );
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
