import axios from '@/plugins/axios';

export default {
  createFilterCache(params) {
    return axios.post('filters/filter', null, { params });
  },
  changeFilterCache(params, body) {
    return axios.patch('filters/filter', body, { params });
  },
  saveFilterCache(params) {
    return axios.put('filters/filter', null, { params });
  },
  discardFilterCache(params) {
    return axios.delete('filters/filter', { params });
  },
  getFilter(params) {
    return axios.get('filters/filter', { params });
  },
  getFilterableColumns(params) {
    return axios.get('filters/filter/columns', { params });
  },
  getFilterableColumnValues(params) {
    return axios.get('filters/filter/column/values', { params });
  },
  createFilterRule(params) {
    return axios.post('filters/filter/rule', null, { params });
  },
  duplicateFilterRule(params) {
    return axios.post('filters/filter/rule/duplicate', null, { params });
  },
  ungroupFilterRule(params) {
    return axios.post('filters/filter/rule/ungroup', null, { params });
  },
  changeFilterRule(params, body) {
    return axios.patch('filters/filter/rule', body, { params });
  },
  deleteFilterRule(params) {
    return axios.delete('filters/filter/rule', { params });
  },
  createFilterGroup(params) {
    return axios.post('filters/filter/group', null, { params });
  },
  changeFilterGroup(params, body) {
    return axios.patch('filters/filter/group', body, { params });
  },
  duplicateFilterGroup(params) {
    return axios.post('filters/filter/group/duplicate', null, { params });
  },
  ungroupFilterGroup(params) {
    return axios.post('filters/filter/group/ungroup', null, { params });
  },
  deleteFilterGroup(params) {
    return axios.delete('filters/filter/group', { params });
  }
};
