import Vue from 'vue';
import filter from './filter';
import filtersApi from '@/api/filters';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { filterPageTypes } from '@/config/filters';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { getTableConfigParams, getTableConfigStructure } from '@/helpers/shared/tableConfig';

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_FILTER_FIELD_BY_TYPE: 'SET_FILTER_FIELD_BY_TYPE',
  SET_FILTER_TYPE: 'SET_FILTER_TYPE',
  SET_FILTER_TABS: 'SET_FILTER_TABS',
  SET_AVAILABLE_FILTERS: 'SET_AVAILABLE_FILTERS',
  SET_ACTIVE_FILTER_ID: 'SET_ACTIVE_FILTER_ID',
  RESET_STATE: 'RESET_STATE'
};

const getPageFiltersStructure = () => {
  return Object.values(filterPageTypes).reduce((acc, type) => {
    acc[type] = {
      available_filters: [],
      filter_tabs: [],
      active_filter_id: DEFAULT_FILTER_ID
    };

    return acc;
  }, {});
};

const initialState = () => ({
  table: null,
  tableConfig: getTableConfigStructure(),
  filter_type: null,
  ...getPageFiltersStructure()
});

const state = initialState();

const getters = {
  filterTabs: state => state[state.filter_type].filter_tabs,
  activeFilterId: state => state[state.filter_type]?.active_filter_id,
  availableFilters: state => state[state.filter_type].available_filters,
  activeFilter: (state, getters) => state[state.filter_type].filter_tabs.find(({ id }) => id === getters.activeFilterId),
  getAvailableFilterById: (_, getters) => (id) => getters.availableFilters.find(filter => filter.id === id)
};

const mutations = {
  [types.SET_TABLE](state, value) {
    state.table = value;
  },
  [types.SET_TABLE_CONFIG](state, payload) {
    state.tableConfig = payload;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state.tableConfig, key, value);
  },
  [types.SET_AVAILABLE_FILTERS](state, value) {
    state[state.filter_type].available_filters = value;
  },
  [types.SET_FILTER_FIELD_BY_TYPE](state, { type, field, value }) {
    Vue.set(state[type], field, value);
  },
  [types.SET_FILTER_TYPE](state, value) {
    state.filter_type = value;
  },
  [types.SET_FILTER_TABS](state, value) {
    state[state.filter_type].filter_tabs = value;
  },
  [types.SET_ACTIVE_FILTER_ID](state, value) {
    state[state.filter_type].active_filter_id = value;
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      if (initial[key]) {
        state[key] = initial[key];
      }
    });
  }
};

const actions = {
  async fetchFiltersTable({ state, commit }) {
    try {
      const response = await filtersApi.getFilters(getTableConfigParams(state.tableConfig));

      if (!response?.data) {
        return;
      }

      commit(types.SET_TABLE, GenericTableAdapter(response.data));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchFiltersTable' });
    }
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  async deleteFilter({ state, getters, dispatch }, filterId) {
    try {
      await filtersApi.removeFilter({
        type: state.filter_type,
        filterId
      });

      if (!state.filter_type) {
        Object.values(filterPageTypes).forEach(type => {
          if (state[type].active_filter_id === filterId) {
            dispatch('setFilterFieldByType', { type, field: 'active_filter_id', value: DEFAULT_FILTER_ID });
          }
        });

        return;
      }

      if (getters.activeFilterId === filterId) {
        dispatch('setActiveFilterId', DEFAULT_FILTER_ID);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'deleteFilter' });
    }
  },
  async duplicateFilter({ state, dispatch }, filterId) {
    try {
      const response = await filtersApi.duplicateFilter({
        type: state.filter_type,
        filterId
      });

      if (!state.filter_type) {
        return;
      }

      dispatch('setActiveFilterId', response?.data?.payload?.id || DEFAULT_FILTER_ID);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'duplicateFilter' });
    }
  },
  async fetchAvailableFilters({ state, commit }) {
    try {
      const response = await filtersApi.getAvailableFilters({
        type: state.filter_type
      });

      if (!response?.data) {
        return;
      }

      commit(types.SET_AVAILABLE_FILTERS, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchAvailableFilters' });
    }
  },
  async fetchFilterTabs({ state, dispatch }) {
    try {
      const response = await filtersApi.getFilters({
        type: state.filter_type
      });
      const tabs = response?.data?.tabs;

      if (!tabs) {
        return;
      }

      dispatch('setFilterTabs', tabs);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchFilterTabs' });
    }
  },
  async fetchFilters({ state, dispatch }) {
    try {
      if (!state.filter_type) {
        return dispatch('fetchFiltersTable');
      }

      if (state.filter_type !== filterPageTypes.ANALYTICS) {
        dispatch('fetchFilterTabs');
      }

      dispatch('fetchAvailableFilters');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchFilters' });
    }
  },
  setFilterType({ commit }, type) {
    commit(types.SET_FILTER_TYPE, type);
  },
  setFilterTabs({ commit }, tabs) {
    commit(types.SET_FILTER_TABS, tabs);
  },
  setActiveFilterId({ commit }, id) {
    commit(types.SET_ACTIVE_FILTER_ID, id);
  },
  setFilterFieldByType({ commit }, payload) {
    commit(types.SET_FILTER_FIELD_BY_TYPE, payload);
  },
  async updateFiltersOrder({ state, dispatch }, filters) {
    try {
      dispatch('setFilterTabs', filters);

      await filtersApi.postFiltersOrder(
        { type: state.filter_type },
        { order: filters.map(({ id }) => id) }
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateFiltersOrder' });
    }
  },
  async addFilterToPage({ state, dispatch }, filterId) {
    try {
      await filtersApi.applyFilterToPage({
        type: state.filter_type,
        filterId
      });

      dispatch('setActiveFilterId', filterId);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'addFilterToPage' });
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  modules: {
    filter
  },
  state,
  mutations,
  actions,
  getters
};
