import { saveAs } from 'file-saver';
import { toggleUserInteraction } from '@/helpers/shared/webAPI';
import { getFileNameFromResponse } from '@/helpers/utils/fileUpload';

export const fileSaver = {
  methods: {
    async saveFile(
      request,
      {
        errorTitle,
        errorText,
        notificationType = 'error'
      } = {}
    ) {
      try {
        toggleUserInteraction(true);

        const response = await request();

        const file = new File([response.data], getFileNameFromResponse(response));

        saveAs(file);
      } catch (error) {
        const message = error?.message;
        const description = error?.description;

        return this.$notify({
          type: notificationType,
          title: errorTitle || this.$t('Main.Ui.acChooseExportToXlsx'),
          text: errorText || message || description || this.$t('ExportIssues.Ui.:Caption'),
          duration: -1
        });
      } finally {
        toggleUserInteraction(false);
      }
    }
  }
};
