<template>
  <SlDropdown
    v-bind="$attrs"
    :overflow-padding="5"
    :placement="placement"
    :content-class="`sl-group-dropdown ${contentClass}`"
    class="sl-group-dropdown"
    v-on="$listeners"
  >
    <template #target>
      <SlButton
        v-if="!$scopedSlots.target"
        v-tooltip="getTooltip('More options')"
        variant="secondary"
        color="grey"
        size="md"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="fill-off size-20 color-grey-80"
        />
      </SlButton>
      <slot
        v-else
        name="target"
      />
    </template>

    <template #content>
      <div class="sl-group-dropdown__content">
        <SlButton
          v-close-popper
          variant="tertiary"
          color="grey"
          size="xs"
          class="sl-group-dropdown__close"
          icon
        >
          <icon
            data="@icons/close.svg"
            class="fill-off size-16 color-grey-60"
          />
        </SlButton>
        <slot />
      </div>
    </template>
  </SlDropdown>
</template>

<script>
import { getTooltip } from '@/helpers/shared/tooltip';

export default {
  name: 'SlGroupDropdown',
  props: {
    placement: {
      type: String,
      default: 'bottom-end'
    },
    contentClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    getTooltip
  }
};
</script>
<style lang="scss" scoped>
@import '@/style/components/ui-kit/sl-group-dropdown';
</style>