var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isAlertsVisible)?_c('div',{staticClass:"global-alerts"},[(_vm.isNeedPayInvoice)?_c('SlAlertLine',{staticClass:"expiration-alert",attrs:{"type":"error","accent":_vm.$t('Web.LicenseDetails.AccountSuspension'),"column":""},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('p',[_vm._v(_vm._s(_vm.$t('Web.LicenseDetails.OutstandingInvoicesReminder', { 1: _vm.invoiceDueLocaleDate })))]),_c('i18n',{attrs:{"path":"Web.LicenseDetails.PaymentConfirmationInstruction","tag":"p"},scopedSlots:_vm._u([{key:"1",fn:function(){return [_c('SlLink',{attrs:{"href":("mailto:" + _vm.SUPPORT_EMAIL),"target":"_blank","color":"red"}},[_vm._v(" "+_vm._s(_vm.SUPPORT_EMAIL)+" ")])]},proxy:true}],null,false,3259765148)}),(_vm.$sl_isUserAdmin)?_c('SlLink',{staticClass:"mt-8",attrs:{"href":{
          name: _vm.routeNames.SUBSCRIPTION
        },"color":"red"}},[_vm._v(" "+_vm._s(_vm.$t('Web.LicenseDetails.ReviewSubscription'))+" ")]):_vm._e()]},proxy:true}],null,false,3672458106)}):_vm._e(),(_vm.isConstrainedPlanOutdated)?_c('SlAlertLine',{attrs:{"type":"warn","accent":_vm.$t('Web.Settings.ConstrainedPlanOutdated'),"text":_vm.$t('Web.Settings.ConstrainedPlanOutdatedWarning')}}):_vm._e(),(_vm.isOverdated)?_c('SlAlertLine',{attrs:{"type":"warn"},scopedSlots:_vm._u([{key:"accent",fn:function(){return [_c('i18n',{attrs:{"path":"Web.UpdateData.PleaseUpdate"},scopedSlots:_vm._u([{key:"1",fn:function(){return [_c('SlLink',{attrs:{"href":{
              name: _vm.routeNames.SETTINGS,
              hash: ("#" + (_vm.settingsTabKeys.PROJECT))
            },"color":"yellow"}},[_vm._v(" "+_vm._s(_vm.$t('Main.Ui.acSettings'))+" ")])]},proxy:true}],null,false,4136319757)})]},proxy:true}],null,false,1989795225)}):_vm._e(),(_vm.frozenDate)?_c('SlAlertLine',{attrs:{"type":"warn","accent":_vm.$t('Web.FreezeCalc.AlertLabel', { 1: _vm.frozenDate })},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('SlLink',{attrs:{"color":"yellow"},on:{"click":function($event){$event.preventDefault();return _vm.handleUnfreezeSimulation($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Web.FreezeCalc.Unfreeze'))+" ")])]},proxy:true}],null,false,3535695100)}):_vm._e(),(_vm.isRequiresFullReimport)?_c('SlAlertLine',{attrs:{"type":"warn","accent":_vm.$t('Web.UpdateData.UpdateDataFullRequired')},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('i18n',{attrs:{"path":"Web.UpdateData.UpdateDataFullRequiredMessage"},scopedSlots:_vm._u([{key:"1",fn:function(){return [_c('SlLink',{attrs:{"color":"yellow"},on:{"click":function($event){$event.preventDefault();return _vm.handleReImport(_vm.updateData.FULL, _vm.logLevel.OFF)}}},[_vm._v(" "+_vm._s(_vm.$t('Web.UpdateData.UpdateDataFull'))+" ")])]},proxy:true}],null,false,2955883031)})]},proxy:true}],null,false,2612846187)}):_vm._e(),(_vm.showOrderAvailabilityDeprecatedWarn)?_c('SlAlertLine',{attrs:{"type":"warn","accent":_vm.$t('Web.Suppliers.OrderingAvailabilityDeprecated'),"column":""},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('i18n',{attrs:{"path":"Web.Suppliers.OrderingAvailabilityMessage"},scopedSlots:_vm._u([{key:"1",fn:function(){return [_c('SlLink',{attrs:{"href":{
              name: _vm.routeNames.SETTINGS,
              hash: ("#" + (_vm.settingsTabKeys.PROJECT))
            },"color":"yellow"}},[_vm._v(" "+_vm._s(_vm.$t('Main.Ui.acSettings'))+" ")])]},proxy:true}],null,false,4136319757)})]},proxy:true}],null,false,1920585143)}):_vm._e(),(_vm.updateFailedReason)?_c('SlAlertLine',{attrs:{"type":"warn","accent":_vm.$t('ProjectChanges.Other.UpdateFailed', { 1: _vm.updateFailedReason }),"closable":""},on:{"close":_vm.handleCloseFailedImportReason}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }