import Vue from 'vue';
import config from '@/config/global.config';

export const getFronteggEnv = () => {
  const env = Vue.$cookies.get('env');

  if ([config.APP_MODES.DEV, config.APP_MODES.PROD].includes(env)) {
    return env;
  }

  return config.APP_MODES.PROD;
};

export const isProduction = () => {
  return getFronteggEnv() === config.APP_MODES.PROD;
};
