import { groupBy as groupByKeys } from '@/config/connection';

export default function(data) {
  const { startDate: _startDate, groupBy, startFrom } = data.commonData;
  const {
    user: _user,
    password: _password,
    itemInfo: _itemInfo,
    transactional: _transactional,
    toShip: _toShip,
    toReceive: _toReceive,
    bom: _bom,
    suppliers: _suppliers,
    substitutions: _substitutions,
    channels: _channels,
    exportPO: _exportPO,
    exportTO: _exportTO,
    exportMO: _exportMO,
    expirations: _expirations,
    pageLimit: _pageLimit
  } = data.required;
  const {
    combineLocs: _combineLocs,
    allowUntrustedCert: _allowUntrustedCert
  } = data.optional;

  const _groupBy = groupBy.toLowerCase();
  const _startFrom = groupBy !== groupByKeys.MONTH
    ? startFrom - 1
    : startFrom;

  return {
    credentials: {
      _user,
      _password
    },
    endpoints: {
      _itemInfo,
      _transactional,
      _toShip,
      _toReceive,
      _bom,
      _suppliers,
      _substitutions,
      _channels,
      _exportPO,
      _exportTO,
      _exportMO,
      _expirations
    },
    info: {
      _startDate,
      _groupBy,
      _startFrom,
      _combineLocs: +_combineLocs,
      _allowUntrustedCert: +_allowUntrustedCert,
      _pageLimit
    }
  };
}
