import { mapActions, mapState } from 'vuex';
import { treeNodeActions } from '@/mixins/demand/tree/treeNodeActions.mixin';
import { fileSaver } from '@/mixins/webAPI';
import { access } from '@/mixins/access';
import { keyCodes } from '@/config/utils/statusCodes.config';
import { routeNames } from '@/config/router/router.config';
import { getCtrl, getDel } from '@/helpers/shared/listeners';

export const treeShortcuts = {
  mixins: [fileSaver, treeNodeActions, access],
  computed: {
    ...mapState({
      activeNode: state => state.demand.tree.active_node
    })
  },
  methods: {
    ...mapActions({
      downloadTruncatedProject: 'project/downloadTruncatedProject'
    }),
    // actions from treeNodeActions.mixin
    handleTreeShortcuts(e) {
      if (this.$sl_routeName !== routeNames.DEMAND || this.isInput(e)) {
        return;
      }

      // copy item code (ctrl + c)
      if (getCtrl(e) && e.keyCode === keyCodes.c) {
        if (this.isTextSelected()) {
          return;
        }

        e.preventDefault();

        return this.copyItem(this.activeNode);
      }

      // open BOM (ctrl + m)
      if (getCtrl(e) && e.keyCode === keyCodes.m) {
        e.preventDefault();

        if (!this.isBomDisabled(this.activeNode)) {
          return this.openBOMs(this.activeNode);
        }
      }

      // toggle approve (alt + a)
      if (e.altKey && e.keyCode === keyCodes.a) {
        e.preventDefault();

        if (this.$sl_hasAccess(this.$sl_features.approveForecast) && this.$sl_tabEditable) {
          return this.toggleApproveState(this.activeNode);
        }
      }

      // toggle attention (alt + n)
      if (e.altKey && e.keyCode === keyCodes.n) {
        e.preventDefault();

        if (this.$sl_tabEditable) {
          return this.toggleNeedsAttentionState(this.activeNode);
        }
      }

      // toggle checked (alt + c)
      if (e.altKey && e.keyCode === keyCodes.c) {
        e.preventDefault();

        if (this.$sl_tabEditable) {
          return this.toggleCheckedState(this.activeNode);
        }
      }

      // substitute (ctrl + u) if row selected
      if (getCtrl(e) && e.keyCode === keyCodes.u) {
        e.preventDefault();

        return this.substituteItem(this.activeNode);
      }

      // remove form import (alt + delete) if row selected
      if (e.altKey && getDel(e)) {
        e.preventDefault();

        return this.removeFromImport(this.activeNode);
      }

      // download truncated project (ctrl + alt + shift + l)
      if (getCtrl(e) && e.altKey && e.shiftKey && e.keyCode === keyCodes.l) {
        e.preventDefault();

        return this.saveFile(this.downloadTruncatedProject.bind(this, {
          ...this.activeNode,
          filterId: this.activeFilterId
        }));
      }
    }
  }
};
