import Vue from 'vue';
import { i18n } from '@/plugins/vue-i18n.plugin';
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { validateLocales } from '@/config/plugins/vee-validate.config';
import { timeFormats, valueFormatByTime } from '@/config/shared/datePicker.config';
import reqExp from '@/helpers/utils/regExp';
import { bytesToReadableSize, getSizeInBytes, sizes } from '@/helpers/file/fileSize';
import { dateToCurrentLocale, dateToMs, is24h, isValidDate, isValidTime, timezoneOffsetMs } from '@/helpers/date/date';
import { toArray } from '@/helpers/utils/toArray';
import { hasDuplicateNames } from '@/helpers/file/hasDuplicateNames';
import { isValidUrl } from '@/helpers/utils/isValidUrl';

export const loadValidationLanguageAsync = async(originalLocale) => {
  const validateLocale = validateLocales[originalLocale];
  const locale = await import(`vee-validate/dist/locale/${validateLocale}.json`);

  i18n.setLocaleMessage(originalLocale, {
    ...i18n.messages[originalLocale],
    validations: locale.messages
  });

  Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule],
      params: [
        ...toArray(rules[rule].params),
        'message'
      ],
      message: (_, params) => {
        return params.message || i18n.tc(`validations.${rule}`, null, params);
      }
    });
  });

  localize(validateLocale, locale);
};

extend('sl_password', {
  validate: (value) => reqExp.password.test(value)
    || i18n.tc('Web.Error.PasswordRequirements')
});

extend('sl_file_type', {
  validate: (value, args) => {
    const isValid = value.every(file => args.some(type => {
      const name = file?.name?.toLowerCase() || '';

      return name.endsWith(type);
    }));

    return isValid || i18n.tc('Web.Error.FileTypeRequirements');
  }
});

extend('sl_file_size', {
  validate: (value, args) => {
    const maxSizeMb = +args[0] || 500;
    const maxSize =  getSizeInBytes(maxSizeMb, sizes.MB);
    const isValid = value.every(file => file.size <= maxSize);

    return isValid || i18n.tc('Web.Error.FileSizeRequirements', null, {
      1: bytesToReadableSize(maxSize)
    });
  }
});

extend('sl_file_duplicate', {
  validate: (files, existingNames) => {
    const hasUploadedDuplicate = files.some(file => existingNames.some(name => name === file.name));
    const hasInputDuplicate = hasDuplicateNames(files);

    const isValid = !hasUploadedDuplicate && !hasInputDuplicate;

    return isValid || i18n.tc('Web.FileUpload.FileAlreadyExist');
  }
});

extend('sl_not_includes', {
  params: ['values', 'message'],
  validate: (value, { values, message }) => !values.includes(value.trim()) || message
});

extend('sl_url', {
  params: ['message', 'protocols'],
  validate: (value, { message, protocols = ['http'] }) => message
    || protocols.includes('http') && isValidUrl(value)
    || protocols.includes('file') && reqExp.fileUri.test(value)
    || i18n.tc('Web.FileUpload.InvalidUrlFormat')
});

extend('sl_date_between', {
  params: ['startDate', 'endDate', 'offset'],
  validate: (value, { startDate = '01/01/2000', endDate = '12/31/2099', offset = null }) => {
    const valueDate = new Date(value);

    const valueMs = dateToMs(valueDate) + (offset ?? timezoneOffsetMs(valueDate));

    const startDateMs = dateToMs(startDate);
    const endDateMs = dateToMs(endDate);
    const isValid = valueMs >= startDateMs && valueMs <= endDateMs;

    return isValid || i18n.tc('Web.Validation.DateBetween', null, {
      start: dateToCurrentLocale(startDate),
      end: dateToCurrentLocale(endDate)
    });
  }
});

extend('sl_required', {
  validate: (value, params) => rules.required.validate(value, params) || params.message,
  params: [...rules.required.params, 'message'],
  computesRequired: true,
  message: (_, {message}) => message || i18n.tc('Web.Validation.RequiredField')
});

extend('sl_abc_check_sum', {
  params: ['sum'],
  validate(value, { sum }) {
    return sum <= 100;
  },
  message: i18n.tc('Web.Validation.AbcCheckSum')
});

extend('sl_unique_item_code', {
  params: ['activeCode'],
  validate(value, { activeCode }) {
    return value.trim() !== activeCode.trim();
  },
  message: i18n.tc('Web.Validation.UniqueItemCode')
});

extend('sl_forbidden_chars', {
  params: ['chars'],
  validate(value, { chars = ['/', '\\', '?', '*', ':', '|', '"', '<', '>'] }) {
    const regExp = new RegExp(`[${chars.join('')}\\\\]+`, 'g');
    const message = i18n.t('Web.Validation.ForbiddenChars', { chars: chars.join(' ') });
    const isValid = !regExp.test(value);

    return isValid || message;
  }
});

extend('sl_valid_date', {
  validate(value) {
    return isValidDate(value) || i18n.t('Web.Error.DatePickerValidationError');
  }
});

extend('sl_valid_time', {
  params: ['format'],
  validate(value, { format }) {
    const timeFormat = format || is24h() ? timeFormats.t_24h : timeFormats.t_12h;

    return isValidTime(value, valueFormatByTime[timeFormat]) || i18n.t('Web.Error.DatePickerValidationError');
  }
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
