export default function(data) {
  const { startDate: _startDate, groupBy } = data.commonData;
  const {
    user: _user,
    password: _password,
    host: _host,
    webResource: _webResource
  } = data.required;
  const {
    category: _category,
    classAsTopCategory: _classAsTopCategory,
    combineLocs: _combineLocs
  } = data.optional;

  const _groupBy = groupBy.toLowerCase();

  return {
    info: {
      _user,
      _password,
      _host,
      _webResource,
      _startDate,
      _groupBy,
      _category,
      _classAsTopCategory: +_classAsTopCategory,
      _combineLocs: +_combineLocs
    }
  };
}
