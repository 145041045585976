import axios from '@/plugins/axios';

export default {
  updateOverrides(params, body) {
    return axios.put('/report/inventory', body, { params });
  },
  getExplanation(params) {
    return axios.get('/report/inventory/explain', { params });
  },
  getOrderingDays(params) {
    return axios.get('/report/inventory/ordering_days', { params });
  },
  getTransferRegionsList(params) {
    return axios.get('/report/inventory/transfer_regions', { params });
  },
  getDcs(params) {
    return axios.get('/project/dcs', { params });
  },
  clearOverrides(params) {
    return axios.post('/report/inventory/clear_overrides', null, { params });
  },
  exportTableToXlsx(params) {
    return axios.get('/report/inventory/export/table/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  exportParameters(params) {
    return axios.get('/report/inventory/parameters', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  importParameters(params, body) {
    return axios.post('/report/inventory/parameters', body, {
      params,
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
  },
  resetNextOrderDate(params) {
    return axios.post('/report/inventory/reset_next_order_date', null, { params });
  },
  copyQtyToOverride(params) {
    return axios.post('/report/inventory/copy_qty_to_override', null, { params });
  },
  updateRowsSelection(params, body) {
    return axios.post('/report/inventory/selection', body, { params });
  }
};
