import Vue from 'vue';
import { i18n } from '@/plugins/vue-i18n.plugin';
import substitutionsApi from '@/api/substitutions';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import CreateRuleAdapter from '@/adapters/request/substitutions/CreateRule.adapter';
import { removalSubstitutionRulesKeys } from '@/config/item/substitutions.config';
import statusCodes from '@/config/utils/statusCodes.config';
import { getTableConfigParams, getTableConfigStructure } from '@/helpers/shared/tableConfig';
import { toArray } from '@/helpers/utils/toArray';
import { ampli } from '@/plugins/product/ampli';
import { exportTypes } from '@/config/report/inventoryReport';

const types = {
  SET_TABLE: 'SET_TABLE',
  SET_TAB: 'SET_TAB',
  DELETE_ITEM: 'DELETE_ITEM',
  SELECT_ROW: 'SELECT_ROW',
  UPDATE_CELL: 'UPDATE_CELL',
  CREATE_ITEM: 'CREATE_ITEM',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG',
  RESET_STATE: 'RESET_STATE',
  CREATE_SUBSTITUTION_ROW: 'CREATE_SUBSTITUTION_ROW',
  DELETE_SUBSTITUTION_ROW: 'DELETE_SUBSTITUTION_ROW',
  SUBMIT_SUBSTITUTION_VALUE: 'SUBMIT_SUBSTITUTION_VALUE',
  PUSH_SUBSTITUTION_ROWS: 'PUSH_SUBSTITUTION_ROWS',
  SET_ITEM_CLONES: 'SET_ITEM_CLONES'
};

const initialState = () => ({
  [removalSubstitutionRulesKeys.ITEMS]: {
    table: null,
    tableConfig: getTableConfigStructure()
  },
  [removalSubstitutionRulesKeys.CHANNELS]: {
    table: null,
    tableConfig: getTableConfigStructure()
  },
  itemClones: null,
  tab: removalSubstitutionRulesKeys.ITEMS
});

const state = initialState();

const getters = {
  itemClone: (state) => state?.itemClones?.itemClonesInfo?.clone,
  getCreateData: (state) => (type) => {
    return toArray(state[type]?.table?.headers)?.reduce((acc, { key, name }) => {
      if (!['id', 'enabled', 'inUse', 'type'].includes(key)) {
        acc[key] = name;
      }

      return acc;
    }, {});
  },
  isEdited: (state) => {
    return Object.values(removalSubstitutionRulesKeys).some(type => {
      return state[type]?.table?.metadata?.edited;
    });
  }
};

const mutations = {
  [types.SET_TAB](state, tab) {
    state.tab = tab;
  },
  [types.SET_TABLE](state, { type, table }) {
    Vue.set(state[type], 'table', table);
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state[state.tab].tableConfig, key, value);
  },
  [types.SET_TABLE_CONFIG](state, payload) {
    Object.assign(state[state.tab].tableConfig, payload);
  },
  [types.SELECT_ROW](state, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, value);
  },
  [types.UPDATE_CELL](state, { row, cellKey, value }) {
    Vue.set(row, cellKey, value);
  },

  [types.SET_ITEM_CLONES](state, value) {
    state.itemClones = value;
  },

  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(state).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  resetState({ commit }) {
    commit(types.RESET_STATE);
  },
  setTab({ commit }, tab) {
    commit(types.SET_TAB, tab);
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  updateRuleTables({ dispatch }) {
    return Promise.allSettled([
      dispatch('fetchRules', removalSubstitutionRulesKeys.ITEMS),
      dispatch('fetchRules', removalSubstitutionRulesKeys.CHANNELS)
    ]);
  },
  async fetchRules({ state, commit }, tab) {
    try {
      const activeTab = tab || state.tab;
      const response = await substitutionsApi.getRules({
        type: activeTab,
        ...getTableConfigParams(state[activeTab].tableConfig)
      });
      const table = response?.data;

      if (!table) {
        return;
      }

      commit(types.SET_TABLE, {
        type: activeTab,
        table: GenericTableAdapter(table)
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'substitutions/fetchRules' });
    }
  },
  updateCell({ state, commit }, { value, rowId, cellKey }) {
    const rows = state[state.tab].table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    if (rowIndex !== -1) {
      const oldCell = rows[rowIndex][cellKey];
      const newValue = {
        ...oldCell,
        val: value
      };

      commit(types.UPDATE_CELL, {
        row: rows[rowIndex],
        cellKey,
        value: newValue
      });
    }
  },
  selectRow({ state, commit }, { rowId, cellKey, value }) {
    const rows = state[state.tab].table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    if (rowIndex !== -1) {
      commit(types.SELECT_ROW, {
        cell: rows[rowIndex],
        cellKey,
        value: {
          val: value
        }
      });
    }
  },
  async updateOverrides({ state }, { id, ...body }) {
    try {
      await substitutionsApi.updateOverrides({
        global: !id,
        id,
        type: state.tab,
        ...(!id && { query: getTableConfigParams(state[state.tab].tableConfig).query })
      }, body);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'substitutions/updateOverrides' });
    }
  },
  async createRule({ state }, item) {
    try {
      const response = await substitutionsApi.createRule(
        {
          type: state.tab
        },
        CreateRuleAdapter(item)
      );

      if (response.status === statusCodes.OK) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.Notification.CreatedSuccessfully', { 1: i18n.t('Web.Substitutions.Rule') })
        });
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'substitutions/createRule' });
    }
  },
  async deleteRules({ state }) {
    try {
      await substitutionsApi.deleteRules({
        type: state.tab
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'substitutions/deleteRules' });
    }
  },

  saveOverrides() {
    return substitutionsApi.saveRules();
  },
  async discardOverrides({ dispatch }) {
    try {
      await substitutionsApi.discardOverrides();
      await dispatch('updateRuleTables');
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'substitutions/discardOverrides' });
    }
  },

  async getItemClones({ commit }, item) {
    try {
      const response = await substitutionsApi.getItemClones({ item });

      if (!response?.data) {
        return;
      }

      commit(types.SET_ITEM_CLONES, response.data);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getItemClones' });
    }
  },
  replaceItem(_, body) {
    return substitutionsApi.replaceItem(body);
  },
  exportToXlsx({ state }) {
    ampli.substitutionRulesExported({
      substitutionRulesType: state.tab,
      exportFormat: exportTypes.XLSX
    });

    return substitutionsApi.exportToXlsx({
      type: state.tab
    });
  },
  async importFromXlsx({ state }, file) {
    ampli.substitutionRulesImported({
      substitutionRulesType: state.tab
    });

    try {
      await substitutionsApi.importFromXlsx({
        type: state.tab
      }, file);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'importFromXlsx' });

      throw e;
    }
  },
  removeFromImport(_, item) {
    return substitutionsApi.removeFromImport({
      item
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

