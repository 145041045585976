import moment from 'moment';
import { autoUpdateTypes } from '@/config/shared/settings.config';

const prepareAutomation = (data) => {
  let res = {
    enable: data.enable,
    type: data.type
  };

  if (data.type === autoUpdateTypes.DAILY) {
    let value = moment(data.dailyModel, ['h:mm A']);

    if (!value.isValid()) {
      value = moment('06:00 AM', ['HH:mm A']);
    }

    value = value.format('HH:mm');

    const [hour, minute] = value.split(':');
    const time = moment({ hour: +hour, minute: +minute })
      .utc()
      .format('HH:mm:SS');

    res = {
      ...res,
      time,
      period: 86400 // 1 day in sec
    };
  }

  if (data.type === autoUpdateTypes.BY_HOURS) {
    let [hour, minute] = (data.everyModel).split(':');
    let time = moment({ hour: +hour, minute: +minute });

    if (!time.isValid()) {
      hour = 1;
      minute = 0;
      time = moment({ hour, minute });
    }

    const seconds = (+hour * 3600) + (+minute * 60);

    res = {
      ...res,
      time: time.utc().format('HH:mm:SS'),
      period: seconds
    };
  }

  if (data.fgs !== undefined) {
    res = {
      ...res,
      fgs: data.fgs
    };
  }

  return res;
};

const prepareSafetyStock = (data) => {
  if (data.cell?.inventoryParams?.serviceLevel) {
    data.cell.inventoryParams.serviceLevel *= 10;
  }

  if (data.cell?.dcParams?.serviceLevel) {
    data.cell.dcParams.serviceLevel *= 10;
  }

  if (data.cell?.color === null) {
    delete data.cell.color;
  }

  return data;
};

export default function(data) {
  if (data.autoUpdate) {
    data.autoUpdate = prepareAutomation(data.autoUpdate);
  }

  if (data.autoExport) {
    data.autoExport = prepareAutomation(data.autoExport);
  }

  if (data.autoUpdateConstrainedOrderingPlan) {
    data.autoUpdateConstrainedOrderingPlan = prepareAutomation(data.autoUpdateConstrainedOrderingPlan);
  }

  if ('demandView' in data) {
    data.demandView = (+data.demandView).toString();
  }

  if (data.upperConfLevel) {
    data.upperConfLevel /= 100;
  }

  if (data.lowerConfLevel) {
    data.lowerConfLevel /= 100;
  }

  if (data.safetyStock) {
    data.safetyStock = prepareSafetyStock(data.safetyStock);
  }

  delete data?.dashboarddata;
  delete data?.curvesVisibility;
  delete data?.massTopDownItems;

  return { ...data };
}
