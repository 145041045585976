export default {
  connection(data) {
    const { startDate: _startDate, groupBy } = data.commonData;
    const {
      domain: _domain,
      port: _port,
      login: _login,
      password: _password,
      databases
    } = data.required;
    const {
      combineLocs: _combineLocs,
      nMonthsToDropToReceive: _nMonthsToDropToReceive,
      loadCustomFields: _loadCustomFields,
      fileId: _fileId,
      fileRemoved: _fileRemoved
    } = data.optional;
    const _groupBy = groupBy.toLowerCase();

    const selectedDatabases = databases.reduce((acc, { locations, name, ...db}) => {
      const selectedLocations = locations.filter(loc => loc.checked);

      if (selectedLocations.length) {
        acc.push({
          ...db,
          location: selectedLocations.map(loc => loc.name),
          _name: name,
          _nLocations: selectedLocations.length
        });
      }

      return acc;
    }, []);

    return {
      connection: {
        endpoint: {
          _domain,
          _port
        },
        credentials: {
          _login,
          _password
        }
      },
      info: {
        _startDate,
        _groupBy,
        _nMonthsToDropToReceive,
        _loadCustomFields,
        _combineLocs
      },
      spireDatabases: {
        _n: databases.length,
        database: selectedDatabases
      },
      certFile: {
        _fileId,
        _fileRemoved
      }
    };
  },
  databases(data) {
    const { domain, port, login, password } = data.required;

    return {
      connection: {
        domain,
        port,
        login,
        password
      },
      fileId: +data.optional.fileId
    };
  }
};
