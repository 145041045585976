import { integrationModuleTypes } from '@/config/integrations';
import { uuId } from '@/helpers/shared/uuId';
import { i18n } from '@/plugins/vue-i18n.plugin';

export default function({ integrations, module }) {
  if (!integrations) {
    return [];
  }

  if (module === integrationModuleTypes.IMPORT) {
    return integrations;
  }

  const { generalModules, projectModules } = integrations;

  return [
    {
      group: i18n.t('Web.Integrations.Export.Existing'),
      integrations: projectModules.map(option => ({
        ...option,
        sl_id: uuId()
      }))
    },
    {
      group: i18n.t('Web.Integrations.Export.New'),
      integrations: generalModules.map(option => ({
        ...option,
        sl_id: uuId()
      }))
    }
  ];
}