const CROWDIN_IN_CONTEXT_LOCALE = 'fa';

const availableLocales = ['en', 'es', 'fr', 'ja', 'pl', 'pt', 'ru', 'tr', 'zh', 'uk', 'cs', 'de', 'it', CROWDIN_IN_CONTEXT_LOCALE];
const slavicLocales = ['uk', 'ru'];
const localeDirMap = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  ja: 'Japanese',
  pl: 'Polish',
  pt: 'Portuguese',
  ru: 'Russian',
  tr: 'Turkish',
  zh: 'Chinese',
  uk: 'Ukrainian',
  cs: 'Czech',
  de: 'German',
  it: 'Italian',
  [CROWDIN_IN_CONTEXT_LOCALE]: 'Acholi'
};

const localeNameMap = {
  en: 'English',
  es: 'Spanish / Español',
  fr: 'French / Français',
  ja: 'Japanese / 日本語',
  pl: 'Polish / Polski',
  pt: 'Portuguese (Brasil) / Português (Brasil)',
  ru: 'Russian / Русский',
  tr: 'Turkish / Türkçe',
  zh: 'Chinese (simplified) / 简体中文',
  uk: 'Ukrainian / Українська',
  cs: 'Czech / Česky',
  de: 'German / Deutsch',
  it: 'Italian / Italiano',
  [CROWDIN_IN_CONTEXT_LOCALE]: 'In-Context translation / Crowdin'
};

module.exports = {
  availableLocales,
  slavicLocales,
  localeDirMap,
  localeNameMap,
  CROWDIN_IN_CONTEXT_LOCALE
};
