import inventoryReportApi from '@/api/reports/inventoryReport';
import collections from '@/store/modules/report/inventoryReport/collections';
import plannedOrders from '@/store/modules/report/inventoryReport/plannedOrders';
import inTransition from '@/store/modules/report/inventoryReport/inTransition';
import constrainedPlan from '@/store/modules/report/inventoryReport/constrainedPlan';
import ExplanationResponseAdapter from '@/adapters/response/ExplanationTable.adapter';
import { namespaceByRoute } from '@/config/report';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { getRouteName } from '@/helpers/shared/router';
import { dealWith } from '@/api/helpers/errorRegistry';
import { ampli } from '@/plugins/product/ampli';
import { exportTypes } from '@/config/report/inventoryReport';
import { toArray } from '@/helpers/utils/toArray';

const types = {
  SET_EXPLANATION_TABLE: 'SET_EXPLANATION_TABLE'
};

const state = () => ({
  explanationTable: null
});

const mutations = {
  [types.SET_EXPLANATION_TABLE](state, value) {
    state.explanationTable = value;
  }
};

const actions = {
  async updateOverride({ rootState, rootGetters }, { rowId, ...body }) {
    const namespace = namespaceByRoute[getRouteName()];

    try {
      this.dispatch(`${namespace}/setIsUpdating`, true);

      await inventoryReportApi.updateOverrides(
        {
          type: rootGetters[`${namespace}/reportType`](),
          filterId: rootGetters['filters/activeFilterId'],
          query: rootState[namespace]?.tableConfig?.search || undefined,
          rowId
        },
        body
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'updateOverride' });
      this.dispatch(`${namespace}/fetchPage`);

      dealWith(
        [
          slErrorCodes.INVALID_NUMBER_LIMIT,
          slErrorCodes.INVALID_NUMBER,
          slErrorCodes.INVALID_INPUT
        ].reduce((acc, code) => {
          acc[code] = {
            text: (e) => e.message
          };

          return acc;
        }, {})
      )(e);

      if (e.metainfo) {
        throw e;
      }
    } finally {
      this.dispatch(`${namespace}/setIsUpdating`, false);

      ampli.inventoryTableOverridden({
        inventoryTableColumn: body.columnKey
      });
    }
  },
  async clearOverrides({ rootState, rootGetters }, payload) {
    const namespace = namespaceByRoute[getRouteName()];

    try {
      this.dispatch(`${namespace}/setIsUpdating`, true);

      await inventoryReportApi.clearOverrides({
        ...payload,
        type: rootGetters[`${namespace}/reportType`](),
        filterId: rootGetters['filters/activeFilterId'],
        query: rootState[namespace]?.tableConfig?.search || undefined
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'clearOverrides' });
      this.dispatch(`${namespace}/fetchPage`);

      if (e.metainfo) {
        throw e;
      }
    } finally {
      this.dispatch(`${namespace}/setIsUpdating`, false);
    }
  },
  exportTableToXlsx({ rootGetters }) {
    ampli.inventoryTableExported({
      exportFormat: exportTypes.XLSX
    });

    const namespace = namespaceByRoute[getRouteName()];

    return inventoryReportApi.exportTableToXlsx({
      filterId: rootGetters['filters/activeFilterId'],
      type: rootGetters[`${namespace}/reportType`]()
    });
  },
  exportParameters({ rootGetters }) {
    ampli.inventoryParametersExported({
      exportFormat: exportTypes.XLSX
    });

    const namespace = namespaceByRoute[getRouteName()];

    return inventoryReportApi.exportParameters({
      filterId: rootGetters['filters/activeFilterId'],
      type: rootGetters[`${namespace}/reportType`]()
    });
  },
  importParameters({ rootGetters }, body) {
    const namespace = namespaceByRoute[getRouteName()];

    return inventoryReportApi.importParameters({
      filterId: rootGetters['filters/activeFilterId'],
      type: rootGetters[`${namespace}/reportType`]()
    }, body);
  },
  async fetchExplanation({ commit, rootGetters }, {
    item,
    location,
    manufactureDetails = false,
    backordersDetails = false
  }) {
    try {
      const namespace = namespaceByRoute[getRouteName()];

      const response = await inventoryReportApi.getExplanation({
        type: rootGetters[`${namespace}/reportType`](),
        item,
        location,
        manufactureDetails,
        backordersDetails
      });

      if (!response.data) {
        return;
      }

      commit(types.SET_EXPLANATION_TABLE, ExplanationResponseAdapter(response.data.explainData));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchExplanation' });
    }
  },
  async fetchDCs(_, payload) {
    try {
      const response = await inventoryReportApi.getDcs(payload);

      return response.data.list;
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchDCs' });
    }
  },
  async fetchOrderingDays({ rootGetters }, { item, location }) {
    try {
      const namespace = namespaceByRoute[getRouteName()];

      const response = await inventoryReportApi.getOrderingDays({
        type: rootGetters[`${namespace}/reportType`](),
        item,
        location
      });

      return response?.data?.days || [];
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchOrderingDays' });
    }
  },
  async fetchTransferRegionsList({ rootGetters }, { item, location }) {
    try {
      const namespace = namespaceByRoute[getRouteName()];

      const response = await inventoryReportApi.getTransferRegionsList({
        type: rootGetters[`${namespace}/reportType`](),
        item,
        location
      });

      return toArray(response?.data?.regions).map(({ isChecked, id }) => ({
        label: id,
        value: id,
        isChecked
      }));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTransferRegionsList' });
    }
  },
  async resetNextOrderDate({ rootState, rootGetters }, metadata) {
    const namespace = namespaceByRoute[getRouteName()];

    try {
      this.dispatch(`${namespace}/setIsUpdating`, true);

      await inventoryReportApi.resetNextOrderDate({
        type: rootGetters[`${namespace}/reportType`](),
        filterId: rootGetters['filters/activeFilterId'],
        query: rootState[namespace]?.tableConfig?.search || undefined,
        ...(!metadata.enabled && { rowId: metadata.id })
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'resetNextOrderDate' });

      throw e;
    } finally {
      this.dispatch(`${namespace}/setIsUpdating`, false);
    }
  },
  async copyQtyToOverride({ rootState, rootGetters }, { metadata, errorHandler }) {
    const namespace = namespaceByRoute[getRouteName()];

    try {
      this.dispatch(`${namespace}/setIsUpdating`, true);

      await inventoryReportApi.copyQtyToOverride(
        {
          type: rootGetters[`${namespace}/reportType`](),
          filterId: rootGetters['filters/activeFilterId'],
          query: rootState[namespace]?.tableConfig?.search || undefined,
          ...(!metadata.enabled && { rowId: metadata.id })
        }
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'copyQtyToOverride' });

      errorHandler(e);
    } finally {
      this.dispatch(`${namespace}/setIsUpdating`, false);
    }
  },
  async updateRowsSelection({ rootGetters, rootState }, { selected, ...params }) {
    const namespace = namespaceByRoute[getRouteName()];

    try {
      await inventoryReportApi.updateRowsSelection(
        {
          type: rootGetters[`${namespace}/reportType`](),
          filterId: rootGetters['filters/activeFilterId'],
          query: rootState[namespace]?.tableConfig?.search || undefined,
          ...params
        },
        { selected }
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'copyQtyToOverride' });
    }
  }
};

export default {
  namespaced: true,
  modules: {
    collections,
    plannedOrders,
    inTransition,
    constrainedPlan
  },
  state,
  mutations,
  actions
};
