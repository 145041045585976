<template>
  <component
    :is="rootComponent"
    class="sl-tab body-3-md"
    :to="fullTo"
    :class="{
      'sl-tab--active': active,
      'sl-tab--disabled': disabled,
      'sl-tab--drag-disabled': dragDisabled,
      'sl-tab--horizontal': horizontal
    }"
  >
    <div
      v-if="$scopedSlots.prepend"
      class="sl-tab__prepend"
    >
      <slot name="prepend" />
    </div>
    <div
      v-if="$scopedSlots['append-inner']"
      class="sl-tab__text-wrapper"
    >
      <span
        :class="['sl-tab__text', {
          'sl-required': required
        }]"
      >
        <slot />
      </span>
      <div class="sl-tab__append-inner">
        <slot name="append-inner" />
      </div>
    </div>
    <span
      v-else
      :class="['sl-tab__text', {
        'sl-required': required
      }]"
    >
      <slot />
    </span>

    <div
      v-if="$scopedSlots.append"
      class="sl-tab__append"
    >
      <slot name="append" />
    </div>
  </component>
</template>

<script>
export default {
  name: 'SlTab',
  props: {
    to: {
      type: Object,
      default: () => {}
    },
    active: Boolean,
    disabled: Boolean,
    dragDisabled: Boolean,
    required: Boolean,
    isLink: Boolean,
    horizontal: Boolean
  },
  computed: {
    rootComponent() {
      return this.isLink ? 'router-link' : 'div';
    },
    fullTo() {
      return Object.assign({}, this.$router.currentRoute, this.to);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-tabs/sl-tab.scss";
</style>
