const initialVersionYear = 2024;
const monthsInYear = 12;

export const getVersion = () => {
  const webVersion = process.env.VUE_APP_VERSION;

  const releases = webVersion.split('.');
  const majorVersion = +releases.shift();
  const restVersion = releases.join('.');
  const correctedMajorVersion = majorVersion % monthsInYear || monthsInYear;
  const yearsAfterInitial = Math.floor((majorVersion - 0.1) / monthsInYear);

  return `${initialVersionYear + yearsAfterInitial}.${correctedMajorVersion}.${restVersion}`;
};
