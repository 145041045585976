import { i18n } from '@/plugins/vue-i18n.plugin';

export const sizes = {
  B: 'b',
  KB: 'kb',
  MB: 'mb',
  GB: 'gb'
};

const sizesMapInBytes = {
  [sizes.B]: 1,
  [sizes.KB]: Math.pow(1024, 1),
  [sizes.MB]: Math.pow(1024, 2),
  [sizes.GB]: Math.pow(1024, 3)
};

export const getSizeInBytes = (amount, unit) => {
  return amount * sizesMapInBytes[unit];
};

export const MB100 = getSizeInBytes(100, sizes.MB);

export const bytesToReadableSize = (size, mbFixed = 2) => {
  if (!size) {
    return null;
  }

  const KB = size > 1024 ? Math.round(size / 1024) : 1;
  const MB = KB / 1024;
  const isMB = parseInt(MB, 10);

  return isMB
    ? `${MB.toFixed(mbFixed)} ${i18n.tc('Web.FileSizes.Mb')}`
    : `${KB} ${i18n.tc('Web.FileSizes.Kb')}`;
};