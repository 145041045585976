import Vue from 'vue';
import { i18n } from '@/plugins/vue-i18n.plugin';
import subscriptionApi from '@/api/subscription';
import initializationApi from '@/api/initialization';
import { addDaysToDate } from '@/helpers/date/addDaysToDate';
import { dateByLocaleKey, localeDateKeys } from '@/helpers/locale/localeDate';
import { accountPlans, licenseStatuses, UNLIM_USERS_COUNT } from '@/config/license';
import { getDifferenceInDays } from '@/helpers/date/getDifferenceDays';

const types = {
  SET_LICENSE_DETAILS: 'SET_LICENSE_DETAILS'
};

const state = () => ({
  license_details: null
});

const getters = {
  expirationDate: (state) => {
    // date including expiration date
    return addDaysToDate(state.license_details?.expirationDate, 1);
  },
  gracePeriodDays: state => +state.license_details?.gracePeriodDays || 0,
  currentGraceDay: (state, getters) => {
    const diff = getters.gracePeriodDays - +state.license_details?.remainGracePeriod;

    if (diff <= 0) {
      return getters.gracePeriodDays;
    }

    return getters.gracePeriodDays - diff;
  },
  expiresIn: state => {
    if (state.license_details?.expiresIn === null) {
      return 1;
    }

    const remain = +state.license_details?.expiresIn;

    return remain > 0 ? remain : 0;
  },
  isExpired: state => !!state.license_details
    && state.license_details.expiresIn !== null
    && +state.license_details.expiresIn <= 0,
  isGracePeriodFinished: (state, getters) => {
    return getters.isExpired && +state.license_details?.remainGracePeriod < 0;
  },
  expirationLocaleDate: (state) => {
    return dateByLocaleKey(state.license_details?.expirationDate, localeDateKeys.YMD);
  },
  invoiceDueLocaleDate: (state) => { // need only for alert line
    return dateByLocaleKey(state.license_details?.invoiceDueDate, localeDateKeys.YMD);
  },
  expirationInvoiceDate: (state) => {
    return getDifferenceInDays(state.license_details?.invoiceDueDate) * -1;
  },
  needLicenseDetails: state => state.license_details?.licenseStatus !== licenseStatuses.OK,
  isFreePlan: (state) => state.license_details?.accountPlan === accountPlans.FREE,
  expireDetails: (_, getters) => {
    if (getters.isExpired) {
      return i18n.tc('Web.LicenseDetails.InfoExpired');
    }

    return i18n.tc('Web.LicenseDetails.InfoExpiresIn.val', {
      p1: this.$tc('Web.LicenseDetails.InfoExpiresIn.val.p1', getters.expiresIn, { n: getters.expiresIn })
    });
  },
  increaseMaxUsersLimitEnabled: state => state.license_details?.increaseMaxUsersLimitEnabled,
  currentUsersCount: state => +state.license_details?.currentUsersAmount,
  maxUsersCount: (state) => +state.license_details?.maxUsersAmount || UNLIM_USERS_COUNT
};

const mutations = {
  [types.SET_LICENSE_DETAILS](state, value) {
    state.license_details = value;
  }
};

const actions = {
  async getSubscriptionDetails({ commit }) {
    try {
      const response = await initializationApi.getSubscriptionDetails();
      const licenseDetails = response?.data;

      if (licenseDetails) {
        commit(types.SET_LICENSE_DETAILS, licenseDetails);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'account/getSubscriptionDetails' });

      throw e;
    }
  },
  getTenantInfo() {
    return initializationApi.getTenantInfo();
  },
  setTenantInfo() {
    return initializationApi.setTenantInfo();
  },
  async setUserLimit(_, value) {
    try {
      await subscriptionApi.setUserLimit({
        val: value
      });

      Vue.notify({
        type: 'success',
        title: i18n.t('Web.LicenseDetails.IncreaseSuccessfuly'),
        text: i18n.t('Web.LicenseDetails.IncreaseWarning'),
        duration: 7000
      });
    } catch (e) {
      const text =  e?.message || '';

      if (text) {
        Vue.notify({
          type: 'error',
          text,
          duration: 3000
        });
      }

      this.dispatch('user/logout', { e, from: 'account/setUserLimit' });
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
