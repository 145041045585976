import Vue from 'vue';
import { i18n } from '@/plugins/vue-i18n.plugin';
import transferRegionsApi from '@/api/reports/intersiteReport/transferRegions';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import statusCodes from '@/config/utils/statusCodes.config';
import {
  getTableConfigParams,
  getTableConfigStructure,
  sortingParamsAdapter
} from '@/helpers/shared/tableConfig';

const initialState = () => ({
  table: null,
  tableConfig: getTableConfigStructure()
});

const types = {
  SET_TABLE: 'SET_TABLE',
  DELETE_ITEM: 'DELETE_ITEM',
  SELECT_ROW: 'SELECT_ROW',
  CREATE_ITEM: 'CREATE_ITEM',
  RESET_STATE: 'RESET_STATE',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG',
  SET_TABLE_CONFIG: 'SET_TABLE_CONFIG'
};

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_TABLE](state, value) {
    state.table = value;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state.tableConfig, key, value);
  },
  [types.SET_TABLE_CONFIG](state, value) {
    Object.assign(state.tableConfig, value);
  },
  [types.SELECT_ROW](state, { cell, cellKey, value }) {
    Vue.set(cell, cellKey, value);
  },
  [types.RESET_STATE](state) {
    const initial = initialState();

    Object.keys(initial).forEach(key => {
      state[key] = initial[key];
    });
  }
};

const actions = {
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  },
  async fetchTransferRegions({ state, commit }) {
    try {

      const response = await transferRegionsApi.getTransferRegions(
        getTableConfigParams(state.tableConfig)
      );
      const table = response?.data;

      if (!table) {
        return;
      }

      commit(types.SET_TABLE, GenericTableAdapter(table));
      commit(types.SET_TABLE_CONFIG, sortingParamsAdapter(table));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchTransferRegions' });
    }
  },
  exportToXlsx() {
    return transferRegionsApi.exportTransferRegionsToXlsx();
  },
  importFromXlsx(_, file) {
    return transferRegionsApi.importTransferRegionsFromXlsx(file);
  },
  async createTransferRegion(_, item) {
    try {
      const response = await transferRegionsApi.createTransferRegion(item);

      if (response.status === statusCodes.OK) {
        Vue.notify({
          type: 'success',
          title: i18n.t('Web.Notification.CreatedSuccessfully', { 1: i18n.t('Web.TransferRegions.TransferRegion') })
        });
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'transferRegions/createTransferRegion' });

      throw e;
    }
  },
  async deleteTransferRegions() {
    try {
      await transferRegionsApi.deleteTransferRegions();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'transferRegions/deleteTransferRegions' });
    }
  },
  async updateTransferRegion(_, { id, ...body }) {
    try {
      await transferRegionsApi.updateTransferRegion({
        global: !id,
        id
      }, body);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'transferRegions/updateTransferRegion' });
    }
  },
  selectRow({ state, commit }, { rowId, cellKey, value }) {
    const rows = state.table.rows;
    const rowIndex = rows.findIndex(row => row.id === rowId);

    commit(types.SELECT_ROW, {
      cell: rows[rowIndex],
      cellKey,
      value: {
        val: value
      }
    });
  },
  async saveTransferRegions() {
    try {
      await transferRegionsApi.saveTransferRegions();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'transferRegions/saveTransferRegions' });

      throw e;
    }
  },
  async discardTransferRegions() {
    try {
      await transferRegionsApi.discardTransferRegions();
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'transferRegions/discardTransferRegions' });
    }
  },
  resetState({ commit }) {
    commit(types.RESET_STATE);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
