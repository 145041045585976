import axios from '@/plugins/axios';

export default {
  getIntegrations(params) {
    return axios.get('/integrations', { params });
  },
  getAvailableIntegrations(params) {
    return axios.get('/integrations/available', { params });
  },
  createIntegration(params, body) {
    return axios.post('/integrations', body, { params });
  },
  renameIntegration(params, body) {
    return axios.put('/integrations/name', body, { params });
  },
  deleteIntegration(params) {
    return axios.delete('/integrations', { params });
  },
  getProjectSettings() {
    return axios.get('/integrations/project_settings');
  },
  updateProjectSettings(body) {
    return axios.put('/integrations/project_settings', body);
  },
  getProjectCreationInfo() {
    return axios.get('/integrations/project_creation/info');
  },
  importProject() {
    return axios.post('/integrations/import_project');
  },
  reimportProject(params) {
    return axios.post('/integrations/reimport_project', null, { params });
  },
  getAvailableConnections(params) {
    return axios.get('/integrations/available/connections', { params });
  }
};
