<template>
  <div
    class="sl-avatar"
    :style="style"
  >
    <img
      v-if="!imageLoadError && url"
      :src="url"
      referrerpolicy="no-referrer"
      @load="handleImageLoad"
      @error="handleImageLoadError"
    >
    <span v-else>
      {{ name[0] }}
    </span>
  </div>
</template>

<script>
import { avatarColors } from '@/config/users/avatar.config';

export default {
  name: 'SlAvatar',
  props: {
    size: {
      type: Number,
      default: 32
    },
    url: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'User'
    }
  },
  data() {
    return {
      imageLoadError: false
    };
  },
  computed: {
    style() {
      const nameSum = this.name.split('').reduce((acc, c) => acc += +c.charCodeAt(0), 0);
      const index = nameSum % avatarColors.length;

      return {
        color: avatarColors[index].color,
        backgroundColor: avatarColors[index].background,
        minWidth: `${this.size}px`,
        width: `${this.size}px`,
        height: `${this.size}px`,
        lineHeight: `${this.size}px`,
        fontSize: `${this.size * 0.625}px`
      };
    }
  },
  methods: {
    handleImageLoadError() {
      this.imageLoadError = true;
    },
    handleImageLoad() {
      this.imageLoadError = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/ui-kit/sl-avatar";
</style>