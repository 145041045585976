import BaseImportAdapter from '@/adapters/response/integrations/transform/BaseImport.adapter';
import DatabaseImportAdapter from '@/adapters/response/integrations/transform/DatabaseImport.adapter';
import { integrationTypes } from '@/config/integrations';

export default function(settings, integration) {
  const adapterByType = {
    [integrationTypes.AGGREGATION]: BaseImportAdapter,
    [integrationTypes.AMAZON]: BaseImportAdapter,
    [integrationTypes.QB_DESKTOP]: BaseImportAdapter,
    [integrationTypes.CUSTOM_API]: BaseImportAdapter,
    [integrationTypes.BC365]: BaseImportAdapter,
    [integrationTypes.SHOPIFY]: BaseImportAdapter,
    [integrationTypes.DATABASE]: DatabaseImportAdapter,
    [integrationTypes.SAP]: DatabaseImportAdapter,
    [integrationTypes.NETSUITE]: DatabaseImportAdapter,
    [integrationTypes.DYNAMICS_NAV]: DatabaseImportAdapter,
    [integrationTypes.DYNAMICS_GP]: DatabaseImportAdapter,
    [integrationTypes.CIN7]: DatabaseImportAdapter,
    [integrationTypes.CIN7CORE]: DatabaseImportAdapter,
    [integrationTypes.FISHBOWL]: DatabaseImportAdapter,
    [integrationTypes.SPIRE]: DatabaseImportAdapter
  };

  const adapter = adapterByType[integration.type];

  return adapter ? adapter(settings, integration) : settings;
}
