import { i18n } from '@/plugins/vue-i18n.plugin';
import { formatNumber } from '@/helpers/format/formatNumber';

export default function(data) {
  if (!data) {
    return null;
  }

  const { costs, volume, weight, pallet, triState, selectedRowsCount, edited, availableCols } = data;

  const parsedCost = costs?.map(cost => {
    const currency = cost.currency ? `${cost.currency} ` : '';
    const value = formatNumber(cost.price);

    return currency + value;
  }).join(' + ') || '0';
  const parsedWeight = weight
    ? `${formatNumber(weight)} ${i18n.tc('Web.Units.Kg')}`
    : null;
  const parsedVolume = volume
    ? `${formatNumber(volume)} ${i18n.tc('Web.Units.MetersCubic')}`
    : null;
  const parsedPallet = pallet
    ? formatNumber(pallet)
    : null;

  return {
    checkbox: triState,
    edited,
    availableCols,
    calculatedValues: [
      {
        label: i18n.tc('Web.PlannedOrders.LabelCost'),
        value: parsedCost
      },
      {
        label: i18n.tc('Web.PlannedOrders.LabelWeight'),
        value: parsedWeight
      },
      {
        label: i18n.tc('Web.PlannedOrders.LabelVolume'),
        value: parsedVolume
      },
      {
        label: i18n.tc('Web.PlannedOrders.LabelPallet'),
        value: parsedPallet
      }
    ],
    totalSelected: +selectedRowsCount
  };
}
