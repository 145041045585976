import spireApi from '@/api/integrations/spire';

const types = {
  SET_COMPANIES: 'SET_COMPANIES'
};

const initialState = () => ({
  companies: []
});

const state = initialState();

const getters = {};

const mutations = {
  [types.SET_COMPANIES](state, value) {
    state.companies = value;
  }
};

const actions = {
  async fetchCompanies({ commit, rootState }) {
    try {
      const { operationData } = await this.dispatch('operations/subscribe', {
        subscriber: () => spireApi.getCompanies({
          id: rootState.integrations.active_integration.id
        })
      });

      const companies = operationData?.companies;

      if (!companies) {
        return;
      }

      commit(types.SET_COMPANIES, companies);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchCompanies' });

      throw e;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
