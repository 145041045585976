import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
// import createMutationsSharer from 'vuex-shared-mutations';

import paths from './storedPaths';

import main from './modules/index';
import initialization from './modules/initialization';
import manageProjects from './modules/manageProjects/index';
import forecastReport from './modules/report/forecastReport';
import inventoryReport from './modules/report/inventoryReport/index';
import project from './modules/project/index';
import substitutions from './modules/project/substitutions';
import suppliers from './modules/suppliers';
import demand from './modules/demand';
import user from './modules/user';
import dashboard from './modules/dashboard';
import settings from './modules/settings';
import modals from './modules/modals';
import userAccess from './modules/userAccess';
import lastChanges from './modules/lastChanges';
import item from './modules/item';
import bom from './modules/bom';
import changes from './modules/changes';
import note from './modules/note';
import connection from './modules/connection';
import timeMachine from './modules/timeMachine';
import spreadsheet from './modules/connection/spreadsheet';
import operations from './modules/operations';
import bc365 from './modules/connection/bc365';
import netsuite from './modules/connection/netsuite';
import spire from './modules/connection/spire';
import qb from './modules/connection/qb';
import skubana from './modules/connection/skubana';
import dbRelated from './modules/connection/dbRelated';
import integrations from './modules/integrations';
import logs from './modules/project/logs';
import filters from './modules/filters';
import account from './modules/account';
import access from './modules/access';
import workspace from './modules/workspace';
import health from './modules/health';
import AIAssistant from './modules/AIAssistant';
import transferRegions from './modules/report/intersiteReport/transferRegions';
import { VUEX_LS_KEY } from '@/config/app.config';

Vue.use(Vuex);

export default new Vuex.Store({
  state: main.state(),
  getters: main.getters,
  mutations: main.mutations,
  actions: main.actions,
  modules: {
    initialization,
    manageProjects,
    user,
    project,
    substitutions,
    forecastReport,
    inventoryReport,
    suppliers,
    dashboard,
    settings,
    modals,
    demand,
    userAccess,
    changes,
    item,
    bom,
    lastChanges,
    note,
    connection,
    timeMachine,
    spreadsheet,
    operations,
    bc365,
    netsuite,
    spire,
    qb,
    skubana,
    dbRelated,
    integrations,
    logs,
    filters,
    account,
    access,
    workspace,
    health,
    AIAssistant,
    transferRegions
  },
  plugins: [
    // todo 16.01.2024 - https://gmdhsoftware.atlassian.net/browse/WSL-2469
    // commented out due to the multiple project per browser epic released
    // createMutationsSharer({
    //   predicate: () => true
    // }),
    persistedState({
      key: VUEX_LS_KEY,
      storage: {
        getItem: (key) => localStorage.getItem(key),
        setItem: (key, value) => localStorage.setItem(key, value),
        removeItem: (key) => localStorage.removeItem(key)
      },
      paths
    })
  ]
});
