import axios from '@/plugins/axios';

export default {
  createEntity(params, body) {
    return axios.post('/suppliers/temporary_records', body, { params });
  },
  deleteEntities(params) {
    return axios.delete('/suppliers/temporary_records', { params });
  },
  getEntitiesTable(params) {
    return axios.get('/suppliers', { params });
  },
  overrideEntity(params, body) {
    return axios.put('/suppliers/override', body, { params });
  },
  applyOverrides() {
    return axios.post('/suppliers');
  },
  deleteOverrides() {
    return axios.delete('/suppliers/overrides');
  },
  getSuppliersList(params) {
    return axios.get('/suppliers/search', { params });
  },
  getSupplierNames(params) {
    return axios.get('/suppliers/names', { params });
  },
  exportToXlsx(params) {
    return axios.get('/suppliers/xlsx', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  },
  importFromXlsx(params, body) {
    return axios.post('/suppliers/xlsx', body, {
      params,
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    });
  }
};
