const KEY_SEPARATOR = '~';

export function getResizedIndexesFromClasses(sizes, headersMap) {
  return Object.keys(sizes).reduce((acc, key) => {
    if (key.includes(KEY_SEPARATOR)) {
      return acc;
    }

    if (headersMap[key]) {
      acc[headersMap[key].columnPosition] = sizes[key];
    }

    return acc;
  }, []);
}
