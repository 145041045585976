import axios from '@/plugins/axios';

export default {
  getSheetList(params) {
    return axios.get('/spreadsheet/sheetlist', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getBasicSettings(params) {
    return axios.get('/spreadsheet/basic-settings', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getSheetPreview(params) {
    return axios.get('/spreadsheet/preview', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getSlots(params) {
    return axios.get('/spreadsheet/slots', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  getWarnings(params) {
    return axios.get('/spreadsheet/warning', {
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  uploadFile(body, config = {}) {
    return axios.post('/spreadsheet/upload', body, {
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-pid-independent': true
      }
    });
  },
  uploadFileWithURL(params, config = {}) {
    return axios.post('/spreadsheet/upload-url', null, {
      ...config,
      params,
      headers: {
        'X-pid-independent': true
      }
    });
  },
  downloadFile(params) {
    return axios.get('/spreadsheet/download', {
      params,
      responseType: 'blob',
      headers: {
        'Accept': 'application/octet-stream'
      }
    });
  }
};
